import {
    SpacingToken,
    UiStack,
} from '@experiences/ui-common';
import {
    ColorBlueSecondary500,
    ColorOrange700,
} from '@uipath/apollo-core/lib/_generated/Colors';
import { Chart } from '@uipath/apollo-lab/react';
import type { IDropdownOption } from '@uipath/portal-shell-types/components/angular-elements'; // Import the correct type
import dayjs from 'dayjs';
import React, {
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import { formatScaleX } from '../../../common/UiBarChart/formatScaleX';
import { UsageService } from './UsageService';

interface StatusDashboardProps {
    token: string;
    accountId: string;
}

export const StatusDashboard: React.FC<StatusDashboardProps> = ({
    token, accountId,
}) => {
    const { formatMessage: translate } = useIntl();
    const [ chartData, setChartData ] = useState<number[][]>([]);
    const [ labels, setLabels ] = useState<string[]>([]);
    const [ loading, setLoading ] = useState<boolean>(true);

    const [ selectedLocation, setSelectedLocation ] = useState<string>('all');
    const [ selectedModel, setSelectedModel ] = useState<string>('all');
    const [ selectedStatus, setSelectedStatus ] = useState<string>('all');

    const scaleX = formatScaleX();

    const [ locationOptions, setLocationOptions ] = useState<IDropdownOption[]>([
        {
            label: 'All',
            value: 'all',
        },
    ]);
    const [ modelOptions, setModelOptions ] = useState<IDropdownOption[]>([
        {
            label: 'All',
            value: 'all',
        },
    ]);
    const [ statusOptions, setStatusOptions ] = useState<IDropdownOption[]>([
        {
            label: 'All',
            value: 'all',
        },
    ]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const months: dayjs.Dayjs[] = [];
                for (let i = 5; i >= 0; i--) {
                    const date = dayjs().subtract(i, 'month')
                        .startOf('month');
                    months.push(date);
                }

                const monthlyStatusCounts: { [key: string]: { [status: string]: number } } = {};

                const data = await UsageService.fetchActionByStatus(token, accountId, new Date(2024, 5, 1)); // Start date is June 2024

                const distinctLocations = Array.from(new Set(data?.map(item => item.modelLocation)));
                const distinctModels = Array.from(new Set(data?.map(item => item.modelUsed)));
                const distinctStatuses = Array.from(new Set(data?.map(item => item.status)));

                setLocationOptions([
                    {
                        label: 'All',
                        value: 'all',
                    }, ...distinctLocations.map(loc => ({
                        label: loc,
                        value: loc,
                    })),
                ]);
                setModelOptions([
                    {
                        label: 'All',
                        value: 'all',
                    }, ...distinctModels.map(mod => ({
                        label: mod,
                        value: mod,
                    })),
                ]);
                setStatusOptions([
                    {
                        label: 'All',
                        value: 'all',
                    }, ...distinctStatuses.map(stat => ({
                        label: stat,
                        value: stat,
                    })),
                ]);

                months.forEach((month) => {
                    const key = `${month.year()}-${month.month() + 1}`;
                    monthlyStatusCounts[key] = {
                        Succeeded: 0,
                        Failed: 0,
                    };
                });

                const filteredData = data?.filter(item => {
                    const matchLocation = selectedLocation === 'all' || item.modelLocation === selectedLocation;
                    const matchModel = selectedModel === 'all' || item.modelUsed === selectedModel;
                    const matchStatus = selectedStatus === 'all' || item.status === selectedStatus;
                    return matchLocation && matchModel && matchStatus;
                });

                filteredData?.forEach((item) => {
                    const key = `${item.year}-${item.month}`;
                    if (monthlyStatusCounts[key]) {
                        monthlyStatusCounts[key][item.status] += item.totalAction;
                    }
                });

                const newChartData: number[][] = [];
                const newLabels: string[] = [];

                months.forEach((month) => {
                    const key = `${month.year()}-${month.month() + 1}`;
                    const statusCounts = monthlyStatusCounts[key];

                    newChartData.push([ statusCounts.Succeeded, statusCounts.Failed ]);
                    newLabels.push(month.format('MMM'));
                });

                setChartData(newChartData);
                setLabels(newLabels);
            } catch (error) {
                console.error('Error fetching usage data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [ token, accountId, selectedLocation, selectedModel, selectedStatus ]);

    return (
        <UiStack
            direction="column"
            mt={SpacingToken.L}
            gap={SpacingToken.XS}
            style={{
                border: '2px solid #ccc',
                borderRadius: '8px',
                padding: '16px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
        >
            <UiStack
                direction="row"
                gap={SpacingToken.M}>
                <div>
                    <label
                        htmlFor="model-select"
                        style={{ fontWeight: 'bold' }}>
                        Total LLM Actions per Status
                    </label>
                </div>
                <div>
                    <label
                        htmlFor="model-select"
                        style={{ fontWeight: 'bold' }}>
                        Region:
                    </label>
                    <select
                        value={selectedLocation}
                        onChange={(e) => setSelectedLocation(e.target.value)}
                    >
                        {locationOptions.map((option) => (
                            <option
                                key={String(option.value)}
                                value={String(option.value)}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <label
                        htmlFor="model-select"
                        style={{ fontWeight: 'bold' }}>
                        Model:
                    </label>
                    <select
                        value={selectedModel}
                        onChange={(e) => setSelectedModel(e.target.value)}
                    >
                        {modelOptions.map((option) => (
                            <option
                                key={String(option.value)}
                                value={String(option.value)}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <label
                        htmlFor="model-select"
                        style={{ fontWeight: 'bold' }}>
                        Status:
                    </label>
                    <select
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        {statusOptions.map((option) => (
                            <option
                                key={String(option.value)}
                                value={String(option.value)}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            </UiStack>

            {loading ? (
                <div>
                    Loading...
                </div>
            ) : (
                <Chart
                    alt={translate({ id: 'CLIENT_USAGE_CHART_ALT_TEXT' })}
                    data={[
                        {
                            data: chartData.map((segments) => segments[0]),
                            renderer: 'bar',
                            color: ColorBlueSecondary500,
                            title: translate({ id: 'CLIENT_USAGE_SUCCEEDED' }),
                        },
                        {
                            data: chartData.map((segments) => segments[1]),
                            renderer: 'bar',
                            color: ColorOrange700,
                            title: translate({ id: 'CLIENT_USAGE_FAILED' }),
                        },
                    ]}
                    labels={labels}
                    scaleX={scaleX}
                    scaleY={{
                        type: 'linear',
                        display: true,
                        label: 'LLM Calls',
                    }}
                    tooltipSchemaBuilder={(values) =>
                        values.map((value, index) => ({
                            cells: [
                                {
                                    text: `${translate({ id: `CLIENT_USAGE_${[ 'SUCCEEDED', 'FAILED' ][index]}` })}: ${value.value.y}`,
                                    role: 'primary' as 'primary',
                                    color: [ ColorBlueSecondary500, ColorOrange700 ][index],
                                },
                            ],
                        }))}
                    legendProperties={{ hasLegend: true }}
                />
            )}
        </UiStack>
    );
};
