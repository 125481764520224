export class A4EErrorMessage extends Error {
    status?: string;
    errorId: string;

    constructor(errorId: string, status?: string) {
        super(errorId);
        this.status = status;
        this.errorId = errorId;
    }
}
