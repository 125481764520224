import axios from 'axios';
import dayjs from 'dayjs';

import type { IAuditLogSummary } from './AuditLogSummary';
import {
    getBatchAuditLogApiUrl,
    getFilteredAuditLogSummaryApiUrl,
    getSingleAuditLogApiUrl,
} from './AuditTabHelpers';
import type { IGridDataEntry } from './GridDataEntryTypes';

type TimeFilter = 'lastDay' | 'lastWeek' | 'last30Days' | 'last60Days' | null;

interface FetchAuditLogsBatchOptions {
    token: string;
    accountId: string;
    itemPerPage?: number;
    pageNumber?: number;
    productFilter?: string | null;
    timeFilter?: TimeFilter; // Using TimeFilter alias
    modelFilter?: string | null;
    statusFilter?: string | null;
}

export class AuditLogService {
    // Common method to set headers
    static getHeaders(token: string, accountId: string) {
        return {
            'X-UiPath-Internal-AccountId': accountId,
            'X-UiPath-Internal-TenantId': accountId,
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        };
    }

    // Common method to handle API requests
    static async makeRequest(
        method: 'get' | 'post',
        url: string,
        token: string,
        accountId: string,
        data: any = null
    ): Promise<any> {
        const headers = this.getHeaders(token, accountId);

        try {
            const response = await axios({
                method,
                url,
                headers,
                data: method === 'post' ? data : undefined, // Include data only if it's a POST request
            });

            return response.data;
        } catch (error) {
            console.error(`Failed to make ${method.toUpperCase()} request:`, error);
            return null;
        }
    }

    static applyFilters(
        timeFilter: TimeFilter,
        productFilter: string | null,
        modelFilter: string | null,
        statusFilter: string | null
    ): any {
        const now = dayjs();
        const utcNow = now.subtract(now.utcOffset(), 'minute');

        const data: any = {
            StartDate: utcNow.subtract(60, 'day').startOf('day')
                .format('YYYY-MM-DDTHH:mm:ss[Z]'),
            EndDate: utcNow.endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'),
        };

        if (timeFilter) {
            const dateRanges = {
                'lastDay': [ utcNow.subtract(1, 'day').startOf('day'), utcNow.endOf('day') ],
                'lastWeek': [ utcNow.subtract(7, 'day').startOf('day'), utcNow.endOf('day') ],
                'last30Days': [ utcNow.subtract(30, 'day').startOf('day'), utcNow.endOf('day') ],
                'last60Days': [ utcNow.subtract(60, 'day').startOf('day'), utcNow.endOf('day') ],
            };

            const [ start, end ] = dateRanges[timeFilter] || [ data.StartDate, data.EndDate ];
            data.StartDate = start.format('YYYY-MM-DDTHH:mm:ss[Z]');
            data.EndDate = end.format('YYYY-MM-DDTHH:mm:ss[Z]');
        }

        if (productFilter && productFilter !== 'all') {
            data.Products = [ productFilter ];
        }
        if (modelFilter && modelFilter !== 'all') {
            data.Models = [ modelFilter ];
        }
        if (statusFilter && statusFilter !== 'all') {
            const statusMap: Record<string, number | null> = {
                'Succeeded': 0,
                'Failed': 1,
            };
            data.Statuses = [ statusMap[statusFilter] ];
        }

        return data;
    }

    static async fetchAuditLogsBatch({
        token,
        accountId,
        itemPerPage = 50,
        pageNumber = 1,
        productFilter = null,
        timeFilter = null,
        modelFilter = null,
        statusFilter = null,
    }: FetchAuditLogsBatchOptions): Promise<IGridDataEntry[]> {
        const skip = (pageNumber - 1) * itemPerPage;
        const take = itemPerPage;

        const url = `${getBatchAuditLogApiUrl(accountId)}query?skip=${skip}&take=${take}`;

        const data = this.applyFilters(timeFilter, productFilter, modelFilter, statusFilter);
        const auditLogs = await this.makeRequest('post', url, token, accountId, data);

        if (Array.isArray(auditLogs)) {
            return this.processAuditLogs(auditLogs);
        }
        console.error('Unexpected response format:', auditLogs);
        return [];
    }

    // Fetch a single audit log
    static async fetchSingleAuditLog(token: string, accountId: string, selectedRecord: IGridDataEntry): Promise<IGridDataEntry | null> {
        const url = getSingleAuditLogApiUrl(accountId, selectedRecord.firstRequestId, selectedRecord.lastRequestId);

        const auditLog = await this.makeRequest('get', url, token, accountId);
        if (auditLog?.requestId) {
            return this.processSingleAuditLog(auditLog);
        }

        console.error('Unexpected response format for single audit log:', auditLog);
        return null;
    }

    static async fetchFilteredAuditLogSummary(
        token: string, accountId: string,
        productFilter: string | null = null,
        timeFilter: 'lastDay' | 'lastWeek' | 'last30Days' | null = null,
        modelFilter: string | null = null,
        statusFilter: string | null = null
    ): Promise<IAuditLogSummary> {
        const cachedData = this.getCachedProdModelData(accountId);

        if (!cachedData) {
            const summary = await this.fetchProdModelListFromApi(token, accountId);
            this.cacheProdModelData(accountId, summary.distinctProducts, summary.distinctUsedModels);
            return summary;
        }
        const numberRecords = await this.fetchTotalRecords(token, accountId, productFilter, timeFilter, modelFilter, statusFilter);

        return {
            totalRecords: numberRecords,
            distinctProducts: cachedData.DistinctProducts,
            distinctUsedModels: cachedData.DistinctUsedModels,
        } as IAuditLogSummary;
    }

    static async fetchTotalRecords(
        token: string, accountId: string,
        productFilter: string | null,
        timeFilter: 'lastDay' | 'lastWeek' | 'last30Days' | 'last60Days' | null = null,
        modelFilter: string | null = null,
        statusFilter: string | null = null): Promise<number> {
        const url = `${getFilteredAuditLogSummaryApiUrl(accountId)}`;
        const data = this.applyFilters(timeFilter, productFilter, modelFilter, statusFilter);
        const response = await this.makeRequest('post', url, token, accountId, data);

        return response.totalRecords;
    }

    static async fetchProdModelListFromApi(token: string, accountId: string): Promise<IAuditLogSummary> {
        const data = {
            StartDate: '2024-01-01',
            EndDate: '2024-12-31',
        };
        const url = `${getFilteredAuditLogSummaryApiUrl(accountId)}?includeProdModelList=true`;
        const response = await this.makeRequest('post', url, token, accountId, data);
        return {
            totalRecords: response.totalRecords,
            distinctProducts: response.distinctProducts,
            distinctUsedModels: response.distinctUsedModels,
        } as IAuditLogSummary;
    }

    static getCachedProdModelData(accountId: string) {
        const cacheKey = `auditLog_${accountId}_prodModel`;
        const cachedData = localStorage.getItem(cacheKey);

        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const now = new Date();

            if (now.getTime() < parsedData.expiry) {
                if (parsedData.DistinctProducts && parsedData.DistinctUsedModels) {
                    return parsedData;
                }
            }
            localStorage.removeItem(cacheKey);
        }
        return null;
    }

    static cacheProdModelData(accountId: string, DistinctProducts: string[], DistinctUsedModels: string[]) {
        const cacheKey = `auditLog_${accountId}_prodModel`;
        const now = new Date();

        const cacheData = {
            DistinctProducts,
            DistinctUsedModels,
            expiry: now.getTime() + 24 * 60 * 60 * 1000,
        };

        localStorage.setItem(cacheKey, JSON.stringify(cacheData));
    }

    static processAuditLogs(auditLogs: any[]): IGridDataEntry[] {
        return auditLogs.map(this.processSingleAuditLog);
    }

    static processSingleAuditLog(log: any): IGridDataEntry {
        return {
            id: log.requestId,
            requestId: log.requestId,
            actionId: log.actionId,
            date: log.date,
            user: log.userName,
            tenant: log.tenantId,
            product: log.product,
            feature: log.feature,
            modelInput: log.input,
            modelOutput: log.output,
            modelUsed: log.modelUsed,
            modelLocation: log.modelLocation,
            status: log.status,
            accountId: log.accountId,
            firstRequestId: log.firstRequestId,
            lastRequestId: log.lastRequestId,
            dataGovernance: log.dataGovernance,
            isBlocked: log.isBlocked,
            filters: log.filters,
            additionalInfo: log.additionalInfo,
            agentInstanceId: log.agentInstanceId,
            isInputOutputParsingOnboarded: log.isInputOutputParsingOnboarded,
        };
    }

    static async fetchInputOutputForRecord(
        token: string,
        accountId: string,
        row: IGridDataEntry,
        setInputOutputData: (data: { input: string | null; output: string | null; isInputOutputParsingOnboarded: boolean | null }) => void,
        setSelectedRecord: (record: IGridDataEntry | null) => void,
        setCurrentIndex: (index: number) => void,
        filteredData: IGridDataEntry[]
    ): Promise<void> {

        const inputOutputData = await this.fetchSingleAuditLog(token, accountId, row);

        if (inputOutputData) {
            setInputOutputData({
                input: inputOutputData.modelInput,
                output: inputOutputData.modelOutput,
                isInputOutputParsingOnboarded: inputOutputData.isInputOutputParsingOnboarded,
            });
        } else {
            setInputOutputData({
                input: null,
                output: null,
                isInputOutputParsingOnboarded: null,
            });
        }

        const index = filteredData.findIndex(item => item.id === row.id);
        setSelectedRecord(row);
        setCurrentIndex(index);
    }
}
