import type { IPagination } from '@experiences/interfaces';
import {
    UiProgressButton,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import { useShowDialog } from '@experiences/util';
import { TextField } from '@mui/material';
import type { Tenant } from '@uipath/portal-shell/dist/types/models/tenant';
import {
    ApButton,
    ApDataGridColumn,
    ApDataGridFooter,
    ApDataGridRowActions,
    ApDataGridRowButton,
    ApDataGridWrapper,
    ApDropdown,
    ApDropdownItem,
} from '@uipath/portal-shell-react';
import type { ApDropdownCustomEvent } from '@uipath/portal-shell-types';
import type { ChangeEvent } from 'react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import type {
    ContextGroundingIndexes,
    TenantConfiguration,
} from '../../../../services/a4e/A4EServiceTypes';
import type { DataSet as ContextGroundingDataSet } from '../../../../services/ecs/Ecs.types';
import { getDataSets } from '../../../../services/ecs/EcsService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../../../store/selectors';
import { UiDrawer } from '../../../common/UiDrawer';
import UiForm from '../../../common/UiForm';
import { UiAccordion } from '../../../licensing/UiAccordion';
import {
    readTenantSettingsFromAutopilot,
    uploadTenantSettingsToAutopilot,
} from '../utils/A4EContextGroundingUtils';
import type { A4EErrorMessage } from '../utils/A4EErrorType';

interface A4EContextGroundingComponentProps {
    selectedTenant: Tenant;
    autopilotFolderId?: number;
}

const getContextGroundingIndexes = async (organizationId: string, selectedTenantId: string | undefined): Promise<ContextGroundingDataSet[]> => {
    if (!selectedTenantId) {
        return [];
    }
    return await getDataSets(organizationId, selectedTenantId);
};

const A4EContextGroundingComponent: React.FC<A4EContextGroundingComponentProps> = ({
    selectedTenant, autopilotFolderId,
}) => {
    const organizationId = useSelector(accountGlobalId);
    const organizationName = useSelector(accountLogicalName);
    const { formatMessage: translate } = useIntl();
    const [ showEditPanel, setShowEditPanel ] = useState(false);
    const [ enabledIndexesFromA4E, setEnabledIndexesFromA4E ] = useState<ContextGroundingIndexes[]>([]);
    const [ pagination, setPagination ] = useState<IPagination>({
        top: 25,
        skip: 0,
    });
    const [ autopilotTenantConfig, setAutopilotTenantConfig ] = useState<TenantConfiguration>({ contextGroundingIndexes: [] });
    const [ isLoadingTenantSettings, setIsLoadingTenantSettings ] = useState(false);
    const createDialog = useShowDialog();
    const createNotification = useUiSnackBar();

    const createErrorNotification = useCallback((error: A4EErrorMessage) => {
        const code = error?.status ? `${error.status}: ` : '';
        const message = translate({ id: error.errorId });
        createNotification(`${message} - ${code}`, notificationType.ERROR);
    }, [ createNotification, translate ]);

    // fetch ECS data
    const {
        data: contextGroundingData,
        isLoading: isLoadingDataSets,
    } = useSWR(selectedTenant?.id ? [ 'datasets', organizationId, selectedTenant ] : undefined, () => getContextGroundingIndexes(organizationId, selectedTenant?.id));

    // form data handling
    const methods = useForm<ContextGroundingIndexes>({
        mode: 'onSubmit',
        defaultValues: {
            id: '',
            indexName: '',
            indexDescription: '',
            folder: '',
        },
        shouldUnregister: false,
    });
    const {
        watch, handleSubmit, reset, register, formState: {
            errors, isValid, isSubmitting,
        },
    } = methods;

    const onSubmit = useCallback(async (editedIndex: ContextGroundingIndexes) => {
        try {
            let newEnabledIndexesFromA4E = [ ...enabledIndexesFromA4E ];
            const isEditing = newEnabledIndexesFromA4E.find(index => index.id === editedIndex.id);
            if (isEditing) {
                newEnabledIndexesFromA4E = newEnabledIndexesFromA4E.map(index => (index.id === editedIndex.id) ? editedIndex : index);
            } else {
                newEnabledIndexesFromA4E.push(editedIndex);
            }

            if (selectedTenant) {
                await uploadTenantSettingsToAutopilot({
                    folderId: `${autopilotFolderId}`,
                    organizationName,
                    tenantName: selectedTenant.name,
                    autopilotTenantConfig,
                    contextGroundingData: newEnabledIndexesFromA4E,
                });
                setEnabledIndexesFromA4E(newEnabledIndexesFromA4E);
            }
            setShowEditPanel(false);
        } catch (e) {
            createErrorNotification(e as A4EErrorMessage);
        }
    }, [ enabledIndexesFromA4E, selectedTenant, autopilotFolderId, organizationName, autopilotTenantConfig, createErrorNotification ]);

    const onSelectDropdown = useCallback((e: ApDropdownCustomEvent<string | string[]>) => {
        const selectedIndexId = e.detail as string;
        const selectedIndex = contextGroundingData?.find(cgData => cgData.schema.id === selectedIndexId);
        methods.setValue('folder', `/${selectedIndex?.datasource?.properties?.folderName ?? ''}`);
        methods.setValue('indexName', selectedIndex?.schema.name ?? '');
        methods.setValue('id', selectedIndexId);
        methods.trigger('id');
    }, [ contextGroundingData, methods ]);

    const openEditPanel = useCallback((data?: ContextGroundingIndexes) => {
        setShowEditPanel(true);
        const isAlreadyEnabledIndex = data;
        if (isAlreadyEnabledIndex) {
            methods.setValue('id', data.id);
            methods.setValue('indexName', data.indexName);
            methods.setValue('indexDescription', data.indexDescription);
            methods.setValue('folder', data.folder);
        } else {
            reset();
        }
    }, [ setShowEditPanel, methods, reset ]);

    const deleteIndexMapping = useCallback(async (dataToDelete: ContextGroundingIndexes) => {
        const proceedClicked = await createDialog({
            title: translate({ id: 'CLIENT_DELETE_INDEX' }),
            body: (
                <div>
                    <UiText>
                        {translate({ id: 'CLIENT_DELETE_CONTEXT_GROUNDING_INDEX_WARNING' })}
                    </UiText>
                </div>
            ),
            icon: 'error',
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
        });
        if (proceedClicked) {
            setIsLoadingTenantSettings(true);
            const newEnabledIndexesFromA4E = enabledIndexesFromA4E.filter(index => index.id !== dataToDelete.id);

            try {
                if (selectedTenant) {
                    await uploadTenantSettingsToAutopilot({
                        folderId: `${autopilotFolderId}`,
                        organizationName,
                        tenantName: selectedTenant.name,
                        autopilotTenantConfig,
                        contextGroundingData: newEnabledIndexesFromA4E,
                    });
                    setEnabledIndexesFromA4E(newEnabledIndexesFromA4E);
                }
            } catch (e) {
                createErrorNotification(e as A4EErrorMessage);
            } finally {
                setIsLoadingTenantSettings(false);
            }
        }
    }, [ createDialog, translate, enabledIndexesFromA4E, selectedTenant, autopilotFolderId, organizationName, autopilotTenantConfig, createErrorNotification ]);

    const onChangeTextField = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        methods.setValue('indexDescription', e.target.value);
        methods.trigger('indexDescription');
    }, [ methods ]);

    useEffect(() => {
        const loadTenantSettings = async () => {
            if (autopilotFolderId) {
                setIsLoadingTenantSettings(true);

                // get autopilot.json
                let autopilotTenantConfigJson: TenantConfiguration = { contextGroundingIndexes: [] };
                try {
                    autopilotTenantConfigJson = await readTenantSettingsFromAutopilot({
                        folderId: `${autopilotFolderId}`,
                        organizationName,
                        tenantName: selectedTenant.name,
                    }) as TenantConfiguration;
                } catch (error) {
                    // create autopilot bucket (and tenant settings) if DNE
                    await uploadTenantSettingsToAutopilot({
                        folderId: `${autopilotFolderId}`,
                        organizationName,
                        tenantName: selectedTenant.name,
                        autopilotTenantConfig: {},
                        contextGroundingData: [],
                    });
                    setEnabledIndexesFromA4E([]);
                }

                // set tenant config states
                if (autopilotTenantConfigJson?.contextGroundingIndexes && Array.isArray(autopilotTenantConfigJson.contextGroundingIndexes)) {
                    setAutopilotTenantConfig(autopilotTenantConfigJson);
                    setEnabledIndexesFromA4E(autopilotTenantConfigJson.contextGroundingIndexes as ContextGroundingIndexes[]);
                }
            }
        };

        loadTenantSettings()
            .catch(e => {
                createErrorNotification(e as A4EErrorMessage);
            })
            .finally(() => {
                setIsLoadingTenantSettings(false);
            });
    }, [ autopilotFolderId, createErrorNotification, organizationName, selectedTenant ]);

    const dropdownErrorMessage = () => {
        let errorMessage = '';
        if (contextGroundingData && contextGroundingData.length === 0) {
            errorMessage = translate({ id: 'CLIENT_A4E_INDEX_NAME_MISSING' });
        } else if (errors?.id) {
            errorMessage = errors.id.message ?? '';
        }
        return errorMessage;
    };

    const onGridChange = useCallback(({
        pageIndex, pageSize,
    }: any) => {
        const newPaginationState: IPagination = {
            top: pageSize ?? 25,
            skip: (pageIndex ?? 0) * (pageSize ?? 25),
        };

        setPagination(newPaginationState);
    }, [ ]);

    const paginatedTableData = useMemo(() =>
        enabledIndexesFromA4E.slice(pagination.skip, pagination.skip + pagination.top),
    [ pagination.skip, pagination.top, enabledIndexesFromA4E ]);

    return (
        <UiAccordion
            titleTranslationCode="CLIENT_AI_TRUST_LAYER_CONTEXT_GROUNDING_TAB"
            expandedByDefault={false}
            disabled={!autopilotFolderId}
            data-cy="context-grounding-accordion"
        >
            <UiStack
                justify='end'
                m='10px'
            >
                <ApButton
                    label={translate({ id: 'CLIENT_ADD_INDEX' })}
                    variant='primary'
                    disabled={isLoadingDataSets || isLoadingTenantSettings || !selectedTenant}
                    onClick={() => openEditPanel()}
                    data-cy="add-index-button"
                />
            </UiStack>

            <ApDataGridWrapper
                data={paginatedTableData}
                selectable={false}
                loading={isLoadingDataSets || isLoadingTenantSettings}
                onChange={onGridChange}
                dataCy="context-grounding-grid">

                <ApDataGridColumn<ContextGroundingIndexes>
                    property="indexName"
                    title={translate({ id: 'CLIENT_INDEX_NAME' })}
                    width={15}
                    searchable
                    sortable
                />
                <ApDataGridColumn<ContextGroundingIndexes>
                    property="folder"
                    title={translate({ id: 'CLIENT_TABLE_HEADER_FOLDER' })}
                    width={5}
                    sortable
                />
                <ApDataGridColumn<ContextGroundingIndexes>
                    property="indexDescription"
                    title={translate({ id: 'CLIENT_DESCRIPTION' })}
                    width={30}
                    sortable
                />
                <ApDataGridRowActions>
                    <ApDataGridRowButton<ContextGroundingIndexes>
                        id='edit'
                        label={translate({ id: 'CLIENT_EDIT' })}
                        icon='edit'
                        onClick={(row) => openEditPanel(row)}
                    />
                    <ApDataGridRowButton<ContextGroundingIndexes>
                        id='delete'
                        label={translate({ id: 'CLIENT_DELETE' })}
                        icon='delete'
                        onClick={deleteIndexMapping}
                    />
                </ApDataGridRowActions>
                <ApDataGridFooter
                    length={enabledIndexesFromA4E ? enabledIndexesFromA4E.length : 0}
                    pageSizes={[ 5, 10, 25, 50 ]}
                />
            </ApDataGridWrapper>

            {showEditPanel && (
                <UiDrawer
                    title={
                        watch('id') === ''
                            ? translate({ id: 'CLIENT_ADD_INDEX_TO_AUTOPILOT' })
                            : translate({ id: 'CLIENT_EDIT_CONTEXT_GROUNDING_INDEX' })
                    }
                    width="large"
                    drawerProps={{
                        anchor: 'right',
                        open: showEditPanel,
                        onClose: () => setShowEditPanel(false),
                    }}
                >
                    <FormProvider {...methods}>
                        <UiForm
                            onSubmit={handleSubmit(onSubmit)}
                            isDrawer
                            actions={
                                <UiStack
                                    justify='end'
                                    align='center'
                                >
                                    <ApButton
                                        style={{ marginRight: '10px' }}
                                        label={translate({ id: 'CLIENT_CANCEL' })}
                                        onClick={() => setShowEditPanel(false)}
                                        variant="tertiary"
                                        aria-label={translate({ id: 'CLIENT_CANCEL' })}
                                        data-cy='cancel-button' />
                                    <UiProgressButton
                                        type="submit"
                                        loading={isSubmitting}
                                        disabled={!isValid}
                                        variant="contained"
                                        data-cy="add-button"
                                        aria-label={translate({ id: 'CLIENT_ADD' })}
                                    >
                                        {translate({ id: 'CLIENT_ADD' })}
                                    </UiProgressButton>
                                </UiStack>
                            }
                        >
                            <UiStack mt={20}>
                                <ApDropdown
                                    {...register('id', { required: translate({ id: 'CLIENT_INDEX_NAME_REQUIRED' }) })}
                                    style={{ 'width': '100%' }}
                                    label={translate({ id: 'CLIENT_INDEX_NAME' })}
                                    aria-label={translate({ id: 'CLIENT_INDEX_NAME' })}
                                    data-cy="indexname-select-dropdown"
                                    placeholder={translate({ id: 'CLIENT_A4E_INDEX_NAME_PLACEHOLDER' })}
                                    selectedValue={methods.watch('id')}
                                    onSelectedValueChanged={onSelectDropdown}
                                    onBlur={() => methods.trigger('id')}
                                    errorMessage={dropdownErrorMessage()}
                                    disabled={contextGroundingData?.length === 0}>
                                    {contextGroundingData?.map(ecsIndex => (
                                        <ApDropdownItem
                                            value={ecsIndex.schema.id}
                                            label={ecsIndex.schema.name}
                                            key={`ap-dropdown-item-${ecsIndex.schema.id}`}
                                        />
                                    ))}
                                </ApDropdown>
                            </UiStack>
                            <UiStack mt={20}>
                                <TextField
                                    {...register('indexDescription', {
                                        required: translate({ id: 'CLIENT_DESCRIPTION_REQUIRED' }),
                                        maxLength: {
                                            value: 500,
                                            message: translate({ id: 'CLIENT_DESCRIPTION_MAX_ERROR' }),
                                        },
                                    })}
                                    required
                                    variant="outlined"
                                    label={translate({ id: 'CLIENT_DESCRIPTION_FOR_AUTOPILOT' })}
                                    aria-label={translate({ id: 'CLIENT_DESCRIPTION_FOR_AUTOPILOT' })}
                                    data-cy='description-for-autopilot'
                                    fullWidth
                                    multiline
                                    rows={6}
                                    placeholder={translate({ id: 'CLIENT_A4E_INDEX_DESCRIPTION_PLACEHOLDER' })}
                                    onBlur={() => methods.trigger('indexDescription')}
                                    onChange={onChangeTextField}
                                    error={!!errors.indexDescription}
                                    helperText={
                                        <div>
                                            {`${methods.watch('indexDescription').length}/500`}
                                            {errors.indexDescription?.message && <br />}
                                            {errors.indexDescription?.message}
                                        </div>
                                    }
                                />
                            </UiStack>
                        </UiForm>
                    </FormProvider>
                </UiDrawer>
            )}
        </UiAccordion>
    );
};

export default A4EContextGroundingComponent;
