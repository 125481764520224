import { GlobalStyles } from '@experiences/theme';
import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import {
    ApButton,
    ApDropdown,
    ApDropdownItem,
    ApTooltip,
} from '@uipath/portal-shell-react';
import type { ApDropdownCustomEvent } from '@uipath/portal-shell-types';
import { isEqual } from 'lodash';
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Controller,
    useForm,
    useWatch,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../../common/constants/Constant';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import type {
    ContextGroundingIndexes,
    LaunchAcknowledgementConfigInterface,
    TenantConfiguration,
    TenantUIConfigInterface,
} from '../../../../services/a4e/A4EServiceTypes';
import { accountLogicalName } from '../../../../store/selectors';
import { UiAccordion } from '../../../licensing/UiAccordion';
import type {
    A4EAdvancedSettingsComponentProps,
    IA4EAdvancedSettingsConfiguration,
} from '../utils/A4EAdvancedSettingUtils';
import {
    advancedSettingsTenantConfigDefaultValues,
    buildAutopilotTenantConfig,
    getDefaultValues,
    readTenantSettingsFromAutopilot,
    uploadTenantSettingsToAutopilot,
} from '../utils/A4EAdvancedSettingUtils';
import type { A4EErrorMessage } from '../utils/A4EErrorType';
import A4EAdvancedSettingCoBrandingThemeDrawerComponent from './A4EAdvancedSettingsCoBrandingThemeDrawerComponent';
import A4EAdvancedSettingUserAcknowledgementDrawerComponent from './A4EAdvancedSettingsUserAcknowledgementDrawerComponent';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        rowGroup: {
            display: 'flex',
            flexDirection: 'row',
            padding: '4px 10px',
        },
        radio: {
            maxHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        infoIcon: {
            width: '20px',
            fontWeight: '600',
        },
    }),
}));

const A4EAdvancedSettingsComponent: React.FC<A4EAdvancedSettingsComponentProps> = ({
    selectedTenant, autopilotFolderId,
}) => {
    const organizationName = useSelector(accountLogicalName);
    const [ userAcknowledementDrawerIsOpen, setUserAcknowledementDrawerIsOpen ] = useState(false);
    const [ coBrandingThemeDrawerIsOpen, setCoBrandingThemeDrawerIsOpen ] = useState(false);
    const [ autopilotTenantConfig, setAutopilotTenantConfig ] = useState<TenantConfiguration>({} as TenantConfiguration);
    const [ isLoadingTenantSettings, setIsLoadingTenantSettings ] = useState(false);
    const [ isSavingTenantSettings, setIsSavingTenantSettings ] = useState(false);
    const [ userAcknowledgementDrawerDefaultValues, setUserAcknowledgementDrawerDefaultValues ] = useState<LaunchAcknowledgementConfigInterface | null>(null);
    const [ coBrandingThemeDrawerDefaultValues, setCoBrandingThemeDrawerDefaultValues ] = useState<TenantUIConfigInterface | null>(null);
    const [ contextGroundingIndexes, setContextGroundingIndexes ] = useState<ContextGroundingIndexes[]>([]);
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const systemPromptRef = useRef<HTMLInputElement | null>(null);
    const suggestedPromptRef = useRef<HTMLInputElement | null>(null);
    const chatHistoryRef = useRef<HTMLInputElement | null>(null);
    const autoEcsIndexRef = useRef<HTMLInputElement | null>(null);
    const ecsResultNumRef = useRef<HTMLInputElement | null>(null);
    const defaultValuesRef = useRef<IA4EAdvancedSettingsConfiguration | null>(null);
    const classes = useStyles();

    const createErrorNotification = useCallback((error: A4EErrorMessage) => {
        const code = error?.status ? `${error.status}: ` : '';
        const message = translate({ id: error.errorId });
        createNotification(`${message} - ${code}`, notificationType.ERROR);
    }, [ createNotification, translate ]);

    const {
        control,
        handleSubmit,
        reset,
        register,
        setValue,
        trigger,
        getValues,
        watch,
        formState: {
            errors, isValid,
        },
    } =
    useForm<IA4EAdvancedSettingsConfiguration>({
        mode: 'all',
        defaultValues: advancedSettingsTenantConfigDefaultValues,
        shouldUnregister: false,
    });

    useEffect(() => {
        const loadTenantSettings = async () => {
            if (autopilotFolderId) {
                setIsLoadingTenantSettings(true);

                let autopilotTenantConfigJson: TenantConfiguration = {} as TenantConfiguration;
                try {
                    // get autopilot.json
                    try {
                        autopilotTenantConfigJson = await readTenantSettingsFromAutopilot({
                            folderId: `${autopilotFolderId}`,
                            organizationName,
                            tenantName: selectedTenant.name,
                        }) as TenantConfiguration;
                    } catch (error) {
                        // create autopilot bucket (and tenant settings) if DNE
                        await uploadTenantSettingsToAutopilot({
                            folderId: `${autopilotFolderId}`,
                            organizationName,
                            tenantName: selectedTenant.name,
                            autopilotTenantConfig: {},
                            contextGroundingData: [],
                        });
                    }
                } catch (error) {
                    // show can't load tenant settings
                    createErrorNotification(error as A4EErrorMessage);
                    setIsLoadingTenantSettings(false);
                } finally {
                    // context grounding indexes from autopilot.json
                    setContextGroundingIndexes(autopilotTenantConfigJson?.contextGroundingIndexes ?? []);
                    // set default values or values from autopilot.json if any
                    const defaultValues = getDefaultValues(autopilotTenantConfigJson);
                    defaultValuesRef.current = defaultValues;
                    reset(defaultValues);
                    // set tenant config states
                    setAutopilotTenantConfig(autopilotTenantConfigJson);
                    setIsLoadingTenantSettings(false);
                }
            }
        };

        loadTenantSettings();
    }, [ autopilotFolderId, createErrorNotification, organizationName, reset, selectedTenant.name ]);

    const onECSIndexSelectDropdown = useCallback((e: ApDropdownCustomEvent<string | string[]>) => {
        const selectedIndexId = e.detail as string;
        setValue('autoSearchECSIndex', selectedIndexId);
        trigger('autoSearchECSIndex');
    }, [ setValue, trigger ]);

    const onSubmit = useCallback(async (data: IA4EAdvancedSettingsConfiguration) => {
        setIsSavingTenantSettings(true);
        const autopilotTenantConfigObjToSave = buildAutopilotTenantConfig(data, autopilotTenantConfig);
        try {
            await uploadTenantSettingsToAutopilot({
                folderId: `${autopilotFolderId}`,
                organizationName,
                tenantName: selectedTenant.name,
                autopilotTenantConfig: autopilotTenantConfigObjToSave,
                contextGroundingData: autopilotTenantConfigObjToSave?.contextGroundingIndexes ?? [],
            });
            createNotification(translate({ id: 'A4E_ADVANCED_SETTINGS_SUCCESS_SAVE' }), notificationType.SUCCESS);
            const newDefaultValues = getDefaultValues(autopilotTenantConfigObjToSave);
            defaultValuesRef.current = newDefaultValues;
            reset(newDefaultValues);
        } catch (error) {
            createErrorNotification(error as A4EErrorMessage);
        } finally {
            setIsSavingTenantSettings(false);
        }
    }, [ autopilotFolderId, autopilotTenantConfig, createErrorNotification, createNotification, organizationName, reset, selectedTenant.name, translate ]);

    const isSystemPromptHintsEnabled = watch('administratorCorePromptNotesEnabled') === 'true';
    const isSuggestedPromptsEnabled = watch('suggestedPromptsEnabled') === 'true';
    const isSuggestedPromptHintsEnabled = watch('administratorSuggestedFollowUpsPromptNotesEnabled') === 'true';
    const isChatHistoryEnabled = watch('chatHistoryEnabled') === 'true';
    const isAutoSearchECSEnabled = watch('autoSearchECSEnabled') === 'true';
    const isECSSearchResultEnabled = watch('ecsNumberOfSearchResultsEnabled') === 'true';
    const isLaunchAcknowledgementConfigValid =
        watch('launchAcknowledgementConfigEnabled') !== 'true' ||
            (watch('launchAcknowledgementConfig.headerText')?.trim().length > 0 && watch('launchAcknowledgementConfig.messageText')?.trim().length > 0);
    const isTenantUIConfigValid =
        watch('tenantUIConfigEnabled') !== 'true' ||
           !!(watch('tenantUIConfig.appBar.lightModeBackgroundColor')?.trim()
            && watch('tenantUIConfig.appBar.darkModeBackgroundColor')?.trim()
            && watch('tenantUIConfig.appBar.darkModeTextColor')?.trim()
            && watch('tenantUIConfig.appBar.lightModeTextColor')?.trim());
    const isContextGroundingIndexValid = !isAutoSearchECSEnabled || contextGroundingIndexes.find(index => index.id === watch('autoSearchECSIndex'));

    useEffect(() => {
        const loadContextGroundingIndexes = async () => {
            if (autopilotFolderId) {
                setIsLoadingTenantSettings(true);
                let autopilotTenantConfigJson: TenantConfiguration = {} as TenantConfiguration;
                try {
                    // get autopilot.json
                    autopilotTenantConfigJson = await readTenantSettingsFromAutopilot({
                        folderId: `${autopilotFolderId}`,
                        organizationName,
                        tenantName: selectedTenant.name,
                    });
                } catch (error) {
                    // show can't load context grounding indexes
                    const a4eError = error as A4EErrorMessage;
                    a4eError.errorId = 'A4E_ADVANCED_SETTINGS_ERROR_LOAD_CONTEXT_GROUNDING_INDEXES';
                    createErrorNotification(a4eError);
                } finally {
                    // context grounding indexes from autopilot.json
                    setContextGroundingIndexes(autopilotTenantConfigJson?.contextGroundingIndexes ?? []);
                    const updatedAutopilotTenantConfig: TenantConfiguration = {
                        ...autopilotTenantConfig,
                        contextGroundingIndexes: autopilotTenantConfigJson?.contextGroundingIndexes ?? [],
                    };
                    setAutopilotTenantConfig(updatedAutopilotTenantConfig);
                    setIsLoadingTenantSettings(false);
                }
            }
        };
        if (isAutoSearchECSEnabled) {
            loadContextGroundingIndexes();
        }
    }, [ isAutoSearchECSEnabled ]);

    useEffect(() => {
        if (!isSuggestedPromptsEnabled) {
            // we want to disable suggested prompt hints too
            setValue('administratorSuggestedFollowUpsPromptNotesEnabled', 'false');
        }
    }, [ isSuggestedPromptsEnabled, setValue ]);

    useEffect(() => {
        if (isSystemPromptHintsEnabled && systemPromptRef.current) {
            systemPromptRef.current.focus();
        }
    }, [ isSystemPromptHintsEnabled ]);

    useEffect(() => {
        if (isSuggestedPromptHintsEnabled && suggestedPromptRef.current) {
            suggestedPromptRef.current.focus();
        }
    }, [ isSuggestedPromptHintsEnabled ]);

    useEffect(() => {
        if (isChatHistoryEnabled && chatHistoryRef.current) {
            chatHistoryRef.current.focus();
        }
    }, [ isChatHistoryEnabled ]);

    useEffect(() => {
        if (isAutoSearchECSEnabled && autoEcsIndexRef.current) {
            autoEcsIndexRef.current.focus();
        }
    }, [ isAutoSearchECSEnabled ]);

    useEffect(() => {
        if (isECSSearchResultEnabled && ecsResultNumRef.current) {
            ecsResultNumRef.current.focus();
        }
    }, [ isECSSearchResultEnabled ]);

    const watchedValues = useWatch({ control });
    const isFormDirty = useCallback(() => {
        // compare the current values with the default values, which are stored in defaultValuesRef.current
        // not using isDirty from react-hook-form because we need to implement different logic
        const currentValues = watchedValues;
        const defaultValues = defaultValuesRef.current;
        if (!defaultValues) {
            return false;
        }
        const booleanStrings = [
            'fileUploadEnabled',
            'userNotesEnabled',
            'chatHistoryEnabled',
            'sendFeedbackToUiPathEnabled',
            'suggestedPromptsEnabled',
            'launchAcknowledgementConfigEnabled',
            'tenantUIConfigEnabled',
            'administratorCorePromptNotesEnabled',
            'administratorSuggestedFollowUpsPromptNotesEnabled',
            'autoSearchECSEnabled',
            'ecsNumberOfSearchResultsEnabled',
        ];
        for (const booleanString of booleanStrings) {
            if (currentValues[booleanString] !== defaultValues[booleanString]) {
                return true;
            }
        }
        // check other scenarios
        if (defaultValues.administratorCorePromptNotes !== currentValues.administratorCorePromptNotes && (defaultValues.administratorCorePromptNotesEnabled !== 'false' ||
            currentValues.administratorCorePromptNotesEnabled !== 'false')) {
            return true;
        }
        if (defaultValues.administratorSuggestedFollowUpsPromptNotes !== currentValues.administratorSuggestedFollowUpsPromptNotes &&
            (defaultValues.administratorSuggestedFollowUpsPromptNotesEnabled !== 'false' ||
            currentValues.administratorSuggestedFollowUpsPromptNotesEnabled !== 'false')
        ) {
            return true;
        }
        if (defaultValues.chatHistoryVisibilityDays !== currentValues.chatHistoryVisibilityDays && (defaultValues.chatHistoryEnabled !== 'false' ||
            currentValues.chatHistoryEnabled !== 'false')) {
            return true;
        }
        if (defaultValues.ecsNumberOfSearchResults !== currentValues.ecsNumberOfSearchResults && (defaultValues.ecsNumberOfSearchResultsEnabled !== 'false' ||
            currentValues.ecsNumberOfSearchResultsEnabled !== 'false')) {
            return true;
        }

        if (defaultValues.autoSearchECSIndex !== currentValues.autoSearchECSIndex && (defaultValues.autoSearchECSEnabled !== 'false' ||
            currentValues.autoSearchECSEnabled !== 'false')) {
            return true;
        }
        if (!isEqual(defaultValues.launchAcknowledgementConfig, currentValues.launchAcknowledgementConfig)
            && (defaultValues.launchAcknowledgementConfigEnabled !== 'false' || currentValues.launchAcknowledgementConfigEnabled !== 'false')) {
            return true;
        }

        if (!isEqual(defaultValues.tenantUIConfig, currentValues.tenantUIConfig)
            && (defaultValues.tenantUIConfigEnabled !== 'false' || currentValues.tenantUIConfigEnabled !== 'false')) {
            return true;
        }

        return false;
    }, [ watchedValues ]);

    const createSettingItem = useCallback((
        name: string,
        labelId: string,
        tooltipId: string,
        dataCy: string,
        disabled: boolean = false
    ) => (<>
        <UiStack
            direction='row'
            align='center'
            gap={SpacingToken.XS}>
            <UiText
                role='heading'
                aria-level={2}>
                {translate({ id: labelId })}
            </UiText>
            <ApTooltip content={translate({ id: tooltipId })}>
                <InfoOutlinedIcon
                    className={classes.infoIcon}
                    tabIndex={0}
                    aria-hidden="false"
                    aria-label={translate({ id: tooltipId })}
                />
            </ApTooltip>
        </UiStack>
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <RadioGroup
                    className={classes.rowGroup}
                    value={field.value}
                    onChange={e => field.onChange(e.target.value)}
                    data-cy={`${dataCy}-radio-group`}
                >
                    <FormControlLabel
                        value="true"
                        control={<Radio color="primary" />}
                        label={translate({ id: 'CLIENT_ENABLE' })}
                        data-cy={`${dataCy}-enable-radio`}
                        disabled={disabled}
                    />
                    <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label={translate({ id: 'CLIENT_DISABLE' })}
                        data-cy={`${dataCy}-disable-radio`}
                        disabled={disabled}
                    />
                </RadioGroup>
            )}
        />
    </>), [ classes.infoIcon, classes.rowGroup, control, translate ]);

    const dropdownErrorMessage = useCallback(() => {
        let errorMessage = '';
        const autoSearchECSIndex = watch('autoSearchECSIndex');
        const indexIsInvalid = autoSearchECSIndex && !contextGroundingIndexes.find(index => index.id === autoSearchECSIndex);
        // check if autoSearchECSIndex is in the contextGroundingIndexes just in case the user directly modified the value in autopilot.json
        if (contextGroundingIndexes.length === 0 && indexIsInvalid) {
            errorMessage = translate({ id: 'CLIENT_A4E_INDEX_NAME_INVALID_AND_NO_OPTIONS' });
        } else if (indexIsInvalid) {
            errorMessage = translate({ id: 'CLIENT_A4E_INDEX_NAME_INVALID' });
        } else if (contextGroundingIndexes.length === 0) {
            errorMessage = translate({ id: 'CLIENT_A4E_INDEX_NAME_MISSING' });
        }
        return errorMessage;
    }, [ contextGroundingIndexes, translate, watch ]);

    const formIsDirty = isFormDirty();

    return (
        <UiAccordion
            titleTranslationCode="A4E_ADVANCED_SETTINGS"
            expandedByDefault={false}
            disabled={!autopilotFolderId}
        >
            <UiStack
                direction='column'
                data-cy='a4e-advanced-settings'
                gap={SpacingToken.XS}
            >
                <UiStack direction='column'>
                    {createSettingItem(
                        'fileUploadEnabled',
                        'A4E_ADVANCED_SETTINGS_FILE_UPLOAD',
                        'A4E_ADVANCED_SETTINGS_FILE_UPLOAD_TOOLTIP',
                        'file-upload')}
                </UiStack>
                <UiStack direction='column'>
                    {createSettingItem(
                        'administratorCorePromptNotesEnabled',
                        'A4E_ADVANCED_SETTINGS_SYSTEM_PROMPT_HINTS',
                        'A4E_ADVANCED_SETTINGS_SYSTEM_PROMPT_HINTS_TOOLTIP',
                        'system-prompt')}
                    {isSystemPromptHintsEnabled && (
                        <TextField
                            inputProps={register('administratorCorePromptNotes', {
                                minLength: 1,
                                validate: { required: p => isSystemPromptHintsEnabled ? !!p?.trim() : true },
                            })}
                            required={isSystemPromptHintsEnabled}
                            error={!!errors.administratorCorePromptNotes}
                            helperText={
                                errors.administratorCorePromptNotes?.type === 'required'
                                            && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                            }
                            multiline
                            minRows={3}
                            maxRows={3}
                            placeholder={translate({ id: 'A4E_ADVANCED_SETTINGS_SYSTEM_PROMPT_HINTS' })}
                            variant="outlined"
                            fullWidth
                            data-cy="advanced-settings-system-prompt-text-field"
                            inputRef={systemPromptRef}
                        />
                    )}
                </UiStack>
                <UiStack direction='column'>
                    {createSettingItem(
                        'suggestedPromptsEnabled',
                        'A4E_ADVANCED_SETTINGS_SUGGESTED_PROMPT',
                        'A4E_ADVANCED_SETTINGS_SUGGESTED_PROMPT_TOOLTIP',
                        'suggested-prompt-boolean'
                    )}
                </UiStack>
                <UiStack direction='column'>
                    {createSettingItem(
                        'administratorSuggestedFollowUpsPromptNotesEnabled',
                        'A4E_ADVANCED_SETTINGS_SUGGESTED_PROMPT_HINTS',
                        'A4E_ADVANCED_SETTINGS_SUGGESTED_PROMPT_HINTS_TOOLTIP',
                        'suggested-prompt',
                        !isSuggestedPromptsEnabled
                    )}
                    {isSuggestedPromptHintsEnabled && (
                        <TextField
                            inputProps={register('administratorSuggestedFollowUpsPromptNotes', {
                                minLength: 1,
                                validate: {
                                    required: p =>
                                        (isSuggestedPromptHintsEnabled && isSuggestedPromptsEnabled)
                                            ? !!p?.trim()
                                            : true,
                                },
                            })}
                            required={isSuggestedPromptHintsEnabled}
                            error={!!errors.administratorSuggestedFollowUpsPromptNotes}
                            helperText={
                                errors.administratorSuggestedFollowUpsPromptNotes?.type === 'required'
                                            && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                            }
                            multiline
                            minRows={3}
                            maxRows={3}
                            placeholder={translate({ id: 'A4E_ADVANCED_SETTINGS_SUGGESTED_PROMPT_HINTS' })}
                            variant="outlined"
                            fullWidth
                            data-cy="advanced-settings-suggested-prompt-text-field"
                            inputRef={suggestedPromptRef}
                        />
                    )}
                </UiStack>
                <UiStack direction='column'>
                    {createSettingItem(
                        'userNotesEnabled',
                        'A4E_ADVANCED_SETTINGS_USER_NOTES',
                        'A4E_ADVANCED_SETTINGS_USER_NOTES_TOOLTIP',
                        'user-notes'
                    )}
                </UiStack>
                <UiStack direction='column'>
                    {createSettingItem(
                        'chatHistoryEnabled',
                        'A4E_ADVANCED_SETTINGS_CHAT_HISTORY_VISIBILITY',
                        'A4E_ADVANCED_SETTINGS_CHAT_HISTORY_TOOLTIP',
                        'chat-history'
                    )}
                    {isChatHistoryEnabled && (
                        <TextField
                            inputProps={register('chatHistoryVisibilityDays', {
                                validate: {
                                    isValidValue: p => isChatHistoryEnabled ?
                                        !!(p && Number(p) >= 0 && !isNaN(Number(p))) : true,
                                },
                            })}
                            error={!!errors.chatHistoryVisibilityDays}
                            helperText={
                                errors.chatHistoryVisibilityDays?.type === 'isValidValue'
                                && translate({ id: 'CLIENT_INVALID_VALUE_FIELD_ERROR' })
                            }
                            variant="outlined"
                            placeholder='0'
                            label={translate({ id: 'A4E_ADVANCED_SETTINGS_CHAT_HISTORY_VISIBILITY_DAYS' })}
                            data-cy="advanced-settings-chat-history-visibility-days-text-field"
                            inputRef={chatHistoryRef}
                        />
                    )}
                </UiStack>
                <UiStack direction='column'>
                    {createSettingItem(
                        'autoSearchECSEnabled',
                        'A4E_ADVANCED_SETTINGS_AUTO_ECS_ENABLED',
                        'A4E_ADVANCED_SETTINGS_AUTO_ECS_ENABLED_TOOLTIP',
                        'autoecs'
                    )}
                    {isAutoSearchECSEnabled && (
                        <ApDropdown
                            {...register('autoSearchECSIndex', { required: translate({ id: 'CLIENT_INDEX_NAME_REQUIRED' }) })}
                            style={{ 'width': '100%' }}
                            label={translate({ id: 'A4E_ADVANCED_SETTINGS_AUTO_ECS_INDEX' })}
                            aria-label={translate({ id: 'A4E_ADVANCED_SETTINGS_AUTO_ECS_INDEX' })}
                            data-cy="autoSearchECSIndex-select-dropdown"
                            placeholder={isLoadingTenantSettings ?
                                translate({ id: 'CLIENT_A4E_INDEX_NAME_LOADING_PLACEHOLDER' }) :
                                translate({ id: 'CLIENT_A4E_INDEX_NAME_PLACEHOLDER' })}
                            selectedValue={watch('autoSearchECSIndex')}
                            onSelectedValueChanged={onECSIndexSelectDropdown}
                            errorMessage={dropdownErrorMessage()}
                            disabled={isLoadingTenantSettings || contextGroundingIndexes?.length === 0}>
                            {contextGroundingIndexes?.map(ecsIndex => (
                                <ApDropdownItem
                                    value={ecsIndex.id}
                                    label={ecsIndex.indexName}
                                    key={`ap-dropdown-item-${ecsIndex.id}`}
                                />
                            ))}
                        </ApDropdown>
                    )}
                </UiStack>
                <UiStack direction='column'>
                    {createSettingItem(
                        'ecsNumberOfSearchResultsEnabled',
                        'A4E_ADVANCED_SETTINGS_SEARCH_RESULTS_NUMBER',
                        'A4E_ADVANCED_SETTINGS_SEARCH_RESULTS_NUMBER_TOOLTIP',
                        'ecs-num-search-results'
                    )}
                    {isECSSearchResultEnabled && (
                        <TextField
                            inputProps={register('ecsNumberOfSearchResults', {
                                validate:
                                {
                                    isValidValue: p => isECSSearchResultEnabled ?
                                        !!(p && Number(p) > 0 && Number(p) <= 15 && Number.isInteger(Number(p))) :
                                        true,
                                },
                            })}
                            error={!!errors.ecsNumberOfSearchResults}
                            helperText={
                                errors.ecsNumberOfSearchResults?.type === 'isValidValue'
                                && translate({ id: 'CLIENT_INVALID_VALUE_FIELD_ERROR_ECS' })
                            }
                            variant="outlined"
                            placeholder='5'
                            label={translate({ id: 'A4E_ADVANCED_SETTINGS_SEARCH_RESULTS_NUMBER' })}
                            data-cy="advanced-settings-chat-ecs-number-search-results-text-field"
                            inputRef={ecsResultNumRef}
                        />
                    )}
                </UiStack>
                <UiStack direction='column'>
                    {createSettingItem(
                        'sendFeedbackToUiPathEnabled',
                        'A4E_ADVANCED_SETTINGS_SEND_FEEDBACK',
                        'A4E_ADVANCED_SETTINGS_SEND_FEEDBACK_TOOLTIP',
                        'send-feedback'
                    )}
                </UiStack>
                <UiStack direction='column'>
                    <UiStack
                        direction='row'
                        align='center'
                        gap={SpacingToken.XS}>
                        <UiText
                            role='heading'
                            aria-level={2}
                        >
                            {translate({ id: 'A4E_ADVANCED_SETTINGS_USER_ACKNOWLEDGEMENT' })}
                        </UiText>
                        <ApTooltip content={translate({ id: 'A4E_ADVANCED_SETTINGS_USER_ACKNOWLEDGEMENT_TOOLTIP' })}>
                            <InfoOutlinedIcon
                                className={classes.infoIcon}
                                tabIndex={0}
                                aria-hidden="false"
                                aria-label={translate({ id: 'A4E_ADVANCED_SETTINGS_USER_ACKNOWLEDGEMENT_TOOLTIP' })}
                            />
                        </ApTooltip>
                    </UiStack>
                    <UiStack
                        direction='row'
                        align='center'>
                        <Controller
                            name='launchAcknowledgementConfigEnabled'
                            control={control}
                            render={({ field }) => (
                                <RadioGroup
                                    className={classes.rowGroup}
                                    value={field.value}
                                    onChange={e => {
                                        field.onChange(e.target.value);
                                        if (e.target.value === 'true' &&
                                            (watch('launchAcknowledgementConfig.headerText')?.trim().length === 0
                                            || watch('launchAcknowledgementConfig.messageText')?.trim().length === 0
                                            )) {
                                            setUserAcknowledementDrawerIsOpen(true);
                                            setUserAcknowledgementDrawerDefaultValues(getValues('launchAcknowledgementConfig') ?? null);
                                        }
                                    }}
                                >
                                    <FormControlLabel
                                        value='true'
                                        control={<Radio color="primary" />}
                                        label={translate({ id: 'CLIENT_ENABLE' })}
                                        data-cy='launch-ack-enable-radio'
                                    />
                                    <FormControlLabel
                                        value='false'
                                        control={<Radio color="primary" />}
                                        label={translate({ id: 'CLIENT_DISABLE' })}
                                        data-cy='launch-ack-disable-radio'
                                    />
                                </RadioGroup>
                            )}
                        />
                        <ApButton
                            variant="tertiary"
                            disabled={watch('launchAcknowledgementConfigEnabled') === 'false'}
                            data-cy="launch-ack-customize-button"
                            onClick={() => {
                                setUserAcknowledementDrawerIsOpen(true);
                                setUserAcknowledgementDrawerDefaultValues(getValues('launchAcknowledgementConfig') ?? null);
                            }}
                            label={translate({ id: 'A4E_ADVANCED_SETTINGS_CUSTOMIZE_APPEARANCE' })}
                        />
                    </UiStack>
                </UiStack>
                <UiStack direction='column'>
                    <UiStack
                        direction='row'
                        align='center'
                        gap={SpacingToken.XS}>
                        <UiText
                            role='heading'
                            aria-level={2}
                        >
                            {translate({ id: 'A4E_ADVANCED_SETTINGS_CO_BRANDING_THEME' })}
                        </UiText>
                        <ApTooltip content={translate({ id: 'A4E_ADVANCED_SETTINGS_CO_BRANDING_THEME_TOOLTIP' })}>
                            <InfoOutlinedIcon
                                className={classes.infoIcon}
                                tabIndex={0}
                                aria-hidden="false"
                                aria-label={translate({ id: 'A4E_ADVANCED_SETTINGS_CO_BRANDING_THEME_TOOLTIP' })}
                            />
                        </ApTooltip>
                    </UiStack>
                    <UiStack
                        direction='row'
                        align='center'>
                        <Controller
                            name='tenantUIConfigEnabled'
                            control={control}
                            render={({ field }) => (
                                <RadioGroup
                                    className={classes.rowGroup}
                                    value={field.value}
                                    onChange={e => {
                                        field.onChange(e.target.value);
                                        if (e.target.value === 'true' && (watch('tenantUIConfig.appBar.lightModeBackgroundColor')?.trim().length === 0
                                     || watch('tenantUIConfig.appBar.darkModeBackgroundColor')?.trim().length === 0
                                            || watch('tenantUIConfig.appBar.darkModeTextColor')?.trim().length === 0
                                            || watch('tenantUIConfig.appBar.lightModeTextColor')?.trim().length === 0)) {
                                            setCoBrandingThemeDrawerIsOpen(true);
                                            setCoBrandingThemeDrawerDefaultValues(getValues('tenantUIConfig') ?? null);
                                        }
                                    }}
                                >
                                    <FormControlLabel
                                        value='true'
                                        control={<Radio color="primary" />}
                                        label={translate({ id: 'CLIENT_ENABLE' })}
                                        data-cy='tenant-ui-enable-radio'
                                    />
                                    <FormControlLabel
                                        value='false'
                                        control={<Radio color="primary" />}
                                        label={translate({ id: 'CLIENT_DISABLE' })}
                                        data-cy='tenant-ui-disable-radio'
                                    />
                                </RadioGroup>
                            )}
                        />
                        <ApButton
                            variant="tertiary"
                            disabled={watch('tenantUIConfigEnabled') === 'false'}
                            data-cy="tenant-ui-customize-button"
                            onClick={() => {
                                setCoBrandingThemeDrawerIsOpen(true);
                                setCoBrandingThemeDrawerDefaultValues(getValues('tenantUIConfig') ?? null);
                            }}
                            label={translate({ id: 'A4E_ADVANCED_SETTINGS_CUSTOMIZE_APPEARANCE' })}
                        />
                    </UiStack>
                </UiStack>
            </UiStack>
            <UiStack
                direction='row'
                justify='end'
                gap={SpacingToken.XS}
            >
                <ApButton
                    onClick={() => reset(defaultValuesRef.current as IA4EAdvancedSettingsConfiguration)}
                    label={translate({ id: 'A4E_CUSTOM_TAB_RESET' })}
                    variant='tertiary'
                    disabled={isLoadingTenantSettings || isSavingTenantSettings}
                    data-cy="advanced-settings-reset-button"
                />
                <ApButton
                    onClick={handleSubmit(onSubmit)}
                    label={translate({ id: 'A4E_CUSTOM_TAB_SAVE' })}
                    variant='primary'
                    data-cy="advanced-settings-save-button"
                    disabled={!isValid || !formIsDirty || !isLaunchAcknowledgementConfigValid || !isTenantUIConfigValid
                        || !isContextGroundingIndexValid || isLoadingTenantSettings || isSavingTenantSettings}
                />
            </UiStack>
            <A4EAdvancedSettingUserAcknowledgementDrawerComponent
                isOpen={userAcknowledementDrawerIsOpen}
                setIsOpen={setUserAcknowledementDrawerIsOpen}
                setValue={setValue}
                defaultValues={userAcknowledgementDrawerDefaultValues ?? undefined}
            />
            <A4EAdvancedSettingCoBrandingThemeDrawerComponent
                isOpen={coBrandingThemeDrawerIsOpen}
                setIsOpen={setCoBrandingThemeDrawerIsOpen}
                setValue={setValue}
                defaultValues={coBrandingThemeDrawerDefaultValues ?? undefined}
            />
        </UiAccordion>
    );
};

export default A4EAdvancedSettingsComponent;
