import type { AxiosError } from 'axios';

import {
    get,
    post,
} from '../utility/Requests.default';
import type {
    AutomationSolutionsError,
    SolutionPackagePublishStatusRequest,
    SolutionPublishStatusResponse,
    SolutionsDeloyPackageRequest,
    SolutionsDeloyPackageResponse,
    SolutionsDeploymentStatusResponse,
    SolutionsGetDeploymentsRequest,
    SolutionsGetDeploymentsResponse,
    SolutionsGetDeploymentStatusRequest,
    SolutionsGetPackageRequest,
    SolutionsGetPipelineDeploymentStatusRequest,
    SolutionsPackageResponse,
    SolutionsPipelineDeploymentStatusResponse,
    SolutionsUninstallDeploymentRequest,
    SolutionsUninstallDeploymentResponse,
} from './A4EServiceTypes';
import { A4EError } from './A4EServiceTypes';

export async function uploadPackage(tenantName: string, data: Blob): Promise<SolutionsPackageResponse> {
    try {
        return await post<SolutionsPackageResponse>(`/${tenantName}/automationsolutions_/v1/pipelines/packages`, {
            headers: {
                extendRequestHeaders: 'true',
                maxContentLength: 'Infinity',
                maxBodyLength: 'Infinity',
                'Content-Type': 'application/zip',
            },
            stripPortalPath: true,
            isFormData: true,
            body: data,
        });
    } catch (error) {
        const axiosError = error as AxiosError;
        throw a4EErrorFromAutomationSolutionsError(axiosError);
    }
}

export async function getPackagePublishStatus(request: SolutionPackagePublishStatusRequest): Promise<SolutionPublishStatusResponse> {
    try {
        const url = `/${request.tenantName}/automationsolutions_/v1/pipelines/packages/${request.packageName}/publish-status/${request.packageVersion}`;
        return await get<SolutionPublishStatusResponse>(url, { stripPortalPath: true });
    } catch (error) {
        const axiosError = error as AxiosError;
        throw a4EErrorFromAutomationSolutionsError(axiosError);
    }
}

export async function getPackage(request: SolutionsGetPackageRequest): Promise<SolutionsPackageResponse> {
    try {
        return await get<SolutionsPackageResponse>(
            `/${request.tenantName}/automationsolutions_/v1/pipelines/packages/${request.packageName}?packageVersion=${request.packageVersion}`,
            { stripPortalPath: true }
        );
    } catch (error) {
        const axiosError = error as AxiosError;
        throw a4EErrorFromAutomationSolutionsError(axiosError);
    }
}

export async function getDeployments(request: SolutionsGetDeploymentsRequest): Promise<SolutionsGetDeploymentsResponse> {
    try {
        return await post<SolutionsGetDeploymentsResponse>(
            `/${request.tenantName}/automationsolutions_/api/v2/search/deployments`,
            {
                stripPortalPath: true,
                body: request,
            }
        );
    } catch (error) {
        const axiosError = error as AxiosError;
        throw a4EErrorFromAutomationSolutionsError(axiosError);
    }
}

export async function deploySolutionPackage(request: SolutionsDeloyPackageRequest): Promise<SolutionsDeloyPackageResponse> {
    try {
        const url = `/${request.tenantName}/automationsolutions_/v1/pipelines` +
        `/deployments/${request.deploymentName}/deploy-from-package/${request.packageName}/${request.packageVersion}/to-folder/${request.solutionFolderName}`;
        return await post<SolutionsDeloyPackageResponse>(
            url,
            { stripPortalPath: true }
        );
    } catch (error) {
        const axiosError = error as AxiosError;
        throw a4EErrorFromAutomationSolutionsError(axiosError);
    }
}

export async function getPipelineDeploymentStatus(request: SolutionsGetPipelineDeploymentStatusRequest): Promise<SolutionsPipelineDeploymentStatusResponse> {

    try {
        return await get<SolutionsPipelineDeploymentStatusResponse>(
            `/${request.tenantName}/automationsolutions_/v1/pipelines/deployments/${request.pipelineDeploymentId}/deployment-status`,
            { stripPortalPath: true }
        );
    } catch (error) {
        const axiosError = error as AxiosError;
        throw a4EErrorFromAutomationSolutionsError(axiosError);
    }
}
export async function getDeploymentStatus(request: SolutionsGetDeploymentStatusRequest): Promise<SolutionsDeploymentStatusResponse> {
    try {
        return await get<SolutionsDeploymentStatusResponse>(
            `/${request.tenantName}/automationsolutions_/v1/pipelines/deployments/${request.instanceId}/status`,
            { stripPortalPath: true }
        );
    } catch (error) {
        const axiosError = error as AxiosError;
        throw a4EErrorFromAutomationSolutionsError(axiosError);
    }
}

export async function uninstallDeployment(request: SolutionsUninstallDeploymentRequest): Promise<SolutionsUninstallDeploymentResponse> {
    try {
        return await post<SolutionsUninstallDeploymentResponse>(
            `/${request.tenantName}/automationsolutions_/v1/pipelines/deployments/${request.deploymentName}/uninstall`,
            { stripPortalPath: true }
        );
    } catch (error) {
        const axiosError = error as AxiosError;
        throw a4EErrorFromAutomationSolutionsError(axiosError);
    }
}

function a4EErrorFromAutomationSolutionsError(axiosError: AxiosError): A4EError {
    const statusCode = axiosError.response?.status ?? 500;
    const responseData: any | undefined = axiosError.response?.data;
    if (responseData) {
        if (statusCode === 400) {
            const data = responseData as AutomationSolutionsError;
            // Join different error messages into one string
            const errorMessages = Object.keys(data.errors)
                .flatMap((key) => data.errors[key].map((error) => error.message));
            return new A4EError(statusCode, errorMessages, data?.errorCodes ?? []);
        }

        const errorMessage: string = responseData?.message ?? axiosError.message;
        const errorCodes = responseData?.errorCode ? [ responseData.errorCode ] : [];
        return new A4EError(statusCode, [ errorMessage ], errorCodes);
    }
    return new A4EError(statusCode, [ axiosError.message ], []);
}
