import type { AxiosError } from 'axios';
import { v4 as uuid } from 'uuid';

import type {
    IA4EStartingPromptData,
    Prompt,
} from '../../../../common/interfaces/a4e';
import type { ODataQueryOptions } from '../../../../services/notifications';
import type {
    BucketDto,
    SimpleFolderDto,
} from '../../../../services/orchestrator/OrchBucketService';
import {
    createBucket,
    deleteBucket,
    getBucketsAcrossFolders,
    getBucketsForFolder,
    getFileFromBucket,
    getFilesFromBucket,
    getFoldersForBucket,
    getOdataBucketReadUri,
    getOdataBucketWriteUri,
    uploadFileToBucket,
} from '../../../../services/orchestrator/OrchBucketService';
import { getAllFoldersForCurrentUser } from '../../../../services/orchestrator/OrchFolderService';
import { A4EErrorMessage } from './A4EErrorType';

export const STARTING_PROMPTS_FILE_NAME_CSV = 'starting_prompts.csv';
export const STARTING_PROMPTS_FILE_NAME_JSON = 'starting_prompts.json';
const AUTOPILOT_DOCUMENT_CONFIG = 'Autopilot Document Config';

const DEFAULT_PROMPT_CONFIG = [
    {
        department: 'General',
        categories: [
            {
                name: 'Create',
                prompts: [
                    {
                        title: 'Create meeting summary and action items from call transcript',
                        prompt: 'Summarize the meeting notes and provide key action items',
                        requiresFileUpload: true,
                    },
                    {
                        title: 'Help me write an agenda for my meeting',
                        prompt: 'Write a draft agenda for an upcoming meeting. The topic is <<< insert short description of topic >>>',
                    },
                    {
                        title: 'Help me write an email to a customer',
                        prompt: 'Help me write an email to a customer. <<< Customer Role >>> <<< Brief overview of email >>>',
                    },
                ],
            },
            {
                name: 'Optimize',
                prompts: [
                    {
                        title: 'Generate 5 innovative ideas for improving remote team collaboration',
                        prompt: 'Generate 5 innovative ideas for improving remote team collaboration',
                    },
                    {
                        title: 'Identify tasks in my routine that can be automated',
                        prompt: 'Identify tasks in my routine that can be automated',
                    },
                    {
                        title: 'Suggest ways to streamline my team meeting',
                        prompt: 'Suggest ways to streamline my weekly team meeting. The topic is <<< describe topic >>>',
                    },
                ],
            },
            {
                name: 'Schedule',
                prompts: [
                    {
                        title: 'Schedule a meeting with a colleague',
                        prompt: 'Schedule a meeting at <<< time / day >>> with <<< team member email >>>',
                    },
                    {
                        title: 'What\'s the best time to schedule a meeting with colleagues across different timezones',
                        prompt: 'What\'s the best time to schedule an hour meeting with colleagues in <<< location >>> and <<< location >>>',
                    },
                    {
                        title: 'What\'s on my calendar today?',
                        prompt: 'What\'s on my calendar today?',
                    },
                ],
            },
            {
                name: 'Analyze',
                prompts: [
                    {
                        title: 'Upload a PDF or image and ask a question',
                        prompt: '<<< Ask a question >>>',
                        requiresFileUpload: true,
                    },
                    {
                        title: 'Help me plan a trip',
                        prompt: 'Help me plan a trip to <<< location >>>',
                    },
                    {
                        title: 'Compile information on competitor\'s products or services',
                        // eslint-disable-next-line max-len
                        prompt: 'Compile a detailed comparison of UiPath <<< Product Name >>> vs <<< Competitor Name >>> products or services, focusing on features and market positioning',
                    },
                ],
            },
        ],
    },
];

const odataGetAutopilotDocBucket: ODataQueryOptions = { filter: 'Name eq \'Autopilot Document Config\'' };

export const getAllFolders = async (urlParams: { accountLogicalName: string; tenantName: string }) => {
    try {
        const { PageItems: folders } = await getAllFoldersForCurrentUser(urlParams);
        return folders;
    } catch (e) {
        throw new A4EErrorMessage('A4E_FOLDER_NOT_FOUND', (e as AxiosError).code);
    }
};

export const getConfiguredFolders = async (urlParams: { accountLogicalName: string; tenantName: string }) => {
    try {
        // get all Autopilot Document Config buckets across all folders
        const { value: autopilotDocumentConfigBuckets } = await getBucketsAcrossFolders(urlParams, odataGetAutopilotDocBucket);
        const configuredFolders: SimpleFolderDto[] = [];
        for (const bucket of autopilotDocumentConfigBuckets) {
            // get all folders for each bucket
            const accessibleFoldersDto = await getFoldersForBucket({
                ...urlParams,
                bucketId: `${bucket.Id}`,
            });
            for (const folder of accessibleFoldersDto.AccessibleFolders) {
                // check if starting_prompts.json exists in the folder
                const folderId = folder.Id;
                const { value: fileDtos } = await getFilesFromBucket({
                    ...urlParams,
                    folderId: `${folderId}`,
                    bucketId: `${bucket.Id}`,
                });
                const startingPromptsFileExists = !!fileDtos.find((file) => file.FullPath === STARTING_PROMPTS_FILE_NAME_JSON);
                if (startingPromptsFileExists) {
                    configuredFolders.push(folder);
                }
            }
        }
        return configuredFolders;
    } catch (e) {
        throw new A4EErrorMessage('A4E_CONFIGURED_FOLDER_NOT_FOUND', (e as AxiosError).code);
    }
};

export const getStartingPromptsForFolder = async (urlParams: { accountLogicalName: string; tenantName: string; folderId: string }) => {
    const { value: autopilotDocumentConfigBuckets } = await getBucketsForFolder(urlParams, odataGetAutopilotDocBucket);
    if (autopilotDocumentConfigBuckets.length === 0) {
        return [];
    }

    let startingPrompts: Prompt[] = [];
    for (const bucket of autopilotDocumentConfigBuckets) {
        try {
            const readUri = await getOdataBucketReadUri({
                ...urlParams,
                bucketId: `${bucket.Id}`,
                fileName: STARTING_PROMPTS_FILE_NAME_JSON,
            });

            startingPrompts = await getFileFromBucket({
                readUri,
                folderId: urlParams.folderId,
            }) as Prompt[];
        } catch (e) {
            throw new A4EErrorMessage('A4E_STARTING_PROMPT_NOT_FOUND', (e as AxiosError).code);
        }

        if (!startingPrompts || typeof startingPrompts !== 'object') {
            throw new A4EErrorMessage('A4E_STARTING_PROMPT_NOT_VALID');
        }

        const updatedStartingPrompts: IA4EStartingPromptData[] = startingPrompts.map((promptObj) => ({
            id: uuid(),
            isFeatured: false,
            requiresFileUpload: false,
            ...promptObj,
            prompt: promptObj.prompt.replace(/&lt;/g, '<').replace(/&gt;/g, '>'), // replace unsafe ASCII characters to <>
        }));

        return updatedStartingPrompts;
    }
};

export const getDefaultPrompts = (): IA4EStartingPromptData[] => DEFAULT_PROMPT_CONFIG.flatMap((departmentConfig) =>
    departmentConfig.categories.flatMap((category) =>
        category.prompts.map((prompt) => ({
            id: uuid(),
            isFeatured: false,
            department: departmentConfig.department,
            category: category.name,
            ...prompt,
            title: prompt.title,
            prompt: prompt.prompt,
            requiresFileUpload: prompt.requiresFileUpload ?? false,
        }))
    )
);

export const saveStartingPromptsForFolder = async (
    urlParams: { accountLogicalName: string; tenantName: string; folderId: string },
    startingPrompts: IA4EStartingPromptData[]
) => {
    try {
        const { value } = await getBucketsForFolder(urlParams, odataGetAutopilotDocBucket);
        let autopilotDocumentConfigBuckets = value;

        // create bucket if it doesn't exist
        if (autopilotDocumentConfigBuckets.length === 0) {
            const newBucketDto: BucketDto = {
                Name: AUTOPILOT_DOCUMENT_CONFIG,
                Identifier: uuid(),
            };
            await createBucket({ ...urlParams }, newBucketDto);

            const { value: newConfigBucket } = await getBucketsForFolder(urlParams, odataGetAutopilotDocBucket);

            autopilotDocumentConfigBuckets = newConfigBucket;
        }

        // if no other files exist in the bucket and the starting prompt is empty, delete the bucket
        const bucketId = `${autopilotDocumentConfigBuckets[0].Id}`;
        const { value: fileDtos } = await getFilesFromBucket({
            ...urlParams,
            bucketId,
        });
        const startingPromptsFileExists = !!fileDtos.find((file) => file.FullPath === STARTING_PROMPTS_FILE_NAME_JSON);
        if (startingPromptsFileExists && fileDtos.length === 1 && startingPrompts.length === 0) {
            await deleteBucket({
                ...urlParams,
                bucketId,
            });
            return;
        }
        // upload to orchestrator
        const writeUri = await getOdataBucketWriteUri({
            ...urlParams,
            bucketId,
            fileName: STARTING_PROMPTS_FILE_NAME_JSON,
        });
        await uploadFileToBucket({
            writeUri,
            folderId: urlParams.folderId,
            file: startingPrompts,
        });

        return;
    } catch (e) {
        throw new A4EErrorMessage('A4E_STARTING_PROMPT_SAVE_FAIL', (e as AxiosError).code);
    }
};

export const convertToCSV = (data: IA4EStartingPromptData[], fields: string[]) => {
    if (data.length === 0) {
        return [ fields.join(',') ].join('\n');
    }

    const csvRows = [];
    // Add headers row
    csvRows.push(fields.join(','));

    // Function to escape values
    const escapeValue = (value: any) => {
        if (typeof value === 'string') {
            // Escape double quotes by doubling them
            value = value.replace(/"/g, '""');
            // Wrap value in double quotes if it contains a comma or double quotes
            if (value.includes(',') || value.includes('"')) {
                value = `"${value}"`;
            }
        }
        return value;
    };

    // Map data to CSV rows
    data.forEach(row => {
        const values = fields.map(field => escapeValue(row[field]));
        csvRows.push(values.join(','));
    });

    // Join rows with newline character
    return csvRows.join('\n');
};

export const parseFileForStartingPrompts = async (file: File, requiredFields: string[], optionalFields: string[]) => new Promise<IA4EStartingPromptData[] | undefined>(resolve => {
    const reader = new FileReader();

    reader.onload = event => {
        const content = event.target?.result as string;
        const allLines = content?.split(/\r\n|\n/).filter(line => !!line.trim());
        if (allLines.length === 0) {
            resolve(undefined);
            return;
        }

        const headerLine = allLines[0];
        const headers = headerLine.split(',').map(header => header.trim());
        const headerSet = new Set(headers);
        // Check if required fields are present in headers
        const areHeadersValid = requiredFields.every(field => headerSet.has(field));
        if (!areHeadersValid) {
            resolve(undefined);
            return;
        }

        // Create a map to keep track of the order of fields
        const fieldIndexMap = new Map<string, number>();
        headers.forEach((header, index) => {
            fieldIndexMap.set(header, index);
        });

        const startingPrompts: IA4EStartingPromptData[] = [];
        allLines.slice(1).forEach((line: string) => {
            const values = parseCSVLine(line);
            const startingPrompt = {} as IA4EStartingPromptData;
            const fields = [ ...requiredFields, ...optionalFields ];
            fields.forEach(field => {
                const index = fieldIndexMap.get(field);
                if (index === undefined) {
                    return ;
                }

                // Convert specific fields to booleans
                if (field === 'isFeatured' || field === 'requiresFileUpload') {
                    const value = values[index].toLowerCase() === 'true';
                    startingPrompt[field] = value;
                } else {
                    startingPrompt[field] = values[index];
                }
            });
            startingPrompts.push(startingPrompt);
        });
        resolve(startingPrompts);
    };

    reader.onerror = () => {
        reader.abort();
        resolve(undefined);
    };

    reader.readAsText(file);
});

// Helper function to parse a CSV line considering quoted values
const parseCSVLine = (line: string): string[] => {
    const regex = /(?:,|\n|^)(?:"([^"]*(?:""[^"]*)*)"|([^",\n]*))/g;
    const result: string[] = [];
    let match: RegExpExecArray | null;

    while ((match = regex.exec(line))) {
        if (match[1] !== undefined) {
            result.push(match[1].replace(/""/g, '"'));
        } else {
            result.push(match[2]);
        }
    }

    return result;
};
