import { useLocalization } from '@experiences/locales';
import {
    SpacingToken,
    UiSelect,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import {
    formatDate,
    getDateFromNow,
} from '@experiences/util';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
    MigrationEndWindowDays,
    MigrationStartWindowDays,
} from '../../../common/interfaces/gws';
import { generateTimeWindows } from '../../../util/MigrationUtil';
import type { ITenantMigrationFormData } from './types';

const useStyles = makeStyles((theme) =>
    ({
        timeSelect: { width: '320px' },
        box: {
            outline: 'solid',
            outlineWidth: '1px',
            outlineColor: theme.palette.semantic.colorBorderDeEmp,
            borderRadius: '3px',
            padding: '16px 32px',
        },
    }),
);

export const TenantMigrationStepSelectTime: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const language = useLocalization();
    const {
        control,
        watch,
        formState: { errors },
    } = useFormContext<ITenantMigrationFormData>();

    const hourKeys = useMemo(generateTimeWindows, []);

    const windowStartDate = getDateFromNow(MigrationStartWindowDays);
    const windowEndDate = getDateFromNow(MigrationEndWindowDays);

    const hour = watch('customHour') || watch('hour');

    return (
        <UiStack
            direction='column'
            gap={SpacingToken.L}>
            <UiStack gap={80}>
                <UiStack
                    direction='column'
                    gap={SpacingToken.Micro}>
                    <UiText variant={FontVariantToken.fontSizeLBold}>
                        {translate({ id: 'CLIENT_DOWNTIME_WINDOW' })}
                    </UiText>
                    <UiText>
                        {`${hour.split('-')[0] + ' UTC'} - ${hour.split('-')[1] + ' UTC'}`}
                    </UiText>
                </UiStack>
                <UiStack
                    direction='column'
                    gap={SpacingToken.Micro}>
                    <UiText variant={FontVariantToken.fontSizeLBold}>
                        {translate({ id: 'CLIENT_COMPATIBILITY_WINDOW' })}
                    </UiText>
                    <UiText>
                        {formatDate(windowStartDate.toISOString(), language)}
                        {' - '}
                        {formatDate(windowEndDate.toISOString(), language)}
                    </UiText>
                </UiStack>
            </UiStack>
            <UiStack
                direction='column'
                gap={SpacingToken.Micro}>
                <UiSelect
                    id="customHour"
                    name="customHour"
                    control={control}
                    isTranslated
                    inputLabel={translate({ id: 'CLIENT_CHOOSE_ANOTHER_WINDOW' })}
                    options={hourKeys}
                    error={!!errors.customHour}
                    fullWidth
                    className={classes.timeSelect}
                    dataCy="hour-select-field"
                />
                <UiText variant={FontVariantToken.fontSizeS}>
                    {translate({ id: 'CLIENT_RECOMMEND_TIME' })}
                </UiText>
            </UiStack>

            <div className={classes.box}>
                <UiText variant={FontVariantToken.fontSizeMBold}>
                    {translate({ id: 'CLIENT_WHAT_NEXT' })}
                </UiText>
                <ul>
                    {Array.from({ length: 5 }, (_, index) => (
                        <li key={index}>
                            <UiText>
                                {translate({ id: `CLIENT_SCHEDULE_TENANT_MIGRATION_INFO_${index + 1}` })}
                            </UiText>
                        </li>
                    ))}
                </ul>
            </div>
        </UiStack>
    );
};
