import {
    SpacingToken,
    UiProgressButton,
    UiStack,
} from '@experiences/ui-common';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { ApButton } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { notificationType } from '../../../../common/constants/Constant';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import type {
    AutomationArgument,
    IA4EAutomationPropertiesDrawerParams,
} from '../../../../common/interfaces/a4e';
import { UiDrawer } from '../../../common/UiDrawer';
import UiForm from '../../../common/UiForm';

interface A4EAutomationPropertiesEditComponentProps {
    automationPropertiesDrawerParams: IA4EAutomationPropertiesDrawerParams;
    setAutomationPropertiesDrawerParams: React.Dispatch<React.SetStateAction<IA4EAutomationPropertiesDrawerParams>>;
    setArgumentData: React.Dispatch<React.SetStateAction<AutomationArgument[]>>;
}

const defaultAutomationArgumentData: AutomationArgument = {
    id: '',
    name: '',
    displayName: '',
    hidden: false,
    description: '',
    promptDescription: '',
    options: '',
};

const A4EAutomationPropertiesEditComponent: React.FC<A4EAutomationPropertiesEditComponentProps> = ({
    automationPropertiesDrawerParams, setAutomationPropertiesDrawerParams, setArgumentData,
}) => {
    const {
        open, editingAutomationProperties,
    } = automationPropertiesDrawerParams;
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();

    const methods = useForm<AutomationArgument>({
        mode: 'all',
        defaultValues: defaultAutomationArgumentData,
    });
    const {
        control, register, handleSubmit, reset, formState: {
            errors, isDirty, isValid,
        },
    } = methods;

    useEffect(() => {
        methods.reset(editingAutomationProperties ?? defaultAutomationArgumentData);
    }, [ editingAutomationProperties, methods ]);

    const handleClose = useCallback(() => {
        reset();
        setAutomationPropertiesDrawerParams({
            ...automationPropertiesDrawerParams,
            open: false,
        });
    }, [ reset, setAutomationPropertiesDrawerParams, automationPropertiesDrawerParams ]);

    const onSubmit = useCallback((rowData: AutomationArgument) => {
        setArgumentData(prevData => prevData.map(data => data.name === rowData.name ? rowData : data));
        createNotification(
            translate({ id: 'A4E_AUTOMATION_PROPERTIES_DRAWER_EDIT_SUCCESS' }),
            notificationType.SUCCESS
        );
        handleClose();
    },
    [ handleClose, setArgumentData, createNotification, translate ]);

    return (
        <UiDrawer
            title={translate({ id: 'A4E_AUTOMATION_PROPERTIES_UPDATE_DRAWER_TITLE' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => {
                    handleClose();
                },
            }}
            width="medium"
            themeProps={{ disableGutters: [ 'bottom', 'right' ] }}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <UiStack
                        direction='row'
                        justify='end'
                        gap={SpacingToken.XS}>
                        <ApButton
                            label={translate({ id: 'CLIENT_CANCEL' })}
                            onClick={() => handleClose()}
                            variant="tertiary"
                            type='reset'
                            data-cy="edit-automation-properties-cancel-button"
                        />
                        <UiProgressButton
                            disabled={!isValid || !isDirty || !!Object.keys(errors).length}
                            type="submit"
                            variant="contained"
                            loading={false}
                            data-cy="edit-automation-properties-save-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </UiStack>
                }
                isDrawer
                addScrollPadding
            >
                <FormProvider {...methods}>
                    <UiStack
                        direction="column"
                        align="start"
                        pl={SpacingToken.S}
                        pr={SpacingToken.S}
                        gap={SpacingToken.M}
                    >
                        <TextField
                            inputProps={register('name')}
                            disabled
                            error={!!errors.name}
                            helperText={errors.name?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            label={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_NAME' })}
                            variant="outlined"
                            fullWidth
                            data-cy="automation-properties-drawer-name-text-field"
                        />
                        <TextField
                            inputProps={register('displayName')}
                            error={!!errors.displayName}
                            helperText={errors.displayName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            label={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_DISPLAY_NAME' })}
                            variant="outlined"
                            fullWidth
                            data-cy="automation-properties-drawer-display-name-text-field"
                        />
                        <TextField
                            inputProps={register('description')}
                            multiline
                            minRows={3}
                            error={!!errors.description}
                            helperText={errors.description?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            label={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_DESC_USER' })}
                            variant="outlined"
                            fullWidth
                            data-cy="automation-properties-drawer-desc-text-field"
                        />
                        <TextField
                            inputProps={register('promptDescription')}
                            multiline
                            minRows={4}
                            error={!!errors.promptDescription}
                            helperText={errors.promptDescription?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            label={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_DESC_AUTOPILOT' })}
                            variant="outlined"
                            fullWidth
                            data-cy="automation-properties-drawer-desc-autopilot-text-field"
                        />
                        <TextField
                            inputProps={register('options')}
                            error={!!errors.promptDescription}
                            helperText={errors.promptDescription?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            label={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_DROPDOWN_OPTIONS' })}
                            variant="outlined"
                            fullWidth
                            data-cy="automation-properties-drawer-options-text-field"
                        />
                        <Controller
                            control={control}
                            name="hidden"
                            render={({ field }) => (
                                <FormControlLabel
                                    {...field}
                                    control={
                                        <Checkbox
                                            checked={field.value}
                                            data-cy="automation-properties-drawer-hidden-checkbox"
                                        />
                                    }
                                    label={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_HIDDEN' })}
                                />
                            )}
                        />
                    </UiStack>
                </FormProvider>
            </UiForm>
        </UiDrawer>
    );
};

export default A4EAutomationPropertiesEditComponent;
