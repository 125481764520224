import buildQuery from 'odata-query';

import type { ODataQueryOptions } from '../notifications';
import { get } from '../utility/Requests.default';

const ORCH_URL = '/api/orchestrator/folders';

enum FolderType {
    Standard = 'Standard',
    Personal = 'Personal',
    Virtual = 'Virtual',
    Solution = 'Solution',
}

export type Folder = {
    IsSelectable?: boolean;
    HasChildren?: boolean;
    Level?: number;
    Key: string;
    DisplayName: string;
    FullyQualifiedName: string;
    Description?: string;
    FolderType: FolderType;
    IsPersonal?: boolean;
    ProvisionType: 'Manual' | 'Automatic';
    PermissionModel: 'InheritFromTenant' | 'FineGrained';
    ParentId?: number;
    ParentKey?: string;
    FeedType: 'Undefined' | 'Processes' | 'Libraries' | 'PersonalWorkspace' | 'FolderHierarchy';
    Id: number;
};

type FolderDto = {
    '@odata.count': number;
    value: Folder[];
};

type CurrentUserFolderDto = {
    Count: number;
    PageItems: Folder[];
};

export async function getAllFolders(
    urlParams: { accountLogicalName: string; tenantName: string },
    queryOptions: ODataQueryOptions
) {
    const {
        accountLogicalName, tenantName,
    } = urlParams;
    return await get<FolderDto>(`${ORCH_URL}/getAllFolders/${accountLogicalName}/${tenantName}${buildQuery(queryOptions)}`);
}

export async function getAllFoldersForCurrentUser(urlParams: { accountLogicalName: string; tenantName: string }) {
    const {
        accountLogicalName, tenantName,
    } = urlParams;
    return await get<CurrentUserFolderDto>(`${ORCH_URL}/getAllFoldersForCurrentUser/${accountLogicalName}/${tenantName}`);
}
