import {
    SpacingToken,
    UiStack,
} from '@experiences/ui-common';
import {
    ColorBlueSecondary500,
    ColorGreen500,
    ColorOrange700,
} from '@uipath/apollo-core/lib/_generated/Colors';
import { Chart } from '@uipath/apollo-lab/react';
import type { IDropdownOption } from '@uipath/portal-shell-types/components/angular-elements';
import React, {
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import { formatScaleX } from '../../../common/UiBarChart/formatScaleX';
import {
    calculateTenantAndProductActions,
    getMonths,
    getTenantAndProductOptions,
    groupChartData,
} from './ProdDashboardHelper';
import { UsageService } from './UsageService';

interface ProdDashboardProps {
    token: string;
    accountId: string;
}

export const ProdDashboard: React.FC<ProdDashboardProps> = ({
    token, accountId,
}) => {
    const { formatMessage: translate } = useIntl();
    const [ chartData, setChartData ] = useState<number[][]>([]);
    const [ labels, setLabels ] = useState<string[]>([]);
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ productNames, setProductNames ] = useState<string[]>([]);
    const [ tenantNames ] = useState<string[]>([]);
    const [ selectedTenant, setSelectedTenant ] = useState<string>('all');
    const [ selectedProduct, setSelectedProduct ] = useState<string>('all');
    const [ tenantIdToNameMap, setTenantIdToNameMap ] = useState<Record<string, string>>({});
    const scaleX = formatScaleX();

    const [ tenantOptions, setTenantOptions ] = useState<IDropdownOption[]>([
        {
            label: 'All',
            value: 'all',
        },
    ]);
    const [ productOptions, setProductOptions ] = useState<IDropdownOption[]>([
        {
            label: 'All',
            value: 'all',
        },
    ]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const sixMonthsBeforeNowUTC = new Date();
                sixMonthsBeforeNowUTC.setUTCMonth(sixMonthsBeforeNowUTC.getUTCMonth() - 6);

                const data = await UsageService.fetchActionByProd(token, accountId, sixMonthsBeforeNowUTC);

                const {
                    tenantOptions, productOptions, tenantIdToNameMap,
                } = await getTenantAndProductOptions(data, selectedTenant, selectedProduct);
                setTenantIdToNameMap(tenantIdToNameMap);
                setTenantOptions(tenantOptions);
                setProductOptions(productOptions);

                const months = getMonths();
                const {
                    monthlyTenantActions, monthlyProductActions, filteredData,
                } = calculateTenantAndProductActions(data, months, selectedTenant, selectedProduct);

                groupChartData(
                    filteredData,
                    months,
                    selectedProduct !== 'all' ? monthlyTenantActions : monthlyProductActions,
                    setChartData,
                    setLabels,
                    setProductNames,
                    selectedProduct !== 'all' ? 'tenantId' : 'product',
                    selectedProduct !== 'all' ? [ 'No tenant', 'No tenant', 'No tenant' ] : [ 'No product', 'No product', 'No product' ]
                );
            } catch (error) {
                console.error('Error fetching usage data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [ token, accountId, selectedTenant, selectedProduct ]);

    return (
        <UiStack
            direction="column"
            mt={SpacingToken.L}
            gap={SpacingToken.XS}
            style={{
                border: '2px solid #ccc',
                borderRadius: '8px',
                padding: '16px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
        >
            <UiStack
                direction="row"
                gap={SpacingToken.M}>
                <div>
                    <label
                        htmlFor="model-select"
                        style={{ fontWeight: 'bold' }}>
                        Total LLM Actions per Product
                    </label>
                </div>
                <div>
                    <label
                        htmlFor="tenant-select"
                        style={{ fontWeight: 'bold' }}>
                        Tenant:
                    </label>
                    <select
                        value={selectedTenant}
                        onChange={e => setSelectedTenant(e.target.value)}
                    >
                        {tenantOptions.length > 1 ? tenantOptions.map(option => (
                            <option
                                key={String(option.value)}
                                value={String(option.value)}>
                                {option.label}
                            </option>
                        )) : <option value="all">
All
                        </option>}
                    </select>
                </div>
                <div>
                    <label
                        htmlFor="product-select"
                        style={{ fontWeight: 'bold' }}>
                        Product:
                    </label>
                    <select
                        value={selectedProduct}
                        onChange={e => setSelectedProduct(e.target.value)}
                    >
                        {productOptions.length > 1 ? productOptions.map(option => (
                            <option
                                key={String(option.value)}
                                value={String(option.value)}>
                                {option.label}
                            </option>
                        )) : <option value="all">
All
                        </option>}
                    </select>
                </div>
            </UiStack>

            {loading ? (
                <div>
Loading...
                </div>
            ) : (
                <Chart
                    alt={translate({ id: 'CLIENT_USAGE_CHART_ALT_TEXT' })}
                    data={selectedProduct !== 'all' ? [
                        {
                            data: chartData.map(monthData => monthData[0] || 0),
                            renderer: 'bar',
                            color: ColorBlueSecondary500,
                            title: tenantIdToNameMap[tenantNames[0]] || 'Tenant 1',
                        },
                        {
                            data: chartData.map(monthData => monthData[1] || 0),
                            renderer: 'bar',
                            color: ColorOrange700,
                            title: tenantIdToNameMap[tenantNames[1]] || 'Tenant 2',
                        },
                        {
                            data: chartData.map(monthData => monthData[2] || 0),
                            renderer: 'bar',
                            color: ColorGreen500,
                            title: tenantIdToNameMap[tenantNames[2]] || 'Tenant 3',
                        },
                    ] : [
                        {
                            data: chartData.map(monthData => monthData[0] || 0),
                            renderer: 'bar',
                            color: ColorBlueSecondary500,
                            title: productNames[0] || 'Product 1', // Dynamically set legend title
                        },
                        {
                            data: chartData.map(monthData => monthData[1] || 0),
                            renderer: 'bar',
                            color: ColorOrange700,
                            title: productNames[1] || 'Product 2',
                        },
                        {
                            data: chartData.map(monthData => monthData[2] || 0),
                            renderer: 'bar',
                            color: ColorGreen500,
                            title: productNames[2] || 'Product 3',
                        },
                    ]}
                    labels={labels}
                    scaleX={scaleX}
                    scaleY={{
                        type: 'linear',
                        display: true,
                        label: 'LLM Calls',
                    }}
                    tooltipSchemaBuilder={values =>
                        values.map((value, index) => ({
                            cells: [
                                {
                                    text: `${selectedProduct !== 'all' ? tenantNames[index] : productNames[index]}: ${value.value.y}`,
                                    role: 'primary',
                                    color: [ ColorBlueSecondary500, ColorOrange700, ColorGreen500 ][index % 3],
                                },
                            ],
                        }))}
                    legendProperties={{ hasLegend: true }}
                />
            )}
        </UiStack>
    );
};

