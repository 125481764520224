import type { AxiosError } from 'axios';

import type {
    AutomationArgument,
    IA4EAutomationPartialData,
} from '../../../../common/interfaces/a4e';
import { A4EError } from '../../../../services/a4e/A4EServiceTypes';
import type { ODataQueryOptions } from '../../../../services/notifications';
import { getAllFoldersForCurrentUser } from '../../../../services/orchestrator/OrchFolderService';
import type {
    Release,
    Tag,
} from '../../../../services/orchestrator/OrchReleaseService';
import {
    getReleaseById,
    getReleasesForCurrentFolder,
    updateRelease,
} from '../../../../services/orchestrator/OrchReleaseService';

const AUTOPILOT_TAG = 'Autopilot';
const AUTOPILOT_PROMPT_DESCRIPTION = 'AutopilotPromptDescription';
const KEY_ID = 'id';
const KEY_DISPLAY_NAME = 'displayName';
const KEY_NAME = 'name';

export const getAllFolders = async (urlParams: { accountLogicalName: string; tenantName: string }) => {
    const { PageItems: folders } = await getAllFoldersForCurrentUser(urlParams);
    return folders;
};

export const getAutomationsForCurrentFolder = async (
    getOnlyAutopilotEnabledAutomations: boolean,
    urlParams: { accountLogicalName: string; tenantName: string; folderId: string }): Promise<Release[]> => {
    const {
        accountLogicalName, tenantName, folderId,
    } = urlParams;

    try {
        const options: ODataQueryOptions = {
            select: [ 'Id', 'Name', 'Tags' ],
            filter: getOnlyAutopilotEnabledAutomations ? `Tags/any(tag: tag/DisplayName eq '${AUTOPILOT_TAG}')` : '',
        };
        const releasesResponse = await getReleasesForCurrentFolder({
            accountLogicalName,
            tenantName,
            folderId,
        }, options);
        return releasesResponse.value;

    } catch (e) {
        const axiosError = e as AxiosError;
        throw a4EErrorFromOrchestratorError(axiosError);
    }
};

export const getAutomationById = async (
    urlParams: { accountLogicalName: string; tenantName: string; folderId: string; processId: string }
): Promise<Release> => {
    const {
        accountLogicalName, tenantName, folderId, processId,
    } = urlParams;

    try {
        return await getReleaseById({
            accountLogicalName,
            tenantName,
            folderId,
            processId,
        });
    } catch (e) {
        const axiosError = e as AxiosError;
        throw a4EErrorFromOrchestratorError(axiosError);
    }
};

export const updateAutomation = async (urlParams: { accountLogicalName: string; tenantName: string; folderId: string; processId: string }, release: any) => {
    const {
        accountLogicalName, tenantName, folderId, processId,
    } = urlParams;
    try {
        return await updateRelease({
            accountLogicalName,
            tenantName,
            folderId,
            processId,
        }, release);
    } catch (e) {
        const axiosError = e as AxiosError;
        throw a4EErrorFromOrchestratorError(axiosError);
    }
};

export const a4EErrorFromOrchestratorError = (axiosError: AxiosError): A4EError => {
    const statusCode = axiosError.response?.status ?? 500;
    const responseData: any | undefined = axiosError.response?.data;
    if (responseData) {
        const errorMessage: string = responseData?.message ?? axiosError.message;
        const errorCodes = responseData?.errorCode ? [ String(responseData.errorCode) ] : [];
        return new A4EError(statusCode, [ errorMessage ], errorCodes);
    }
    return new A4EError(statusCode, [ axiosError.message ], []);
};

export const getArgumentsData = (automationInputArguments: string | undefined, tags: Tag[]): AutomationArgument[] => {
    if (!automationInputArguments) {
        return [];
    }
    const inputArguments = JSON.parse(automationInputArguments);
    const argumentData: AutomationArgument[] = [];
    for (const arg of inputArguments) {
        const row: AutomationArgument = {
            id: arg.name,
            name: arg.name,
            displayName: '',
            hidden: false,
            description: '',
            promptDescription: '',
            options: '',
        };
        // find tag for the corresponding input argument
        const tag = tags.find((t) => t.DisplayName === arg.name);
        if (tag) {
            const keyValuePairs = tag.DisplayValue.split('|');
            for (const pair of keyValuePairs) {
                const cleanedPair = pair.slice(1, pair.length - 1);
                const [ key, value ] = cleanedPair.split('=');
                if (key === KEY_NAME) {
                    row.displayName = value;
                } else if (key === 'hidden') {
                    row[key] = value === 'true';
                } else {
                    row[key] = value;
                }
            }
            row.name = tag.DisplayName;
        }
        argumentData.push(row);
    }
    return argumentData;
};

export const getUpdatedTags = (originalTags: Tag[], partialData: IA4EAutomationPartialData, tableData: AutomationArgument[]) => {
    const updatedTags: Tag[] = [];
    for (const [ key, value ] of Object.entries(partialData)) {
        const isAutopilotPromptDescAndValueIsEmpty =
            key === AUTOPILOT_PROMPT_DESCRIPTION &&
            typeof value === 'string' &&
            !value.trim();
        if (isAutopilotPromptDescAndValueIsEmpty) {
            continue;
        }
        const isAutopilotTag = key === AUTOPILOT_TAG;
        const tag: Tag = {
            Name: key.toLowerCase(),
            DisplayName: key,
            Value: isAutopilotTag ? '' :
                `${value}`.toLowerCase(),
            DisplayValue: isAutopilotTag ? '' : `${value}`,
        };
        updatedTags.push(tag);
    }

    for (const arg of tableData) {
        const {
            displayValue, value,
        } = generateTagValueStrings(arg);
        const tag: Tag = {
            Name: arg.name.toLowerCase(),
            DisplayName: arg.name,
            Value: value,
            DisplayValue: displayValue,
        };
        updatedTags.push(tag);
    }

    for (const tag of originalTags) {
        if (!updatedTags.some((t) => t.Name === tag.Name)) {
            updatedTags.push(tag);
        }
    }

    // remove autopilot tag if it's not enabled
    if (!partialData[AUTOPILOT_TAG]) {
        const autopilotTagIndex = updatedTags.findIndex((tag) => tag.DisplayName === AUTOPILOT_TAG);
        if (autopilotTagIndex !== -1) {
            updatedTags.splice(autopilotTagIndex, 1);
        }
    }

    return updatedTags;
};

const generateTagValueStrings = (arg: AutomationArgument): { displayValue: string; value: string } => {
    const displayValueParts: string[] = [];
    const valueParts: string[] = [];

    for (const [ key, val ] of Object.entries(arg)) {
        if (key === KEY_ID || key === KEY_DISPLAY_NAME) {
            continue;
        }
        // keys
        const displayValKey = key;
        const valKey = key.toLowerCase();
        // values
        const displayVal = key === KEY_NAME ? arg.displayName : val.toString();
        displayValueParts.push(`[${displayValKey}=${displayVal}]`);
        valueParts.push(`[${valKey}=${getSanitizedString(val.toString().toLowerCase())}]`);
    }

    const displayValue = displayValueParts.join('|');
    const value = valueParts.join('|');

    return {
        displayValue,
        value,
    };
};

const getSanitizedString = (str: string) => str.replace(/[<>%&\\?/:']/g, '');
