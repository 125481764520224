import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import { FontVariantToken } from '@uipath/apollo-core';
import React from 'react';
import { useIntl } from 'react-intl';

import { RemoteModuleComponent } from '../../remote/RemoteModuleComponent';

export const SettingsTab: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    return (
        <UiStack
            direction="column"
            mt={SpacingToken.L}
            gap={SpacingToken.XS}>
            <UiText
                variant={FontVariantToken.fontSizeLBold}
                data-cy="ai-trust-layer-settings-title">
                {translate({ id: 'CLIENT_AI_TRUST_LAYER_GOVERNANCE_TITLE' })}
            </UiText>
            <UiText data-cy="ai-trust-layer-settings-description">
                {translate({ id: 'CLIENT_AI_TRUST_LAYER_GOVERNANCE_DESCRIPTION' })}
            </UiText>
            <RemoteModuleComponent serviceName='roboticsops' />
        </UiStack>
    );
};
