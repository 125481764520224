import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

const useStyles = makeStyles(() =>
    createStyles({
        button: { marginRight: '10px' },
        buttonContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        buttonInner: { width: '100px' },
        confirm: { marginBottom: '8px' },
        description: { marginBottom: '24px' },
    }),
);

const A4EDeleteConsentDialogBody: React.FC<IUiDialogHookCustomContent> = ({ closeDialog }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    return (
        <>
            <UiText
                data-cy="starting-prompt-consent-description"
                className={classes.description}>
                <FormattedMessage
                    id='A4E_STARTING_PROMPTS_DELETE_CONSENT_DESCRIPTION'
                />
            </UiText>
            <div className={classes.buttonContainer}>
                <Button
                    className={clsx(classes.button, classes.buttonInner)}
                    color="primary"
                    data-cy="starting-prompt-delete-consent-cancel-button"
                    onClick={() => closeDialog(false)}
                    variant="outlined"
                >
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>
                <UiProgressButton
                    className={classes.button}
                    data-cy="starting-prompt-delete-consent-confirm-button"
                    innerButtonClass={classes.buttonInner}
                    loading={false}
                    onClick={() => closeDialog(true)}
                    variant="contained"
                >
                    {translate({ id: 'CLIENT_CONFIRM' })}
                </UiProgressButton>
            </div>
        </>
    );
};

export default A4EDeleteConsentDialogBody;
