import {
    SpacingToken,
    UiProgressButton,
    UiStack,
} from '@experiences/ui-common';
import { InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { ApButton } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
} from 'react';
import type { UseFormSetValue } from 'react-hook-form';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { notificationType } from '../../../../common/constants/Constant';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import type { TenantUIConfigInterface } from '../../../../services/a4e/A4EServiceTypes';
import { UiDrawer } from '../../../common/UiDrawer';
import UiForm from '../../../common/UiForm';

const useStyles = makeStyles(theme =>
    createStyles({
        colorPreview: {
            width: 20,
            height: 20,
            borderRadius: '50%',
            border: `1px solid ${theme.palette.grey[300]}`,
            marginRight: theme.spacing(1),
        },
    }),
);

type ColorFieldName = 'appBar.lightModeBackgroundColor' | 'appBar.lightModeTextColor' | 'appBar.darkModeBackgroundColor' | 'appBar.darkModeTextColor';

interface ColorTextFieldConfig {
    name: ColorFieldName;
    labelId: string;
    placeholder: string;
    dataCy: string;
}

interface A4EAdvancedSettingCoBrandingThemeDrawerComponentProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setValue: UseFormSetValue<any>;
    defaultValues?: TenantUIConfigInterface;
}

const defaultData: TenantUIConfigInterface = {
    appBar: {
        lightModeBackgroundColor: '',
        darkModeBackgroundColor: '',
        darkModeTextColor: '',
        lightModeTextColor: '',
        coBrandingText: '',
    },
    startingScreen: { logoURL: '' },
};

const colorTextFieldsConfig: ColorTextFieldConfig[] = [
    {
        name: 'appBar.lightModeBackgroundColor',
        labelId: 'A4E_ADVANCED_SETTINGS_LIGHT_MODE_BG_COLOR',
        placeholder: 'ffffff',
        dataCy: 'advanced-settings-drawer-light-mode-bg-color-field',
    },
    {
        name: 'appBar.lightModeTextColor',
        labelId: 'A4E_ADVANCED_SETTINGS_LIGHT_MODE_TEXT_COLOR',
        placeholder: 'ffffff',
        dataCy: 'advanced-settings-drawer-light-mode-text-color-field',
    },
    {
        name: 'appBar.darkModeBackgroundColor',
        labelId: 'A4E_ADVANCED_SETTINGS_DARK_MODE_BG_COLOR',
        placeholder: 'ffffff',
        dataCy: 'advanced-settings-drawer-dark-mode-bg-color-field',
    },
    {
        name: 'appBar.darkModeTextColor',
        labelId: 'A4E_ADVANCED_SETTINGS_DARK_MODE_TEXT_COLOR',
        placeholder: 'ffffff',
        dataCy: 'advanced-settings-drawer-dark-mode-text-color-field',
    },
];

const A4EAdvancedSettingCoBrandingThemeDrawerComponent: React.FC<A4EAdvancedSettingCoBrandingThemeDrawerComponentProps> = ({
    isOpen, setIsOpen, setValue, defaultValues,
}) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const createNotification = useUiSnackBar();
    const methods = useForm<TenantUIConfigInterface>({
        mode: 'onBlur',
        defaultValues: defaultValues ?? defaultData,
    });

    const {
        register, handleSubmit, reset, watch, formState: {
            errors, isValid, isDirty,
        },
    } = methods;

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        }
    }, [ defaultValues, reset ]);

    const handleClose = useCallback(() => {
        if (
            defaultValues?.appBar?.darkModeBackgroundColor === '' ||
            defaultValues?.appBar?.darkModeTextColor === '' ||
            defaultValues?.appBar?.lightModeBackgroundColor === '' ||
            defaultValues?.appBar?.lightModeTextColor === ''
        ) {
            setValue('tenantUIConfigEnabled', 'false');
        }
        reset();
        setIsOpen(false);
    }, [ reset, setIsOpen, setValue, defaultValues ]);

    const onSubmit = useCallback((data: TenantUIConfigInterface) => {
        setValue('tenantUIConfig', data);
        createNotification(
            translate({ id: 'A4E_ADVANCED_SETTINGS_DRAWER_CO_BRANDING_EDIT_SUCCESS' }),
            notificationType.SUCCESS
        );
        setIsOpen(false);
    }, [ setValue, setIsOpen, createNotification, translate ]);

    const getColorFieldsHelperText = useCallback((name: ColorFieldName) => {
        const [ parent, child ] = name.split('.');
        const errorType = (errors as any)?.[parent]?.[child]?.type;
        if (errorType === 'required') {
            return translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' });
        } else if (errorType === 'minLength' || errorType === 'maxLength') {
            return translate({ id: 'CLIENT_INVALID_COLOR_FIELD_LENGTH' });
        } else if (errorType === 'isValidColor') {
            return translate({ id: 'CLIENT_INVALID_COLOR_FIELD_ERROR' });
        }
        return '';
    }, [ errors, translate ]);

    const createColorTextField = useCallback((config: ColorTextFieldConfig) => {
        const {
            name, labelId, placeholder, dataCy,
        } = config;
        const [ parent, child ] = name.split('.');
        const error = (errors as any)?.[parent]?.[child];
        return (
            <TextField
                inputProps={{
                    ...register(name, {
                        minLength: 6,
                        maxLength: 6,
                        validate: {
                            required: p => !!p?.trim(),
                            isValidColor: p => /^[0-9A-F]{6}$/i.test(p),
                        },
                    }),
                    maxLength: 6,
                }}
                required
                error={!!error}
                helperText={getColorFieldsHelperText(name)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <div
                                className={classes.colorPreview}
                                style={{ backgroundColor: `#${watch(name)}` }}
                            />
                                        #
                        </InputAdornment>
                    ),
                }}
                placeholder={placeholder}
                label={translate({ id: labelId })}
                variant="outlined"
                fullWidth
                data-cy={dataCy}
            />
        );
    }, [ classes.colorPreview, errors, getColorFieldsHelperText, register, watch, translate ]);

    const createColorTextFields = useCallback(() => colorTextFieldsConfig.map(config => createColorTextField(config)), [ createColorTextField ]);

    return (
        <UiDrawer
            title={translate({ id: 'A4E_ADVANCED_SETTINGS_CO_BRANDING_DRAWER_TITLE' })}
            drawerProps={{
                anchor: 'right',
                open: isOpen,
                onClose: () => {
                    handleClose();
                },
            }}
            width="medium"
            themeProps={{ disableGutters: [ 'bottom', 'right' ] }}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <UiStack
                        direction="row"
                        justify='end'
                        align='center'
                        gap={SpacingToken.S}
                    >
                        <ApButton
                            onClick={() => handleClose()}
                            label={translate({ id: 'CLIENT_CANCEL' })}
                            variant='tertiary'
                            data-cy="tenant-ui-drawer-cancel-button"
                        />
                        <UiProgressButton
                            disabled={!isValid || !!Object.keys(errors).length || !isDirty}
                            type="submit"
                            variant="contained"
                            loading={false}
                            data-cy="tenant-ui-drawer-save-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </UiStack>
                }
                isDrawer
                addScrollPadding
            >
                <FormProvider {...methods}>
                    <UiStack
                        direction="column"
                        align="start"
                        gap={SpacingToken.M}
                    >
                        <TextField
                            inputProps={register('startingScreen.logoURL')}
                            label={translate({ id: 'A4E_ADVANCED_SETTINGS_COMPANY_LOGO' })}
                            variant="outlined"
                            fullWidth
                            data-cy="advanced-settings-drawer-logo-url-field"
                        />
                        <TextField
                            inputProps={register('appBar.coBrandingText')}
                            label={translate({ id: 'A4E_ADVANCED_SETTINGS_HEADER_TEXT' })}
                            variant="outlined"
                            fullWidth
                            data-cy="advanced-settings-drawer-header-text-field"
                        />
                        {createColorTextFields()}
                    </UiStack>
                </FormProvider>
            </UiForm>
        </UiDrawer>
    );
};

export default A4EAdvancedSettingCoBrandingThemeDrawerComponent;
