// UsageService.ts
import { getEnvironment } from '@experiences/util';

import { AuditLogService } from '../AuditTabServices/AuditLogService';

interface ActionStat {
    totalActions: number;
    succeededActions: number;
    failedActions: number;
    blockedActions: number;
}

interface ActionByStatus {
    year: number;
    month: number;
    totalAction: number;
    modelUsed: string;
    modelLocation: string;
    status: string;
}

interface ActionByProd {
    year: number;
    month: number;
    totalAction: number;
    product: string;
    tenantId: string | null;
}

export class UsageService {

    static getUsageApiBaseUrl(accountId: string): string {
        const env = getEnvironment();

        let baseUrl = '';
        if (env === 'local' || env === 'alp') {
            baseUrl = `https://alpha.uipath.com/${accountId}/llmops_/api/Usage/`;
        } else if (env === 'stg') {
            baseUrl = `https://staging.uipath.com/${accountId}/llmops_/api/Usage/`;
        } else if (env === 'prd') {
            baseUrl = `https://cloud.uipath.com/${accountId}/llmops_/api/Usage/`;
        }

        return baseUrl;
    }

    static async fetchActionStats(
        token: string,
        accountId: string,
        from: Date,
        to: Date
    ): Promise<ActionStat | null> {
        const baseUrl = this.getUsageApiBaseUrl(accountId);
        const url = `${baseUrl}actionStats?from=${from.toISOString()}&to=${to.toISOString()}`;

        return await AuditLogService.makeRequest('get', url, token, accountId);
    }

    static async fetchActionByStatus(
        token: string,
        accountId: string,
        day: Date
    ): Promise<ActionByStatus[] | null> {
        const baseUrl = this.getUsageApiBaseUrl(accountId);
        const url = `${baseUrl}actionByStatus?day=${day.toISOString()}`;

        return await AuditLogService.makeRequest('get', url, token, accountId);
    }

    static async fetchActionByProd(
        token: string,
        accountId: string,
        day: Date
    ): Promise<ActionByProd[]> {
        const baseUrl = this.getUsageApiBaseUrl(accountId);
        const url = `${baseUrl}actionByProd?day=${day.toISOString()}`;

        return await AuditLogService.makeRequest('get', url, token, accountId);
    }
}
