import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import { useAuthContext } from '@experiences/util';
import { FontVariantToken } from '@uipath/apollo-core';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { accountGlobalId } from '../../../store/selectors';
import { ProdDashboard } from './UsageTabServices/ProdDashboard';
import { StatusDashboard } from './UsageTabServices/StatusDashboard';

export const UsageTab: React.FC = () => {
    const { token } = useAuthContext();
    const accountId = useSelector(accountGlobalId);
    const { formatMessage: translate } = useIntl();

    return (
        <UiStack
            direction="column"
            mt={SpacingToken.L}
            gap={SpacingToken.XS}>
            {/* Descriptive text */}
            <UiText variant={FontVariantToken.fontSizeL}>
                {translate({ id: 'CLIENT_AI_TRUST_LAYER_USAGE_DESC' })}
            </UiText>

            <StatusDashboard
                token={token}
                accountId={accountId} />
            <ProdDashboard
                token={token}
                accountId={accountId} />

        </UiStack>
    );
};
