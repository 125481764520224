export class A4EError extends Error {
    status: number;
    errorMessages: string[];
    errorsCodes: string[];

    constructor(status: number, errorMessages: string[], errorsCodes: string[]) {
        super(errorMessages.join('\n'));
        this.status = status;
        this.errorMessages = errorMessages;
        this.errorsCodes = errorsCodes;
    }
}

export type A4EErrorResponse = {
    error_code: string;
    error: string;
    data: any | undefined;
};

export type AutomationSolutionsError = {
    title: string;
    status: number;
    errors: {
        [key: string]: Array<{
            errorCode: string;
            message: string;
        }>;
    };
    errorCodes: string[];
};

export const AutomationSolutionErrors = {
    Packages: {
        PackageVersionNotFound: '3001',
        PackageVersionAlreadyExists: '3005',
    },
};

type SolutionsRequest = {
    tenantName: string;
};

export type AutopilotSolution = {
    name: string;
    version: string;
};

export type SolutionPackagePublishStatusRequest = SolutionsGetPackageRequest;

export type SolutionPublishStatusResponse = {
    status: string;
    packageName: string;
    packageVersion: string;
};

export type SolutionsGetPackageRequest = SolutionsRequest & {
    packageName: string;
    packageVersion: string;
};

export type SolutionsPackageResponse = {
    key: string;
    packageName: string;
    packageVersion: string;
    publishDate: string;
    authorName: string;
    authorEmail: string;
    description: string;
    state: string;
    solutionRootFolderName: string;
};

export type SolutionsGetDeploymentsRequest = SolutionsRequest & {
    orderByColumn: string;
    orderByDirection: string;
    skip?: number;
    operation?: string;
    operationStatus?: string;
    activationStatus?: string;
    packageName: string;
    packageVersion?: string;
    take: number;
};

export type SolutionsGetDeploymentsResponse = {
    count: number;
    values: SolutionDeployment[];
};

export type SolutionDeployment = {
    key: string;
    installDeploymentKey: string;
    instanceId: string;
    configurationKey: string;
    packageVersionKey: string;
    packageKey: string;
    packageName: string;
    packageVersion: string;
    installedRootFolderKey: string;
    startTime: string;
    endTime: string;
    actions: string[];
    name: string;
    type: string;
    operation: string;
    operationStatus: string;
    activationStatus: string;
};

export type SolutionsDeloyPackageRequest = SolutionsRequest & {
    packageName: string;
    packageVersion: string;
    deploymentName: string;
    solutionFolderName: string;
};

export type SolutionsDeloyPackageResponse = SolutionsRequest & {
    pipelineDeploymentId: string;
};

export type SolutionsGetPipelineDeploymentStatusRequest = SolutionsRequest & {
    pipelineDeploymentId: string;
};

export type SolutionsPipelineDeploymentStatusResponse = {
    status: string;
    validationResult: {
        success: boolean;
        validationErrors: DeploymentValidationError[];
        conflictErrors: DeploymentConflictError[];
    };
    deploymentResult: SolutionsDeploymentStatusResponse;
    conflictFixingErrors: DeploymentConflictFixingError[];
    deploymentScheduleErrors: DeploymentError[];
};

export type DeploymentError = {
    errorText: string;
    errorCode: string;
};

type DeploymentValidationError = DeploymentError & {
    kind: string;
    name: string;
    resourceKey: string;
    errorType: string;
};

type DeploymentConflictError = DeploymentValidationError & {
    conflictFixingActions: string[];
};

type DeploymentConflictFixingError = DeploymentValidationError;

export type SolutionsGetDeploymentStatusRequest = SolutionsRequest & {
    instanceId: string;
};

export type SolutionsDeploymentStatusResponse = {
    deploymentKey: string;
    installDeploymentKey: string;
    deploymentName: string;
    configurationKey: string;
    installedRootFolderKey: string;
    packageName: string;
    packageVersion: string;
    packageVersionKey: string;
    status: string;
    authorName: string;
    startDate: string;
    endDate: string;
    errorMessage: string[];
    actions: string[];
    operation: string;
    services: Array<{
        serviceName: string;
        status: string;
        updateDate: string;
        serviceErrorMessages: string[];
    }>;
};

export type SolutionsUninstallDeploymentRequest = SolutionsRequest & {
    deploymentName: string;
};

export type SolutionsUninstallDeploymentResponse = {
    instanceId: string;
    scheduled: boolean;
};

export type LaunchAcknowledgementConfigInterface = {
    headerText: string;
    messageImageURL?: string;
    messageText: string;
    acknowledgementButtonText: string;
};

export type TenantUIConfigInterface = {
    appBar?: {
        lightModeBackgroundColor: string;
        darkModeBackgroundColor: string;
        darkModeTextColor: string;
        lightModeTextColor: string;
        coBrandingText?: string;
    };
    startingScreen?: {
        logoURL?: string;
    };
};

export type ContextGroundingIndexes = {
    id: string;
    indexName: string;
    indexDescription: string;
    folder: string;
};

export type TenantConfiguration = {
    tenantUIConfig?: TenantUIConfigInterface;
    launchAcknowledgementConfig?: LaunchAcknowledgementConfigInterface;
    userDepartmentOptions?: string[];
    fileUploadEnabled?: boolean;
    userNotesEnabled?: boolean;
    sendFeedbackToUiPathEnabled?: boolean;
    hideSuggestedPrompts?: boolean;
    autoSearchECSEnabled?: boolean;
    autoSearchECSIndex?: string;
    ecsNumberOfSearchResults?: number;
    administratorCorePromptNotes?: string;
    administratorSuggestedFollowUpsPromptNotes?: string;
    chatHistoryVisibilityDays?: number;
    contextGroundingIndexes?: ContextGroundingIndexes[];
};
