import type { IPagination } from '@experiences/interfaces';
import {
    SpacingToken,
    UiStack,
} from '@experiences/ui-common';
import { useShowDialog } from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import type { Tenant } from '@uipath/portal-shell/dist/types/models/tenant';
import {
    ApButton,
    ApDataGridColumn,
    ApDataGridFooter,
    ApDataGridRowActions,
    ApDataGridRowButton,
    ApDataGridWrapper,
    ApDropdown,
    ApDropdownItem,
} from '@uipath/portal-shell-react';
import type { ApDropdownCustomEvent } from '@uipath/portal-shell-types';
import FileSaver from 'file-saver';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../../common/constants/Constant';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import type {
    IA4EStartingPromptData,
    IA4EStartingPromptsDrawerParams,
} from '../../../../common/interfaces/a4e';
import type { SimpleFolderDto } from '../../../../services/orchestrator/OrchBucketService';
import type { Folder } from '../../../../services/orchestrator/OrchFolderService';
import { accountLogicalName } from '../../../../store/selectors';
import { UiAccordion } from '../../../licensing/UiAccordion';
import type { A4EErrorMessage } from '../utils/A4EErrorType';
import {
    convertToCSV,
    getAllFolders,
    getConfiguredFolders,
    getDefaultPrompts,
    getStartingPromptsForFolder,
    saveStartingPromptsForFolder,
    STARTING_PROMPTS_FILE_NAME_CSV,
} from '../utils/A4EStartingPromptsUtils';
import A4EDeleteConsentDialogBody from './A4EDeleteConsentDialogBody';
import A4EStartingPromptsAddOrEditComponent from './A4EStartingPromptsAddOrEditComponent';
import A4EStartingPromptsUploadComponent from './A4EStartingPromptsUploadComponent';

interface A4EStartingPromptsComponentProps {
    selectedTenant: Tenant;
    testSelectedOrchestratorFolderId?: string; // for testing only
}

const A4EStartingPromptsComponent: React.FC<A4EStartingPromptsComponentProps> = ({
    selectedTenant, testSelectedOrchestratorFolderId,
}) => {
    const createNotification = useUiSnackBar();
    const organizationName = useSelector(accountLogicalName);
    const { formatMessage: translate } = useIntl();
    const [ selectedOrchestratorFolderId, setSelectedOrchestratorFolderId ] = useState(testSelectedOrchestratorFolderId ?? '');
    const [ orchestratorFolders, setOrchestratorFolders ] = useState<Folder[] | SimpleFolderDto[]>([]);
    const [ isFetchingOrchFolders, setIsFetchingOrchFolders ] = useState(false);
    const [ isFetchingStartingPrompts, setIsFetchingStartingPrompts ] = useState(false);
    const [ isDownloading, setIsDownloading ] = useState(false);
    const [ configuredFoldersOnly, setConfiguredFoldersOnly ] = useState(false);
    const [ originalStartingPromptsTableData, setOriginalStartingPromptsTableData ] = useState<IA4EStartingPromptData[]>([]);
    const [ startingPromptsTableData, setStartingPromptsTableData ] = useState<IA4EStartingPromptData[]>([]);
    const [ pagination, setPagination ] = useState<IPagination>({
        top: 25,
        skip: 0,
    });
    const [ startingPromptsDrawerParams, setStartingPromptsDrawerParams ] = useState<IA4EStartingPromptsDrawerParams>({
        open: false,
        isEditMode: false,
        editingStartingPrompt: null,
    });
    const [ uploadDrawerIsOpen, setUploadDrawerIsOpen ] = useState(false);
    const createDialog = useShowDialog();
    const disabled = !selectedOrchestratorFolderId || isFetchingOrchFolders || isFetchingStartingPrompts;

    const createErrorNotification = (error: A4EErrorMessage) => {
        const code = error?.status ? `${error.status}: ` : '';
        const message = translate({ id: error.errorId });
        createNotification(`${message} - ${code}`, notificationType.ERROR);
    };

    // fetch orchestrator folders data in two scenarios
    // 1. when the component mounts
    // 2. when configuredFoldersOnly changes
    useEffect(() => {
        const fetchOrchFoldersData = async () => {
            setIsFetchingOrchFolders(true);
            try {
                const folders = configuredFoldersOnly ? await getConfiguredFolders({
                    accountLogicalName: organizationName,
                    tenantName: selectedTenant.name,
                }) : await getAllFolders({
                    accountLogicalName: organizationName,
                    tenantName: selectedTenant.name,
                });

                if (configuredFoldersOnly && folders.length > 0) {
                    createNotification(translate({ id: 'A4E_CONFIGURED_FOLDER_FOUND' }), notificationType.DEFAULT);
                }
                setOrchestratorFolders(folders ?? []);
            } catch (e) {
                createErrorNotification(e as A4EErrorMessage);
                setOrchestratorFolders([]);
            } finally {
                setIsFetchingOrchFolders(false);
            }
        };

        fetchOrchFoldersData();
    }, [ configuredFoldersOnly, organizationName, selectedTenant.name, createNotification, translate ]);

    useEffect(() => {
        // When new options for orchestrator folders are received, check if the currently selected folder
        // is still present in the new options. If the current folder is not found in the new options,
        // reset the selected folder to ''.
        const isSelectedFolderInOptions = orchestratorFolders.find(folder => `${folder.Id}` === selectedOrchestratorFolderId);
        if (selectedOrchestratorFolderId && !isSelectedFolderInOptions) {
            setSelectedOrchestratorFolderId(testSelectedOrchestratorFolderId ?? '');
        }
    }, [ orchestratorFolders, selectedOrchestratorFolderId ]);

    useEffect(() => {
        if (!selectedOrchestratorFolderId) {
            setOriginalStartingPromptsTableData([]);
            return;
        }

        const fetchStartingPromptsData = async () => {
            try {
                setIsFetchingStartingPrompts(true);
                const startingPrompts = await getStartingPromptsForFolder({
                    accountLogicalName: organizationName,
                    tenantName: selectedTenant.name,
                    folderId: selectedOrchestratorFolderId,
                });

                if (startingPrompts?.length === 0) {
                    createNotification(translate({ id: 'A4E_STARTING_PROMPTS_NOT_FOUND' }), notificationType.DEFAULT);
                }

                setOriginalStartingPromptsTableData(startingPrompts ?? []);
            } catch (e) {
                createErrorNotification(e as A4EErrorMessage);
                setOriginalStartingPromptsTableData([]);
            } finally {
                setIsFetchingStartingPrompts(false);
            }

        };

        if (selectedOrchestratorFolderId) {
            fetchStartingPromptsData();
        }
    }, [ selectedOrchestratorFolderId, organizationName, selectedTenant.name, createNotification, translate ]);

    useEffect(() => {
        setStartingPromptsTableData(originalStartingPromptsTableData);
    }, [ originalStartingPromptsTableData ]);

    const onChange = useCallback((event: ApDropdownCustomEvent<string | string[]>) => {
        if (event.detail) {
            setSelectedOrchestratorFolderId(event.detail as string);
        }
    }, []);

    const onGridChange = useCallback(({
        pageIndex, pageSize,
    }: any) => {
        const newPaginationState: IPagination = {
            top: pageSize ?? 25,
            skip: (pageIndex ?? 0) * (pageSize ?? 25),
        };

        setPagination(newPaginationState);
    }, [ ]);

    const exportStartingPrompts = useCallback(async () => {
        setIsDownloading(true);
        const FIELDS = [ 'department', 'category', 'title', 'prompt', 'isFeatured', 'requiresFileUpload' ];
        const csvData = convertToCSV(startingPromptsTableData, FIELDS);
        const startingPromptsData = new Blob([ csvData ], { type: 'text/csv;charset=utf-8;' });
        FileSaver.saveAs(startingPromptsData, STARTING_PROMPTS_FILE_NAME_CSV);
        setIsDownloading(false);
    }, [ startingPromptsTableData, setIsDownloading, convertToCSV ]);

    const onEditStartingPromptRow = useCallback((row: IA4EStartingPromptData) => {
        setStartingPromptsDrawerParams({
            open: true,
            isEditMode: true,
            editingStartingPrompt: row,
        });
    }, []);

    const onDeleteStartingPromptRow = useCallback(async (row: IA4EStartingPromptData) => {
        const proceed = await createDialog({
            title: translate({ id: 'A4E_STARTING_PROMPTS_DELETE_CONSENT_HEADER' }),
            customDialogContent: A4EDeleteConsentDialogBody,
            icon: 'warning',
        });
        if (proceed) {
            // i assume we will have an id and we will use that to determine which row to delete
            const newStartingPromptsTableData = startingPromptsTableData.filter(rowData => rowData.id !== row.id);
            setStartingPromptsTableData(newStartingPromptsTableData);
        }
    }, [ createDialog, startingPromptsTableData, translate ]);

    const handleSubmit = async () => {
        setIsFetchingStartingPrompts(true);
        try {
            await saveStartingPromptsForFolder({
                accountLogicalName: organizationName,
                tenantName: selectedTenant.name,
                folderId: selectedOrchestratorFolderId,
            }, startingPromptsTableData);
            setOriginalStartingPromptsTableData(startingPromptsTableData);
            createNotification(translate({ id: 'A4E_SAVED_SUCCESSFULLY' }), notificationType.SUCCESS);
        } catch (e) {
            createErrorNotification(e as A4EErrorMessage);
        } finally {
            setIsFetchingStartingPrompts(false);
        }
    };

    const addDefaultPrompts = async () => {
        setIsFetchingStartingPrompts(true);
        const defaultPrompts = getDefaultPrompts();
        // before adding each prompt, want to check if it already exists, if it does, don't add it
        // if same department, same category, same title -> don't add
        const existingPrompts = new Set(
            startingPromptsTableData.map(prompt => `${prompt.department}-${prompt.category}-${prompt.title}`)
        );
        const newPrompts = defaultPrompts.filter(
            prompt => !existingPrompts.has(`${prompt.department}-${prompt.category}-${prompt.title}`)
        );
        const updatedStartingPrompts = startingPromptsTableData.concat(newPrompts);
        setStartingPromptsTableData(updatedStartingPrompts);
        setIsFetchingStartingPrompts(false);
    };

    const paginatedTableData = useMemo(() =>
        startingPromptsTableData.slice(pagination.skip, pagination.skip + pagination.top),
    [ pagination.skip, pagination.top, startingPromptsTableData ]);

    return (
        <UiAccordion
            titleTranslationCode="A4E_STARTING_PROMPTS"
            expandedByDefault={false}
        >
            <UiStack
                direction='column'
                data-cy='tenant-customize'
                gap={SpacingToken.S}
            >
                <UiStack
                    direction='row'
                    justify='between'
                    align='end'
                    mb={SpacingToken.S}
                >
                    <UiStack
                        direction='row'
                        align='end'
                        gap={SpacingToken.S}
                    >
                        <ApDropdown
                            style={{ width: '322px' }}
                            disabled={isFetchingOrchFolders}
                            label={translate({ id: 'A4E_CUSTOM_TAB_ORCHESTATOR_FOLDER' })}
                            selectedValue={selectedOrchestratorFolderId}
                            onSelectedValueChanged={onChange}
                            data-cy="starting-prompt-orchestrator-folder-select"
                        >
                            {orchestratorFolders.length === 0 ? (
                                <ApDropdownItem
                                    disabled
                                    label={translate({ id: 'A4E_NO_RESULTS_FOUND' })}
                                />
                            ) : (orchestratorFolders.map(option => (
                                <ApDropdownItem
                                    value={`${option.Id}`}
                                    label={option.FullyQualifiedName}
                                    key={`ap-dropdown-item-${option.Id}`}
                                    data-cy={`ap-dropdown-item-${option.Id}`}
                                />
                            )))}
                        </ApDropdown>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={configuredFoldersOnly}
                                    onChange={(e) => setConfiguredFoldersOnly(e.target.checked)}
                                    data-cy="ecommerce-marketing-consent"
                                />
                            }
                            label={translate({ id: 'A4E_CONFIGURED_FOLDERS_ONLY' })}
                            disabled={isFetchingOrchFolders || isFetchingStartingPrompts}
                        />
                    </UiStack>
                    <UiStack
                        direction='row'
                        gap={SpacingToken.XS}
                    >
                        <Button
                            startIcon={<UploadOutlinedIcon />}
                            color="primary"
                            type="submit"
                            onMouseDown={(e: any) => e.preventDefault()}
                            data-cy="upload-button"
                            disabled={disabled}
                            onClick={() => {
                                setUploadDrawerIsOpen(true);
                            }}
                        >
                            {translate({ id: 'A4E_CUSTOM_TAB_UPLOAD' })}
                        </Button>
                        <Button
                            startIcon={<DownloadOutlinedIcon />}
                            color="primary"
                            type="submit"
                            onMouseDown={(e: any) => e.preventDefault()}
                            data-cy="download-button"
                            disabled={disabled || isDownloading}
                            onClick={() => {
                                exportStartingPrompts();
                            }}
                        >
                            {translate({ id: 'A4E_CUSTOM_TAB_DOWNLOAD' })}
                        </Button>
                        <Button
                            startIcon={<AddIcon />}
                            color="primary"
                            type="submit"
                            onMouseDown={(e: any) => e.preventDefault()}
                            data-cy="add-new-prompt-button"
                            disabled={disabled}
                            onClick={() => {
                                setStartingPromptsDrawerParams({
                                    open: true,
                                    isEditMode: false,
                                    editingStartingPrompt: null,
                                });
                            }}
                        >
                            {translate({ id: 'A4E_STARTING_PROMPTS_ADD_NEW_PROMPT' })}
                        </Button>
                    </UiStack>
                </UiStack>
                <ApDataGridWrapper<IA4EStartingPromptData>
                    data={paginatedTableData ?? []}
                    loading={isFetchingOrchFolders || isFetchingStartingPrompts}
                    selectable={false}
                    onChange={onGridChange}
                    dataCy="starting-prompt-grid">
                    <ApDataGridColumn<IA4EStartingPromptData>
                        property="department"
                        title={translate({ id: 'A4E_STARTING_PROMPTS_TABLE_HEADER_DEPARTMENT' })}
                        width={20}
                    />
                    <ApDataGridColumn<IA4EStartingPromptData>
                        property="category"
                        title={translate({ id: 'A4E_STARTING_PROMPTS_TABLE_HEADER_CATEGORY' })}
                        width={20}
                    />
                    <ApDataGridColumn<IA4EStartingPromptData>
                        property="title"
                        title={translate({ id: 'A4E_STARTING_PROMPTS_TABLE_HEADER_TITLE' })}
                        width={65}
                    />
                    <ApDataGridColumn<IA4EStartingPromptData>
                        property="prompt"
                        title={translate({ id: 'A4E_STARTING_PROMPTS_TABLE_HEADER_PROMPT' })}
                        width={65}
                    />
                    <ApDataGridColumn<IA4EStartingPromptData>
                        property="isFeatured"
                        title={translate({ id: 'A4E_STARTING_PROMPTS_TABLE_HEADER_FEATURED' })}
                        width={20}
                        render={({ isFeatured }) => <>
                            {isFeatured ? 'Yes' : 'No'}
                        </>}
                    />
                    <ApDataGridColumn<IA4EStartingPromptData>
                        property="requiresFileUpload"
                        title={translate({ id: 'A4E_STARTING_PROMPTS_TABLE_HEADER_FILE_UPLOAD' })}
                        width={20}
                        render={({ requiresFileUpload }) => <>
                            {requiresFileUpload ? 'Yes' : 'No'}
                        </>}
                    />
                    <ApDataGridRowActions>
                        <ApDataGridRowButton<IA4EStartingPromptData>
                            id='edit'
                            dataCy='edit'
                            label={translate({ id: 'CLIENT_EDIT' })}
                            icon='edit'
                            onClick={(row) => onEditStartingPromptRow(row)}
                        />
                        <ApDataGridRowButton<IA4EStartingPromptData>
                            id='delete'
                            dataCy='delete'
                            label={translate({ id: 'CLIENT_DELETE' })}
                            icon='delete'
                            onClick={(row) => onDeleteStartingPromptRow(row)}
                        />
                    </ApDataGridRowActions>
                    <ApDataGridFooter
                        length={startingPromptsTableData.length}
                        pageSizes={[ 5, 10, 25, 50 ]}
                    />
                </ApDataGridWrapper>
                <UiStack
                    direction='row'
                    justify='between'
                >
                    <ApButton
                        variant='primary'
                        data-cy="add-default-prompts-button"
                        disabled={disabled}
                        onClick={() => {
                            addDefaultPrompts();
                        }}
                        label={translate({ id: 'A4E_STARTING_PROMPTS_ADD_DEFAULT_PROMPTS' })}
                    />
                    <UiStack gap={SpacingToken.XS}>
                        <ApButton
                            variant="tertiary"
                            data-cy="reset-button"
                            disabled={disabled}
                            onClick={() => {
                                setStartingPromptsTableData(originalStartingPromptsTableData);
                            }}
                            label={translate({ id: 'A4E_CUSTOM_TAB_RESET' })}
                        />
                        <ApButton
                            variant='primary'
                            data-cy="save-button"
                            disabled={disabled}
                            onClick={handleSubmit}
                            label={translate({ id: 'A4E_CUSTOM_TAB_SAVE' })}
                        />
                    </UiStack>
                </UiStack>
                <A4EStartingPromptsAddOrEditComponent
                    startingPromptsDrawerParams={startingPromptsDrawerParams}
                    setStartingPromptsDrawerParams={setStartingPromptsDrawerParams}
                    setStartingPromptsTableData={setStartingPromptsTableData}
                />
                <A4EStartingPromptsUploadComponent
                    isOpen={uploadDrawerIsOpen}
                    setIsOpen={setUploadDrawerIsOpen}
                    setStartingPromptsTableData={setStartingPromptsTableData}
                />
            </UiStack>
        </UiAccordion>
    );
};

export default A4EStartingPromptsComponent;
