import type { IPagination } from '@experiences/interfaces';
import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { FontVariantToken } from '@uipath/apollo-core';
import type { Tenant } from '@uipath/portal-shell/dist/types/models/tenant';
import {
    ApButton,
    ApCheckbox,
    ApDataGridColumn,
    ApDataGridFooter,
    ApDataGridRowActions,
    ApDataGridRowButton,
    ApDataGridWrapper,
    ApDropdown,
    ApDropdownItem,
    ApTooltip,
    PortalIcon,
} from '@uipath/portal-shell-react';
import type { ApDropdownCustomEvent } from '@uipath/portal-shell-types';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../../common/constants/Constant';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import type {
    AutomationArgument,
    IA4EAutomationPartialData,
    IA4EAutomationPropertiesDrawerParams,
} from '../../../../common/interfaces/a4e';
import type { A4EError } from '../../../../services/a4e/A4EServiceTypes';
import type { Folder } from '../../../../services/orchestrator/OrchFolderService';
import type {
    Release,
    Tag,
} from '../../../../services/orchestrator/OrchReleaseService';
import { accountLogicalName } from '../../../../store/selectors';
import { UiAccordion } from '../../../licensing/UiAccordion';
import {
    getAllFolders,
    getArgumentsData,
    getAutomationById,
    getAutomationsForCurrentFolder,
    getUpdatedTags,
    updateAutomation,
} from '../utils/A4EAutomationPropertiesUtils';
import A4EAutomationPropertiesEditComponent from './A4EAutomationPropertiesEditComponent';

interface A4EAutomationPropertiesComponentProps {
    selectedTenant: Tenant;
}

const defaultPartialAutomationData: IA4EAutomationPartialData = {
    AutopilotPromptDescription: '',
    Autopilot: false,
    AutopilotPreResponseAction: false,
};

const A4EAutomationPropertiesComponent: React.FC<A4EAutomationPropertiesComponentProps> = ({ selectedTenant }) => {
    const createNotification = useUiSnackBar();
    const { formatMessage: translate } = useIntl();
    const organizationName = useSelector(accountLogicalName);
    const [ orchestratorFolder, setOrchestratorFolder ] = useState('');
    const [ orchestratorFolders, setOrchestratorFolders ] = useState<Folder[]>([]);
    const [ isFoldersLoading, setIsFoldersLoading ] = useState(false);
    const [ isFetchingAutomations, setIsFetchingAutomations ] = useState(false);
    const [ isFetchingAutomation, setIsFetchingAutomation ] = useState(false);
    const [ isUploading, setIsUploading ] = useState(false);
    const [ automationOptions, setAutomationOptions ] = useState<Release[]>([]);
    const [ selectedAutomation, setSelectedAutomation ] = useState('');
    const [ autopilotEnabledOnly, setAutopilotEnabledOnly ] = useState(false);
    const [ fullAutomationData, setFullAutomationData ] = useState<any>(null);
    const [ originalTags, setOriginalTags ] = useState<Tag[]>([]);
    // TABLE DATA
    const [ originalArgumentData, setOriginalArgumentData ] = useState<AutomationArgument[]>([]);
    // WILL BE USED UPON SUBMIT (USER UPDATED TABLE DATA)
    const [ argumentData, setArgumentData ] = useState<AutomationArgument[]>([]);
    const [ pagination, setPagination ] = useState<IPagination>({
        top: 25,
        skip: 0,
    });
    const [ AutomationPropertiesDrawerParams, setAutomationPropertiesDrawerParams ] = useState<IA4EAutomationPropertiesDrawerParams>({
        open: false,
        editingAutomationProperties: null,
    });

    const {
        control, handleSubmit, reset, register,
    } = useForm<IA4EAutomationPartialData>({ defaultValues: defaultPartialAutomationData });

    const createGenericErrorNotification = useCallback((messageId: string, error: A4EError) => {
        createNotification(
            translate({ id: messageId }, { error: error.message }),
            notificationType.ERROR,
        );
    }, [ createNotification, translate ]);

    // only fetch orchestrator folders on mount -> everything is disabled until the folder is selected
    useEffect(() => {
        const fetchOrchestratorFoldersData = async () => {
            setIsFoldersLoading(true);
            try {
                const folders = await getAllFolders({
                    accountLogicalName: organizationName,
                    tenantName: selectedTenant.name,
                });
                setOrchestratorFolders(folders);
            } catch (e) {
                const error = e as A4EError;
                createGenericErrorNotification('A4E_ORCH_FOLDER_ERROR', error);
                setOrchestratorFolders([]);
                setOrchestratorFolder('');
            } finally {
                setIsFoldersLoading(false);
            }
        };
        fetchOrchestratorFoldersData();
    }, [ organizationName, selectedTenant.name, createNotification, translate, createGenericErrorNotification ]);

    // when the value of the orchestartorFolder changes or the autopilotEnabledOnly checkbox is checked/unchecked,
    // fetch the automation options for the selected folder
    useEffect(() => {
        const fetchAutomations = async () => {
            setIsFetchingAutomations(true);
            try {
                const releases = await getAutomationsForCurrentFolder(
                    autopilotEnabledOnly,
                    {
                        accountLogicalName: organizationName,
                        tenantName: selectedTenant.name,
                        folderId: orchestratorFolder,
                    });
                setAutomationOptions(releases);
            } catch (e) {
                const error = e as A4EError;
                createGenericErrorNotification('A4E_AUTOMATION_PROPERTIES_AUTOMATIONS_ERROR', error);
                setAutomationOptions([]);
                setSelectedAutomation('');
            } finally {
                setIsFetchingAutomations(false);
            }

        };

        if (orchestratorFolder) {
            fetchAutomations();
        }
    }, [ orchestratorFolder, autopilotEnabledOnly, organizationName, selectedTenant.name, createNotification, translate, createGenericErrorNotification ]);

    useEffect(() => {
        // clear out the selected automation if the automation options change and its not in the options
        if (selectedAutomation && !automationOptions.find(option => `${option.Id}` === selectedAutomation)) {
            setSelectedAutomation('');
        }
    }, [ automationOptions, selectedAutomation ]);

    // once an automation is selected or cleared out => make changes to the ui, fetch or clear out data
    useEffect(() => {
        if (!selectedAutomation) {
            setFullAutomationData(null);
            // reset table data
            setOriginalArgumentData([]);
            return;
        }

        const fetchAutomationData = async () => {
            setIsFetchingAutomation(true);
            try {
                const data = await getAutomationById({
                    accountLogicalName: organizationName,
                    tenantName: selectedTenant.name,
                    folderId: orchestratorFolder,
                    processId: selectedAutomation,
                });
                setFullAutomationData(data);
                if (data.Tags) {
                    const tags: Tag[] = data.Tags;
                    setOriginalTags(tags);
                    // tags is what we want to read and what we will update if the user changes the data
                    const AutopilotPromptDescription = tags.find(tag => tag.DisplayName === 'AutopilotPromptDescription')?.DisplayValue ?? '';
                    const Autopilot = !!tags.find(tag => tag.DisplayName === 'Autopilot');
                    const AutopilotPreResponseAction = tags.find(tag => tag.DisplayName === 'AutopilotPreResponseAction')?.Value === 'true';
                    const automationArguments = getArgumentsData(data.Arguments.Input, tags);
                    // set the original data so we can reset later if needed
                    setOriginalArgumentData(automationArguments);
                    reset({
                        AutopilotPromptDescription,
                        Autopilot,
                        AutopilotPreResponseAction,
                    });
                } else {
                    // no properties set
                    setOriginalArgumentData([]);
                    reset(defaultPartialAutomationData);
                }
            } catch (e) {
                setOriginalArgumentData([]);
                setFullAutomationData(null);
                reset(defaultPartialAutomationData);
                const error = e as A4EError;
                createGenericErrorNotification('A4E_AUTOMATION_PROPERTIES_AUTOMATION_FETCH_ERROR', error);
            } finally {
                setIsFetchingAutomation(false);
            }
        };

        fetchAutomationData();
    }, [ selectedAutomation, reset, orchestratorFolder, organizationName, selectedTenant.name, createNotification, translate, createGenericErrorNotification ]);

    useEffect(() => {
        setArgumentData(originalArgumentData);
    }, [ originalArgumentData ]);

    const onGridChange = useCallback(({
        pageIndex, pageSize,
    }: any) => {
        const newPaginationState: IPagination = {
            top: pageSize ?? 25,
            skip: (pageIndex ?? 0) * (pageSize ?? 25),
        };

        setPagination(newPaginationState);
    }, [ ]);

    const onOrchFolderChange = useCallback((event: ApDropdownCustomEvent<string | string[]>) => {
        setOrchestratorFolder(event.detail as string);
        setSelectedAutomation('');
    }, []);

    const onAutomationChange = useCallback((event: ApDropdownCustomEvent<string | string[]>) => {
        setSelectedAutomation(event.detail as string);
    }, []);

    const handleReset = () => {
        reset();
        setArgumentData(originalArgumentData);
        createNotification(
            translate({ id: 'A4E_AUTOMATION_PROPERTIES_RESET_SUCCESS' }),
            notificationType.SUCCESS,
        );
    };

    const onSubmit = useCallback(async (data: IA4EAutomationPartialData) => {
        const updatedTags = getUpdatedTags(originalTags, data, argumentData);
        setIsUploading(true);
        try {
            await updateAutomation({
                accountLogicalName: organizationName,
                tenantName: selectedTenant.name,
                folderId: orchestratorFolder,
                processId: selectedAutomation,
            },
            {
                ...fullAutomationData,
                Tags: updatedTags,
            });
            createNotification(
                translate({ id: 'A4E_AUTOMATION_PROPERTIES_SAVE_SUCCESS' }),
                notificationType.SUCCESS
            );

            // update all original values to updated values
            const AutopilotPromptDescription = updatedTags.find(tag => tag.DisplayName === 'AutopilotPromptDescription')?.DisplayValue ?? '';
            const Autopilot = !!updatedTags.find(tag => tag.DisplayName === 'Autopilot');
            const AutopilotPreResponseAction = updatedTags.find(tag => tag.DisplayName === 'AutopilotPreResponseAction')?.Value === 'true';
            setOriginalTags(updatedTags);
            setOriginalArgumentData(argumentData);
            reset({
                AutopilotPromptDescription,
                Autopilot,
                AutopilotPreResponseAction,
            });
        } catch (e) {
            const error = e as A4EError;
            createGenericErrorNotification('A4E_AUTOMATION_PROPERTIES_SAVE_ERROR', error);
        } finally {
            setIsUploading(false);
        }

    }, [
        argumentData,
        createGenericErrorNotification,
        createNotification,
        fullAutomationData,
        orchestratorFolder,
        organizationName,
        originalTags,
        selectedAutomation,
        selectedTenant.name,
        translate,
        reset,
    ]);

    const onEditAutomationArgumentRow = useCallback((row: AutomationArgument) => {
        setAutomationPropertiesDrawerParams({
            open: true,
            editingAutomationProperties: row,
        });
    }, []);

    const createFolderDropdown = useCallback(() => <ApDropdown
        style={{ width: '322px' }}
        disabled={isFoldersLoading}
        label={translate({ id: 'A4E_CUSTOM_TAB_ORCHESTATOR_FOLDER' })}
        selectedValue={orchestratorFolder}
        onSelectedValueChanged={onOrchFolderChange}
        data-cy="automation-properties-orchestrator-folder-dropdown"
    >
        {orchestratorFolders.length === 0 ? (
            <ApDropdownItem
                disabled
                label={translate({ id: 'A4E_NO_RESULTS_FOUND' })}
            />
        ) : (orchestratorFolders.map(option => (
            <ApDropdownItem
                value={`${option.Id}`}
                label={option.FullyQualifiedName}
                key={`automation-properties-dropdown-item-${option.Id}`}
                data-cy={`automation-properties-dropdown-item-${option.DisplayName}`}
            />
        )))}
    </ApDropdown>, [ isFoldersLoading, onOrchFolderChange, orchestratorFolder, orchestratorFolders, translate ]);

    const createAutomationDropdown = useCallback(() => <ApDropdown
        style={{ width: '322px' }}
        disabled={!orchestratorFolder || isFetchingAutomations}
        label={translate({ id: 'A4E_AUTOMATION_PROPERTIES_AUTOMATION' })}
        selectedValue={selectedAutomation}
        onSelectedValueChanged={onAutomationChange}
        data-cy="automation-dropdown"
    >
        {automationOptions.length === 0 ? (
            <ApDropdownItem
                disabled
                label={translate({ id: 'A4E_NO_RESULTS_FOUND' })}
            />
        ) : (automationOptions.map(option => (
            <ApDropdownItem
                value={`${option.Id}`}
                label={option.Name}
                key={`automation-dropdown-item-${option.Id}`}
                data-cy={`automation-dropdown-item-${option.Name}`}
            />
        )))}
    </ApDropdown>, [ automationOptions, isFetchingAutomations, onAutomationChange, orchestratorFolder, selectedAutomation, translate ]);

    const createAutomationCheckboxes = useCallback(() => {
        const checkboxes: Array<{
            name: 'Autopilot' | 'AutopilotPreResponseAction';
            labelId: string;
            tooltipLabelId: string;
            dataCy: string;
        }> = [
            {
                name: 'Autopilot',
                labelId: 'A4E_AUTOMATION_PROPERTIES_ENABLE_FOR_AUTOPILOT',
                tooltipLabelId: 'A4E_AUTOMATION_PROPERTIES_AUTOMATION_AUTOPILOT_TOOLTIP',
                dataCy: 'enable-for-autopilot-checkbox',
            },
            {
                name: 'AutopilotPreResponseAction',
                labelId: 'A4E_AUTOMATION_PROPERTIES_SET_FOR_PRERESPONSE_ACTION',
                tooltipLabelId: 'A4E_AUTOMATION_PROPERTIES_AUTOMATION_PRE_RESPONSE_TOOLTIP',
                dataCy: 'is-preresponse-action-checkbox',
            },
        ];

        return <UiStack
            direction="column"
            justify='start'
            align="start"
            mt={SpacingToken.M}
            mb={SpacingToken.M}
            gap={SpacingToken.S}
        >
            <TextField
                inputProps={register('AutopilotPromptDescription')}
                disabled={isUploading}
                label={
                    <UiStack
                        direction='row'
                        align='center'
                        gap={SpacingToken.Micro}>
                        <UiText variant={FontVariantToken.fontSizeMBold}>
                            {translate({ id: 'A4E_AUTOMATION_PROPERTIES_AUTOMATION_PROMPT_DESC' })}
                        </UiText>
                        <ApTooltip
                            content={translate({ id: 'A4E_AUTOMATION_PROPERTIES_AUTOMATION_PROMPT_DESC_TOOLTIP' })}
                            placement='right'>
                            <PortalIcon
                                name='info'
                                variant='outlined' />
                        </ApTooltip>
                    </UiStack>
                }
                variant="outlined"
                fullWidth
                data-cy="automation-prompt-desc-text-field"
            />
            {checkboxes.map(checkbox => (<Controller
                key={checkbox.name}
                control={control}
                name={checkbox.name}
                render={({ field }) => (
                    <FormControlLabel
                        {...field}
                        disabled={isUploading}
                        control={
                            <Checkbox
                                checked={field.value}
                                data-cy={checkbox.dataCy}
                            />
                        }
                        label={
                            <UiStack
                                direction='row'
                                align='center'
                                gap={SpacingToken.Micro}>
                                <UiText>
                                    {translate({ id: checkbox.labelId })}
                                </UiText>
                                <ApTooltip
                                    content={translate({ id: checkbox.tooltipLabelId })}
                                    placement='right'>
                                    <PortalIcon
                                        name='info'
                                        variant='outlined' />
                                </ApTooltip>
                            </UiStack>
                        }
                    />
                )}
            />))}
        </UiStack>;
    }, [ control, isUploading, register, translate ]);

    const paginatedTableData = useMemo(() =>
        argumentData.slice(pagination.skip, pagination.skip + pagination.top),
    [ pagination.skip, pagination.top, argumentData ]);

    const createParameterTable = useCallback(() => (
        <ApDataGridWrapper<AutomationArgument>
            data={paginatedTableData}
            selectable={false}
            loading={isFetchingAutomation || isUploading}
            onChange={onGridChange}
            dataCy="automation-properties-grid"
        >
            <ApDataGridColumn<AutomationArgument>
                property="name"
                title={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_NAME' })}
                description={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_NAME_DESCRIPTION' })}
                width={50}
                primary
                searchable
                sortable
            />
            <ApDataGridColumn<AutomationArgument>
                property="displayName"
                title={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_DISPLAY_NAME' })}
                description={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_DISPLAY_NAME_DESCRIPTION' })}
                width={50}
            />
            <ApDataGridColumn<AutomationArgument>
                property="description"
                title={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_DESC_USER' })}
                description={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_DESC_USER_DESCRIPTION' })}
                width={70}
            />
            <ApDataGridColumn<AutomationArgument>
                property="promptDescription"
                title={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_DESC_AUTOPILOT' })}
                description={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_DESC_AUTOPILOT_DESCRIPTION' })}
                width={70}
            />
            <ApDataGridColumn<AutomationArgument>
                property="options"
                title={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_DROPDOWN_OPTIONS' })}
                description={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_DROPDOWN_OPTIONS_DESCRIPTION' })}
                width={40}
            />
            <ApDataGridColumn<AutomationArgument>
                property="hidden"
                title={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_HIDDEN' })}
                description={translate({ id: 'A4E_AUTOMATION_PROPERTIES_TABLE_HEADER_HIDDEN_DESCRIPTION' })}
                width={20}
                render={row => (<ApCheckbox
                    checked={row.hidden}
                    disabled />)} />
            <ApDataGridRowActions>
                <ApDataGridRowButton<AutomationArgument>
                    id='edit'
                    label={translate({ id: 'CLIENT_EDIT' })}
                    icon='edit'
                    onClick={(row) => onEditAutomationArgumentRow(row)}
                    dataCy='action-edit-automation-argument-row'
                />
            </ApDataGridRowActions>
            <ApDataGridFooter
                length={argumentData.length}
                pageSizes={[ 5, 10, 25, 50 ]}
            />
        </ApDataGridWrapper>
    ), [ argumentData, isFetchingAutomation, isUploading, onEditAutomationArgumentRow, translate ]);
    const isAutomationReady = selectedAutomation && fullAutomationData && !isFetchingAutomation && !isFetchingAutomations;
    return (
        <UiAccordion
            titleTranslationCode="A4E_AUTOMATION_PROPERTIES"
            expandedByDefault={false}
        >
            <UiStack
                direction='column'
                gap={SpacingToken.S}>
                <>
                    <UiStack
                        direction='row'
                        align='end'
                        gap={SpacingToken.M}
                        pl={SpacingToken.Micro}
                        pr={SpacingToken.Micro}
                    >
                        {createFolderDropdown()}
                        {createAutomationDropdown ()}
                    </UiStack>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={autopilotEnabledOnly}
                                onChange={(e) => setAutopilotEnabledOnly(e.target.checked)}
                                data-cy="autopilot-enabled-only-checkbox"
                            />
                        }
                        label={translate({ id: 'A4E_AUTOMATION_PROPERTIES_AUTOPILOT_ENABLED_ONLY' })}
                        disabled={!orchestratorFolder || isFetchingAutomations || isFetchingAutomation}
                    />
                </>
                { isAutomationReady && (<>
                    {createAutomationCheckboxes()}
                    {argumentData?.length !== 0 && createParameterTable()}
                    <UiStack
                        direction='row'
                        justify='end'
                        gap={SpacingToken.XS}
                    >
                        <ApButton
                            label={translate({ id: 'A4E_CUSTOM_TAB_RESET' })}
                            variant='tertiary'
                            type="reset"
                            data-cy="reset-button"
                            onClick={handleReset}
                            disabled={isUploading}
                        />
                        <ApButton
                            label={translate({ id: 'A4E_CUSTOM_TAB_SAVE' })}
                            variant="primary"
                            type="submit"
                            onMouseDown={(e: any) => e.preventDefault()}
                            data-cy="save-button"
                            onClick={handleSubmit(onSubmit)}
                            disabled={isUploading}
                            loading={isUploading}
                        />
                    </UiStack>
                </>)}
                <A4EAutomationPropertiesEditComponent
                    automationPropertiesDrawerParams={AutomationPropertiesDrawerParams}
                    setAutomationPropertiesDrawerParams={setAutomationPropertiesDrawerParams}
                    setArgumentData={setArgumentData}
                />
            </UiStack>
        </UiAccordion>
    );
};

export default A4EAutomationPropertiesComponent;
