import dayjs from 'dayjs';

import { TenantService } from '../AuditTabServices/TenantService';

interface ActionByProd {
    year: number;
    month: number;
    totalAction: number;
    product: string;
    tenantId: string | null;
}

export async function getTenantAndProductOptions(data: ActionByProd[], selectedTenant: string, selectedProduct: string) {
    const distinctTenants = Array.from(new Set(data?.map(item => item.tenantId))) || [];
    const distinctProducts = Array.from(new Set(data?.map(item => item.product))) || [];

    const tenantDict = TenantService.getTenantCache();
    const tenantIdToNameMap: Record<string, string> = {};
    const formattedTenants = await Promise.all(
        distinctTenants.map(async (tenantId) => {
            const tenantName = await TenantService.fetchAndUpdateTenant(tenantId ?? '', tenantDict);
            tenantIdToNameMap[tenantId ?? 'unknown'] = tenantName || 'No tenant';
            return {
                tenantId,
                tenantName: tenantName || 'No tenant',
            };
        })
    );

    const tenantOptions = [
        {
            label: 'All',
            value: 'all',
        },
        ...formattedTenants.map(({
            tenantId, tenantName,
        }) => ({
            label: tenantName || 'No tenant',
            value: tenantId || 'No tenant',
        })),
    ];

    const productOptions = [
        {
            label: 'All',
            value: 'all',
        },
        ...distinctProducts.map(product => ({
            label: product,
            value: product,
        })),
    ];

    return {
        tenantOptions,
        productOptions,
        tenantIdToNameMap,
    };
}

// Function to get months for the chart
export function getMonths(): dayjs.Dayjs[] {
    const months: dayjs.Dayjs[] = [];
    for (let i = 5; i >= 0; i--) {
        const date = dayjs().subtract(i, 'month')
            .startOf('month');
        months.push(date);
    }
    return months;
}

// Function to calculate tenant or product actions
export function calculateTenantAndProductActions(
    data: ActionByProd[],
    months: dayjs.Dayjs[],
    selectedTenant: string,
    selectedProduct: string
) {
    const monthlyTenantActions: { [key: string]: { [tenant: string]: number } } = {};
    const monthlyProductActions: { [key: string]: { [product: string]: number } } = {};

    months.forEach(month => {
        const key = `${month.year()}-${month.month() + 1}`;
        monthlyTenantActions[key] = {};
        monthlyProductActions[key] = {};
    });

    const filteredData = data?.filter(item => {
        const matchesTenant = selectedTenant === 'all' || item.tenantId === selectedTenant;
        const matchesProduct = selectedProduct === 'all' || item.product === selectedProduct;
        return matchesTenant && matchesProduct;
    });

    return {
        monthlyTenantActions,
        monthlyProductActions,
        filteredData,
    };
}

export function groupChartData(
    filteredData: ActionByProd[],
    months: dayjs.Dayjs[],
    monthlyActions: { [key: string]: { [name: string]: number } },
    setChartData: (data: number[][]) => void,
    setLabels: (labels: string[]) => void,
    setNames: (names: string[]) => void,
    entityKey: 'tenantId' | 'product',
    defaultNames: string[]
) {
    filteredData?.forEach(item => {
        const key = `${item.year}-${item.month}`;
        const entity = item[entityKey] ?? 'unknown';
        if (!monthlyActions[key][entity]) {
            monthlyActions[key][entity] = 0;
        }
        monthlyActions[key][entity] += item.totalAction;
    });

    const groupedChartData: number[][] = [];
    const monthLabels: string[] = [];
    const topNames: string[] = [];

    months.forEach(month => {
        const key = `${month.year()}-${month.month() + 1}`;
        const actions = monthlyActions[key];

        const sortedEntities = Object.entries(actions)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 3);

        const topActions = [ 0, 0, 0 ];
        sortedEntities.forEach(([ name, count ], index) => {
            topActions[index] = count;
            if (!topNames[index]) {
                topNames[index] = name;
            }
        });

        groupedChartData.push(topActions);
        monthLabels.push(month.format('MMM'));
    });

    setChartData(groupedChartData);
    setLabels(monthLabels);
    setNames(topNames.length ? topNames : defaultNames);
}
