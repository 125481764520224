import type { Tenant } from '@uipath/portal-shell/dist/types/models/tenant';
import type { AxiosError } from 'axios';

import type {
    ContextGroundingIndexes,
    LaunchAcknowledgementConfigInterface,
    TenantConfiguration,
    TenantUIConfigInterface,
} from '../../../../services/a4e/A4EServiceTypes';
import {
    readTenantSettingsFromAutopilot as readTenantSettings,
    uploadTenantSettingsToAutopilot as uploadTenantSettings,
} from './A4EContextGroundingUtils';
import { A4EErrorMessage } from './A4EErrorType';

export const advancedSettingsTenantConfigDefaultValues: IA4EAdvancedSettingsConfiguration = {
    fileUploadEnabled: 'false',
    userNotesEnabled: 'false',
    sendFeedbackToUiPathEnabled: 'true',
    suggestedPromptsEnabled: 'true',
    administratorCorePromptNotesEnabled: 'false',
    administratorCorePromptNotes: '',
    administratorSuggestedFollowUpsPromptNotesEnabled: 'false',
    administratorSuggestedFollowUpsPromptNotes: '',
    chatHistoryEnabled: 'false',
    autoSearchECSIndex: '',
    autoSearchECSEnabled: 'false',
    ecsNumberOfSearchResultsEnabled: 'false',
    ecsNumberOfSearchResults: '5',
    chatHistoryVisibilityDays: '0',
    launchAcknowledgementConfigEnabled: 'false',
    launchAcknowledgementConfig: {
        headerText: '',
        messageImageURL: '',
        messageText: '',
        acknowledgementButtonText: '',
    },
    tenantUIConfigEnabled: 'false',
    tenantUIConfig: {
        appBar: {
            lightModeBackgroundColor: '',
            darkModeBackgroundColor: '',
            darkModeTextColor: 'f8f9fa',
            lightModeTextColor: '182027',
            coBrandingText: '',
        },
        startingScreen: { logoURL: '' },
    },
};

export interface A4EAdvancedSettingsComponentProps {
    selectedTenant: Tenant;
    autopilotFolderId?: number;
}

type A4EAdvanceSettingBoolean = 'true' | 'false';

export interface IA4EAdvancedSettingsConfiguration {
    tenantUIConfigEnabled?: A4EAdvanceSettingBoolean;
    tenantUIConfig?: TenantUIConfigInterface;
    launchAcknowledgementConfigEnabled?: A4EAdvanceSettingBoolean;
    launchAcknowledgementConfig?: LaunchAcknowledgementConfigInterface;
    fileUploadEnabled?: A4EAdvanceSettingBoolean;
    userNotesEnabled?: A4EAdvanceSettingBoolean;
    sendFeedbackToUiPathEnabled?: A4EAdvanceSettingBoolean;
    suggestedPromptsEnabled?: A4EAdvanceSettingBoolean;
    administratorCorePromptNotesEnabled?: A4EAdvanceSettingBoolean;
    administratorCorePromptNotes?: string;
    administratorSuggestedFollowUpsPromptNotesEnabled?: A4EAdvanceSettingBoolean;
    administratorSuggestedFollowUpsPromptNotes?: string;
    chatHistoryEnabled?: A4EAdvanceSettingBoolean;
    autoSearchECSIndex?: string;
    autoSearchECSEnabled?: A4EAdvanceSettingBoolean;
    ecsNumberOfSearchResults?: string;
    ecsNumberOfSearchResultsEnabled?: A4EAdvanceSettingBoolean;
    chatHistoryVisibilityDays?: string;
    [key: string]: any;
}

export const uploadTenantSettingsToAutopilot = async ({
    folderId,
    organizationName,
    tenantName,
    autopilotTenantConfig,
    contextGroundingData,
}: {
    folderId: string;
    organizationName: string;
    tenantName: string;
    autopilotTenantConfig?: TenantConfiguration;
    contextGroundingData?: ContextGroundingIndexes[];
}) => {
    try {
        return await uploadTenantSettings({
            folderId,
            organizationName,
            tenantName,
            autopilotTenantConfig,
            contextGroundingData: contextGroundingData ?? [],
        });
    } catch (e) {
        throw new A4EErrorMessage('A4E_ADVANCED_SETTINGS_ERROR_SAVE', (e as AxiosError).code);
    }
};

export const readTenantSettingsFromAutopilot = async ({
    folderId,
    organizationName,
    tenantName,
}: {
    folderId: string;
    organizationName: string;
    tenantName: string;
}) => {
    try {
        return await readTenantSettings({
            folderId,
            organizationName,
            tenantName,
        });
    } catch (e) {
        throw new A4EErrorMessage('A4E_ADVANCED_SETTINGS_ERROR_LOAD_TENANT_SETTINGS', (e as AxiosError).code);
    }
};

export const buildAutopilotTenantConfig = (data: IA4EAdvancedSettingsConfiguration, autopilotTenantConfig: TenantConfiguration) => {
    const objToSave: TenantConfiguration = {} as TenantConfiguration;
    if (data.tenantUIConfigEnabled === 'true') {
        const tenantUIConfig = { ...data.tenantUIConfig };
        if (tenantUIConfig?.appBar) {
            tenantUIConfig.appBar.lightModeBackgroundColor = `#${tenantUIConfig?.appBar?.lightModeBackgroundColor}`;
            tenantUIConfig.appBar.darkModeBackgroundColor = `#${tenantUIConfig?.appBar?.darkModeBackgroundColor}`;
            tenantUIConfig.appBar.darkModeTextColor = `#${tenantUIConfig?.appBar?.darkModeTextColor}`;
            tenantUIConfig.appBar.lightModeTextColor = `#${tenantUIConfig?.appBar?.lightModeTextColor}`;
        }
        objToSave.tenantUIConfig = tenantUIConfig;
    }
    if (data.launchAcknowledgementConfigEnabled === 'true') {
        objToSave.launchAcknowledgementConfig = data.launchAcknowledgementConfig;
        if (objToSave.launchAcknowledgementConfig?.acknowledgementButtonText?.trim() === '') {
            objToSave.launchAcknowledgementConfig.acknowledgementButtonText = 'Acknowledge';
        }
    }
    if (autopilotTenantConfig?.userDepartmentOptions) {
        objToSave.userDepartmentOptions = autopilotTenantConfig.userDepartmentOptions;
    }
    objToSave.fileUploadEnabled = data.fileUploadEnabled === 'true';
    objToSave.userNotesEnabled = data.userNotesEnabled === 'true';
    objToSave.sendFeedbackToUiPathEnabled = data.sendFeedbackToUiPathEnabled === 'true';
    objToSave.hideSuggestedPrompts = data.suggestedPromptsEnabled === 'false';

    if (data.administratorCorePromptNotesEnabled === 'true') {
        objToSave.administratorCorePromptNotes = data.administratorCorePromptNotes ?? '';
    }
    if (data.administratorSuggestedFollowUpsPromptNotesEnabled === 'true' && data.suggestedPromptsEnabled === 'true') {
        objToSave.administratorSuggestedFollowUpsPromptNotes = data.administratorSuggestedFollowUpsPromptNotes ?? '';
    }
    if (data.chatHistoryEnabled === 'true') {
        objToSave.chatHistoryVisibilityDays = Number(data.chatHistoryVisibilityDays);
    }
    if (data.autoSearchECSEnabled === 'true') {
        objToSave.autoSearchECSEnabled = true;
        objToSave.autoSearchECSIndex = data.autoSearchECSIndex;
    } else {
        objToSave.autoSearchECSEnabled = false;
        objToSave.autoSearchECSIndex = '';
    }
    if (data.ecsNumberOfSearchResultsEnabled === 'true') {
        objToSave.ecsNumberOfSearchResults = Number(data.ecsNumberOfSearchResults);
    }
    objToSave.contextGroundingIndexes = autopilotTenantConfig?.contextGroundingIndexes ?? [];
    return objToSave;
};

export const getDefaultValues = (autopilotTenantConfig: TenantConfiguration): IA4EAdvancedSettingsConfiguration => {
    const defaultValues = {} as IA4EAdvancedSettingsConfiguration;
    // values that are actually stored in the autopilot tenant config
    defaultValues.fileUploadEnabled = determineDefaultBooleanValue(autopilotTenantConfig?.fileUploadEnabled, 'false');
    defaultValues.userNotesEnabled = determineDefaultBooleanValue(autopilotTenantConfig?.userNotesEnabled, 'false');
    defaultValues.sendFeedbackToUiPathEnabled = determineDefaultBooleanValue(autopilotTenantConfig?.sendFeedbackToUiPathEnabled, 'true');
    defaultValues.suggestedPromptsEnabled = determineDefaultBooleanValue(!autopilotTenantConfig?.hideSuggestedPrompts, 'true');
    defaultValues.administratorCorePromptNotes = autopilotTenantConfig?.administratorCorePromptNotes ?? '';
    defaultValues.administratorSuggestedFollowUpsPromptNotes = autopilotTenantConfig?.administratorSuggestedFollowUpsPromptNotes ?? '';
    defaultValues.autoSearchECSEnabled = determineDefaultBooleanValue(autopilotTenantConfig?.autoSearchECSEnabled, 'false');
    defaultValues.ecsNumberOfSearchResults = autopilotTenantConfig?.ecsNumberOfSearchResults?.toString() ?? '5';
    defaultValues.autoSearchECSIndex = autopilotTenantConfig?.autoSearchECSIndex ?? '';
    defaultValues.chatHistoryVisibilityDays = autopilotTenantConfig?.chatHistoryVisibilityDays?.toString() ?? '0';
    defaultValues.launchAcknowledgementConfig = autopilotTenantConfig?.launchAcknowledgementConfig ?? {
        headerText: '',
        messageImageURL: '',
        messageText: '',
        acknowledgementButtonText: '',
    };
    defaultValues.tenantUIConfig = initializeTenantUIConfig(autopilotTenantConfig);
    // values that are not stored in the autopilot tenant config - strictly for the UI
    defaultValues.tenantUIConfigEnabled = autopilotTenantConfig?.tenantUIConfig ? 'true' : 'false';
    defaultValues.launchAcknowledgementConfigEnabled = autopilotTenantConfig?.launchAcknowledgementConfig ? 'true' : 'false';
    defaultValues.administratorCorePromptNotesEnabled = autopilotTenantConfig?.administratorCorePromptNotes ? 'true' : 'false';
    // this should be disabled if hideSuggestedPrompts exists and is true
    defaultValues.administratorSuggestedFollowUpsPromptNotesEnabled =
    (autopilotTenantConfig?.administratorSuggestedFollowUpsPromptNotes && !autopilotTenantConfig?.hideSuggestedPrompts) ? 'true' : 'false';
    defaultValues.chatHistoryEnabled = autopilotTenantConfig?.chatHistoryVisibilityDays === undefined ? 'false' : 'true';
    defaultValues.ecsNumberOfSearchResultsEnabled = autopilotTenantConfig?.ecsNumberOfSearchResults ? 'true' : 'false';
    return defaultValues;
};

const determineDefaultBooleanValue = (value: boolean | string | undefined, defaultValue: A4EAdvanceSettingBoolean): A4EAdvanceSettingBoolean => {
    if (value === undefined) {
        return defaultValue;
    }
    return value ? 'true' : 'false';
};

export const initializeTenantUIConfig = (autopilotTenantConfig: TenantConfiguration): TenantUIConfigInterface => ({
    appBar: {
        lightModeBackgroundColor: autopilotTenantConfig?.tenantUIConfig?.appBar?.lightModeBackgroundColor?.replace('#', '') ?? '',
        darkModeBackgroundColor: autopilotTenantConfig?.tenantUIConfig?.appBar?.darkModeBackgroundColor?.replace('#', '') ?? '',
        darkModeTextColor: autopilotTenantConfig?.tenantUIConfig?.appBar?.darkModeTextColor?.replace('#', '') ?? 'f8f9fa',
        lightModeTextColor: autopilotTenantConfig?.tenantUIConfig?.appBar?.lightModeTextColor?.replace('#', '') ?? '182027',
        coBrandingText: autopilotTenantConfig?.tenantUIConfig?.appBar?.coBrandingText ?? '',
    },
    startingScreen: { logoURL: autopilotTenantConfig?.tenantUIConfig?.startingScreen?.logoURL ?? '' },
});
