import type { IBlob } from '@experiences/interfaces/src/blob';
import type { AxiosError } from 'axios';

import {
    getBlob,
    post,
} from '../utility/Requests.default';
import type {
    A4EErrorResponse,
    AutopilotSolution,
} from './A4EServiceTypes';
import { A4EError } from './A4EServiceTypes';

export async function getLatestAutopilotPackageVersion(tenantName: string): Promise<AutopilotSolution> {
    try {
        return await post<AutopilotSolution>(`/${tenantName}/autopilotstudio_/a4e/api/solution`, { stripPortalPath: true });
    } catch (error) {
        const axiosError = error as AxiosError;
        throw a4eErrorFromResponse(axiosError);
    }
}

export async function getLatestAutopilotPackageUrl(tenantName: string, version: string): Promise<string> {
    try {
        return await post<string>(`/${tenantName}/autopilotstudio_/a4e/api/solution/url/${version}`, { stripPortalPath: true });
    } catch (error) {
        const axiosError = error as AxiosError;
        throw a4eErrorFromResponse(axiosError);
    }
}

export async function getAutopilotPackage(url: string): Promise<IBlob> {
    try {
        return await getBlob(url, {
            stripPortalPath: true,
            ignoreBasePath: true,
            includeCredentials: false,
            headers: {},
        });
    } catch (error) {
        const axiosError = error as AxiosError;
        throw a4eErrorFromResponse(axiosError);
    }
}

export function a4eErrorFromResponse(axiosError: AxiosError): A4EError {
    const statusCode = axiosError.response?.status ?? 500;
    const responseData: any | undefined = axiosError.response?.data;
    if (responseData) {
        const data = responseData as A4EErrorResponse;
        return new A4EError(statusCode, [ data.error ], [ data.error_code ]);
    }
    return new A4EError(statusCode, [ axiosError.message ], []);
}
