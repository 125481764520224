import buildQuery from 'odata-query';

import type { ODataQueryOptions } from '../notifications';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests.default';
import type { Tag } from './OrchReleaseService';

export interface BucketDto {
    Name: string;
    Description?: string;
    Identifier: string;
    StrorageProvider?: string;
    StrorageParameters?: string;
    StorageContainer?: string;
    Options?: string;
    CredentialStoreId?: string;
    ExternalName?: string;
    Password?: string;
    FoldersCount?: number;
    Tags?: Tag[];
    Id?: number;
}
interface BucketDtos {
    value: BucketDto[];
}

interface BlobFileAccessOdataDto {
    Uri: string;
    Verb: string;
    RequiresAuth: boolean;
    Headers: {
        Keys: string[];
        Values: string[];
    };
}

interface AccesibleFoldersDto {
    AccessibleFolders: SimpleFolderDto[];
    TotalFoldersCount: number;
}

export interface SimpleFolderDto {
    DisplayName: string;
    FullyQualifiedName: string;
    Id: number;
}

interface BlobFileDtos {
    value: BlobFileDto[];
}

interface BlobFileDto {
    FullPath: string;
    ContentType: string;
    Size: number;
    IsDirectory: boolean;
    Id: string;
}

export interface BlobFileAccessDto {
    uri: string;
    verb: string;
    requiresAuth: boolean;
    headers: {
        keys: string[];
        values: string[];
    };
}

const ORCH_URL = '/api/orchestrator/buckets';

// System Bucket API
export async function getBucketReadUri(urlParams: { accountLogicalName: string; tenantName: string; folderId: string; bucketName: string; fileName: string }) {
    const {
        accountLogicalName, tenantName, folderId, bucketName, fileName,
    } = urlParams;
    return await get<BlobFileAccessDto>(`${ORCH_URL}/systemBucket/readUri/${accountLogicalName}/${tenantName}/${bucketName}/${folderId}?fileName=${fileName}&expiryInMinutes=1`);
}

export async function getFileFromSystemBucket(body: { readUri: BlobFileAccessDto; folderId: string }) {
    const {
        readUri, folderId,
    } = body;

    return await post(
        `${ORCH_URL}/systemBucket/read`,
        {
            body: { readUri: readUri.uri },
            headers: {
                extendRequestHeaders: 'true',
                'x-uipath-organizationunitid': folderId,
            },
        }
    );
}

export async function getBucketWriteUri(urlParams: { accountLogicalName: string; tenantName: string; folderId: string; bucketName: string; fileName: string }) {
    const {
        accountLogicalName, tenantName, folderId, bucketName, fileName,
    } = urlParams;
    return await get<BlobFileAccessDto>(`${ORCH_URL}/systemBucket/writeUri/${accountLogicalName}/${tenantName}/${bucketName}/${folderId}?fileName=${fileName}&expiryInMinutes=1`,);
}

export async function uploadFileToSystemBucket(body: { writeUri: BlobFileAccessDto; folderId: string; file: any }) {
    const {
        file, writeUri, folderId,
    } = body;
    const keys = writeUri.headers?.keys || [];
    const values = writeUri.headers?.values || [];
    const headers = keys.reduce<Record<string, string>>((acc, key, index) => {
        acc[key] = values[index];
        return acc;
    }, { 'x-uipath-organizationunitid': folderId });

    return await put(
        `${ORCH_URL}/systemBucket/write`,
        {
            body: {
                file,
                writeUri: writeUri.uri,
                headersToInclude: Object.keys(headers),
            },
            headers: {
                extendRequestHeaders: 'true',
                ...headers,
            },
        }
    );
}

// User Bucket API
export async function getOdataBucketReadUri(urlParams: { accountLogicalName: string; tenantName: string; folderId: string; bucketId: string; fileName: string }) {
    const {
        accountLogicalName, tenantName, folderId, bucketId, fileName,
    } = urlParams;
    return await get<BlobFileAccessOdataDto>(`${ORCH_URL}/readUri/${accountLogicalName}/${tenantName}/${bucketId}/${folderId}?fileName=${fileName}&expiryInMinutes=1`);
}

export async function getFileFromBucket(body: { readUri: BlobFileAccessOdataDto; folderId: string }) {
    const {
        readUri, folderId,
    } = body;

    return await post(
        `${ORCH_URL}/read`,
        {
            body: { readUri: readUri.Uri },
            headers: {
                extendRequestHeaders: 'true',
                'x-uipath-organizationunitid': folderId,
            },
        }
    );
}

export async function getOdataBucketWriteUri(urlParams: { accountLogicalName: string; tenantName: string; folderId: string; bucketId: string; fileName: string }) {
    const {
        accountLogicalName, tenantName, folderId, bucketId, fileName,
    } = urlParams;
    return await get<BlobFileAccessOdataDto>(`${ORCH_URL}/writeUri/${accountLogicalName}/${tenantName}/${bucketId}/${folderId}?fileName=${fileName}&expiryInMinutes=1`);
}

export async function uploadFileToBucket(body: { writeUri: BlobFileAccessOdataDto; folderId: string; file: any }) {
    const {
        file, writeUri, folderId,
    } = body;
    const keys = writeUri.Headers?.Keys || [];
    const values = writeUri.Headers?.Values || [];
    const headers = keys.reduce<Record<string, string>>((acc, key, index) => {
        acc[key] = values[index];
        return acc;
    }, { 'x-uipath-organizationunitid': folderId });

    return await put(
        `${ORCH_URL}/write`,
        {
            body: {
                file,
                writeUri: writeUri.Uri,
                headersToInclude: Object.keys(headers),
            },
            headers: {
                extendRequestHeaders: 'true',
                ...headers,
            },
        }
    );
}

export async function getFoldersForBucket(urlParams: { accountLogicalName: string; tenantName: string; bucketId: string }) {
    const {
        accountLogicalName, tenantName, bucketId,
    } = urlParams;
    return await get<AccesibleFoldersDto>(`${ORCH_URL}/getFoldersForBucket/${accountLogicalName}/${tenantName}/bucket/${bucketId}`);
}

export async function getBucketsForFolder(
    urlParams: { accountLogicalName: string; tenantName: string; folderId: string },
    queryOptions: ODataQueryOptions,
) {
    const {
        accountLogicalName, tenantName, folderId,
    } = urlParams;
    return await get<BucketDtos>(`${ORCH_URL}/getBucketsForFolder/${accountLogicalName}/${tenantName}/folder/${folderId}${buildQuery(queryOptions)}`);
}

export async function getFilesFromBucket(urlParams: { accountLogicalName: string; tenantName: string; bucketId: string; folderId: string }) {
    const {
        accountLogicalName, tenantName, bucketId, folderId,
    } = urlParams;
    return await get<BlobFileDtos>(`${ORCH_URL}/getFiles/${accountLogicalName}/${tenantName}/${bucketId}/${folderId}`);
}

export async function getBucketsAcrossFolders(
    urlParams: { accountLogicalName: string; tenantName: string },
    queryParams: ODataQueryOptions,
) {
    const {
        accountLogicalName, tenantName,
    } = urlParams;
    return await get<BucketDtos>(`${ORCH_URL}/getBucketsAcrossFolders/${accountLogicalName}/${tenantName}${buildQuery(queryParams)}`);
}

export async function deleteBucket(urlParams: { accountLogicalName: string; tenantName: string; bucketId: string; folderId: string }) {
    const {
        accountLogicalName, tenantName, bucketId, folderId,
    } = urlParams;
    return axiosDelete(`${ORCH_URL}/${accountLogicalName}/${tenantName}/${bucketId}/${folderId}`);
}

export async function createBucket(
    urlParams: { accountLogicalName: string; tenantName: string; folderId: string },
    body: BucketDto
) {
    const {
        accountLogicalName, tenantName, folderId,
    } = urlParams;
    return await post(
        `${ORCH_URL}/${accountLogicalName}/${tenantName}`,
        {
            body,
            headers: {
                extendRequestHeaders: 'true',
                'x-uipath-organizationunitid': folderId,
            },
        }
    );
}
