import buildQuery from 'odata-query';

import type { ODataQueryOptions } from '../notifications';
import {
    get,
    put,
} from '../utility/Requests.default';

const ORCH_URL = '/api/orchestrator/releases';

export type Tag = {
    DisplayName: string;
    DisplayValue: string;
    Name: string;
    Value: string;
};

export type Release = {
    Id: number;
    Name: string;
    Tags: Tag[];
    Arguments: {
        Input: string;
        Output: string;
    };
};

type ReleaseObject = {
    '@odata.count': number;
    '@odata.context': string;
    value: Release[];
};

export async function getReleasesForCurrentFolder(
    urlParams: { accountLogicalName: string; tenantName: string; folderId: string },
    queryOptions: ODataQueryOptions
) {
    const {
        accountLogicalName, tenantName, folderId,
    } = urlParams;
    const endpoint = `${ORCH_URL}/${accountLogicalName}/${tenantName}/${folderId}${buildQuery(queryOptions)}`;

    return await get<ReleaseObject>(endpoint);
}

export async function getReleaseById(urlParams: { accountLogicalName: string; tenantName: string; folderId: string; processId: string }) {
    const {
        accountLogicalName, tenantName, folderId, processId,
    } = urlParams;
    return await get<Release>(`${ORCH_URL}/${accountLogicalName}/${tenantName}/${folderId}/${processId}`);
}

export async function updateRelease(urlParams: { accountLogicalName: string; tenantName: string; folderId: string; processId: string }, release: any) {
    const {
        accountLogicalName, tenantName, folderId, processId,
    } = urlParams;
    const headers = { 'x-uipath-organizationunitid': folderId };
    return await put<void>(
        `${ORCH_URL}/${accountLogicalName}/${tenantName}/${processId}`,
        {
            body: {
                release,
                headersToInclude: Object.keys(headers),
            },
            headers: {
                extendRequestHeaders: 'true',
                ...headers,
            },
        }
    );
}
