import { getFormattedDate } from '@experiences/util';

import { getNextAuditEvents } from '../../../services/audit/AuditService';

const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm';

export function getNextEvents(start: number, nextUrl?: string | null) {
    return nextUrl ?
        getNextAuditEvents(nextUrl, start) :
        null ;
}

export function getSuggestValue(value: string[]) {
    return value?.map(s => ({
        id: s,
        text: s,
    }));
}

export function getSearchSourceFactory(search?: string, inputs?: Array<{ name: string; id: string }>) {
    const uniqueInputs = [ ...new Set(inputs) ];

    return Promise.resolve({
        data: uniqueInputs.filter(input => search ? input?.name.includes(search) : true).map((s, i) => ({
            id: s.id,
            value: s.id,
            text: s.name,
        })),
        total: uniqueInputs.filter(input => search ? input?.name.includes(search) : true).length,
    });
}

export function getDateFilterString(from?: Date, to?: Date) {
    return from && to ?
        {
            id: 'CLIENT_DATE_CREATED_FROM_TO',
            args: {
                0: getFormattedDate(from, DATE_TIME_FORMAT),
                1: getFormattedDate(to, DATE_TIME_FORMAT),
            },
        } :
        { id: 'CLIENT_DATE_CREATED' };
}
