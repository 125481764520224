import { accountLogicalName } from '@experiences/ecommerce';
import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import { useInterval } from '@experiences/util';
import { useTheme } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import type { Tenant } from '@uipath/portal-shell/dist/types/models/tenant';
import {
    ApButton,
    ApLink,
    PortalDivider,
    PortalIcon,
} from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../../common/constants/Constant';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import { type A4EError } from '../../../../services/a4e/A4EServiceTypes';
import type { SolutionStatus } from '../utils/A4ESolutionUtils';
import {
    deployAutopilot,
    getAutopilotDeploymentStatus,
    getAutopilotPipelineDeploymentStatus,
    getAutopilotSolutionStatus,
    SolutionDeploymentState,
    uninstallAutopilotDeployment,
} from '../utils/A4ESolutionUtils';
import { A4ETenantDropdown } from './A4ETenantDropdown';

interface A4ESolutionComponentProps {
    onDeploymentStateChange: (newState: SolutionDeploymentState) => void;
    tenant: Tenant | undefined;
    setTenant: (tenant: Tenant | undefined) => void;
    tenantId?: string; // Used only for testing
}

const A4ESolutionComponent: React.FC<A4ESolutionComponentProps> = ({
    tenantId, onDeploymentStateChange, tenant, setTenant,
}) => {
    const createNotification = useUiSnackBar();
    const { formatMessage: translate } = useIntl();
    const theme = useTheme();

    const organizationName = useSelector(accountLogicalName);
    const [ tenants, setTenants ] = useState<Tenant[]>([]);
    const [ solutionStatus, setSolutionStatus ] = useState<SolutionStatus>({ deploymentState: SolutionDeploymentState.NONE });
    const [ isDeploymentInProgress, setIsDeploymentInProgress ] = useState<boolean>(false);

    const createGenericErrorNotification = useCallback((messageId: string, error: A4EError) => {
        error.errorMessages.forEach((errorMessage) => {
            createNotification(
                `${translate({ id: messageId })} - ${errorMessage}`,
                notificationType.ERROR,
            );
        });
    }, [ createNotification, translate ]);

    useEffect(() => {
        const availableTenants = window.PortalShell?.AccountAndTenants.getCurrentAccountAndTenantsInfo().tenants ?? [];
        setTenants(availableTenants);
        if (tenantId) {
            setSolutionStatus({ deploymentState: SolutionDeploymentState.NONE });
            setTenant(availableTenants.find(t => t.id === tenantId));
        }
    }, [ setTenant, tenantId ]);

    useEffect(() => {
        onDeploymentStateChange(solutionStatus.deploymentState ?? SolutionDeploymentState.NONE);
    }, [ solutionStatus.deploymentState, onDeploymentStateChange ]);

    useEffect(() => {
        const checkIfAutopilotDeployed = async () => {
            if (tenant?.name) {
                try {
                    const status = await getAutopilotSolutionStatus(organizationName, tenant.name);
                    if (status.deploymentState === SolutionDeploymentState.INSTALL_ERROR) {
                        createNotification(translate({ id: 'A4E_SOLUTION_ERROR_FOLDER' }), notificationType.ERROR);
                    }
                    setSolutionStatus(status);
                } catch (error) {
                    createGenericErrorNotification('A4E_SOLUTION_ERROR_CHECK', error as A4EError);

                }
            } else {
                setSolutionStatus({ deploymentState: SolutionDeploymentState.NOT_INSTALLED });
            }
        };
        checkIfAutopilotDeployed();
    }, [ createGenericErrorNotification, createNotification, organizationName, tenant?.name, translate ]);

    useEffect(() => {
        const isInProgress =
    tenant?.name &&
        solutionStatus.deploymentState === SolutionDeploymentState.INSTALLING ||
        solutionStatus.deploymentState === SolutionDeploymentState.UPDATING ||
        solutionStatus.deploymentState === SolutionDeploymentState.UNINSTALLING;
        setIsDeploymentInProgress(isInProgress);
    }, [ solutionStatus.deploymentState, tenant?.name ]);

    useInterval(
        async () => {
            try {
                let status: SolutionDeploymentState | undefined = solutionStatus.deploymentState;
                let shouldRefreshEntireStatus = false;
                if (tenant?.name && solutionStatus.pipelineDeploymentId) {
                    status = await getAutopilotPipelineDeploymentStatus(tenant.name, solutionStatus.pipelineDeploymentId);
                    // If the deployment is finished, clear the pipelineDeploymentId
                    if (status === SolutionDeploymentState.INSTALLED) {
                        createNotification(
                            translate({ id: 'A4E_SOLUTION_PROGRESS_INSTALLED' }),
                            notificationType.SUCCESS,
                        );
                        shouldRefreshEntireStatus = true;
                    }
                } else if (tenant?.name && solutionStatus.instanceId) {
                    status = await getAutopilotDeploymentStatus(tenant.name, solutionStatus.instanceId);
                    // If the deployment is finished, clear the instanceId
                    if (status === SolutionDeploymentState.INSTALLED || status === SolutionDeploymentState.NOT_INSTALLED) {
                        const statusMessageId = status === SolutionDeploymentState.INSTALLED ? 'A4E_SOLUTION_PROGRESS_INSTALLED' : 'A4E_SOLUTION_PROGRESS_UNINSTALLED';
                        createNotification(
                            translate({ id: statusMessageId }),
                            notificationType.SUCCESS,
                        );
                        shouldRefreshEntireStatus = true;
                    }
                }

                // We finished the deployment, so we need to refresh the entire status
                if (tenant?.name && shouldRefreshEntireStatus) {
                    const latestSolutionStatus = await getAutopilotSolutionStatus(organizationName, tenant.name);
                    setSolutionStatus(latestSolutionStatus);
                }
            } catch (error) {
                createGenericErrorNotification('A4E_SOLUTION_ERROR_CHECK', error as A4EError);
            }
        },
        isDeploymentInProgress ? 2000 : null,
    );

    const setTenantCallback = useCallback(
        (selectedTenantId: string) => {
            setSolutionStatus({ deploymentState: SolutionDeploymentState.NONE });
            setTenant(tenants.find(t => t.id === selectedTenantId));
        },
        [ setTenant, tenants ],
    );

    const determineSolutionHeaderText = useCallback((): string => {
        switch (solutionStatus.deploymentState) {
            case SolutionDeploymentState.INSTALLED:
            case SolutionDeploymentState.UPDATE_AVAILABLE:
            case SolutionDeploymentState.UPDATING:
            case SolutionDeploymentState.UNINSTALLING:
                return translate({ id: 'A4E_SOLUTION_INSTALLED_TITLE' }, { version: solutionStatus.deployedAutopilotVersion });
            case SolutionDeploymentState.INSTALL_ERROR:
            case SolutionDeploymentState.INSTALLING:
            case SolutionDeploymentState.NOT_INSTALLED:
            default:
                return translate({ id: 'A4E_SOLUTION_PREREQ_TITLE' });
        }
    }, [ solutionStatus.deployedAutopilotVersion, solutionStatus.deploymentState, translate ]);

    const installSolution = useCallback(async () => {
        if (!tenant?.name) {
            setSolutionStatus({ deploymentState: SolutionDeploymentState.NOT_INSTALLED });
            return;
        }

        const previousState = solutionStatus.deploymentState;
        try {
            const newDeploymentState = solutionStatus.deploymentState === SolutionDeploymentState.UPDATE_AVAILABLE ?
                SolutionDeploymentState.UPDATING : SolutionDeploymentState.INSTALLING;
            const messageId = solutionStatus.deploymentState === SolutionDeploymentState.UPDATE_AVAILABLE ?
                'A4E_SOLUTION_PROGRESS_UPDATING' : 'A4E_SOLUTION_PROGRESS_INSTALLING';
            createNotification(translate({ id: messageId }), notificationType.INPROGRESS);
            setSolutionStatus((prevStatus) => ({
                ...prevStatus,
                instanceId: undefined,
                pipelineDeploymentId: undefined,
                deploymentState: newDeploymentState,
            }));
            const deploymentStatus = await deployAutopilot(tenant?.name);

            setSolutionStatus((prevStatus) => ({
                ...prevStatus,
                pipelineDeploymentId: deploymentStatus.pipelineDeploymentId,
                deploymentState: newDeploymentState,
            }));
        } catch (error) {
            createGenericErrorNotification('A4E_SOLUTION_ERROR_INSTALL', error as A4EError);
            setSolutionStatus((prevStatus) => ({
                ...prevStatus,
                pipelineDeploymentId: undefined,
                deploymentState: previousState,
            }));
        }
    }, [ createGenericErrorNotification, createNotification, solutionStatus.deploymentState, tenant?.name, translate ]);

    const uninstallSolution = useCallback(async () => {
        if (!tenant?.name) {
            setSolutionStatus({ deploymentState: SolutionDeploymentState.NOT_INSTALLED });
            return;
        }

        const previousState = solutionStatus.deploymentState;
        try {
            createNotification(translate({ id: 'A4E_SOLUTION_PROGRESS_UNINSTALLING' }), notificationType.INPROGRESS);
            setSolutionStatus((prevStatus) => ({
                ...prevStatus,
                instanceId: undefined,
                pipelineDeploymentId: undefined,
                deploymentState: SolutionDeploymentState.UNINSTALLING,
            }));
            const instanceId = await uninstallAutopilotDeployment(tenant?.name);

            setSolutionStatus((prevStatus) => ({
                ...prevStatus,
                instanceId,
                deploymentState: SolutionDeploymentState.UNINSTALLING,
            }));
        } catch (error) {
            createGenericErrorNotification('A4E_SOLUTION_ERROR_UNINSTALL', error as A4EError);
            setSolutionStatus((prevStatus) => ({
                ...prevStatus,
                instanceId: undefined,
                deploymentState: previousState,
            }));
        }
    }, [ createGenericErrorNotification, createNotification, solutionStatus.deploymentState, tenant?.name, translate ]);

    const determineSolutionActionButton = useCallback((): React.JSX.Element => {
        if (solutionStatus.deploymentState && solutionStatus.deploymentState >= SolutionDeploymentState.INSTALL_ERROR) {
            const isButtonDisabled = !tenant ||
        solutionStatus.deploymentState === SolutionDeploymentState.UNINSTALLING ||
        solutionStatus.deploymentState === SolutionDeploymentState.UPDATING;
            return <ApButton
                label={translate({ id: 'A4E_SOLUTION_UNINSTALL' })}
                variant='secondary'
                loading={solutionStatus.deploymentState === SolutionDeploymentState.UNINSTALLING}
                disabled={isButtonDisabled}
                onClick={uninstallSolution}
                data-cy="solution-action-button"
            />;
        }

        const isButtonDisabled = !tenant ||
        solutionStatus.deploymentState === SolutionDeploymentState.NONE ||
        solutionStatus.deploymentState === SolutionDeploymentState.INSTALLING ||
        solutionStatus.deploymentState === SolutionDeploymentState.INSTALLED ||
        solutionStatus.deploymentState === SolutionDeploymentState.UPDATING;

        // Show loading if tenant is selected and we are determining current status
        const isButtonLoading = (tenant && solutionStatus.deploymentState === SolutionDeploymentState.NONE) ||
        solutionStatus.deploymentState === SolutionDeploymentState.INSTALLING;

        return <ApButton
            label={translate({ id: 'A4E_SOLUTION_INSTALL' })}
            variant='primary'
            loading={isButtonLoading}
            disabled={isButtonDisabled}
            onClick={installSolution}
            data-cy="solution-action-button"
        />;
    }, [ installSolution, solutionStatus.deploymentState, tenant, translate, uninstallSolution ]);

    const createUpdateBanner = useCallback((): React.JSX.Element => {
        const isButtonDisabled =
        solutionStatus.deploymentState === SolutionDeploymentState.UNINSTALLING ||
        solutionStatus.deploymentState === SolutionDeploymentState.UPDATING;
        return <UiStack
            direction='row'
            pt={SpacingToken.XS}
            pb={SpacingToken.XS}
            pl={SpacingToken.S}
            pr={SpacingToken.S}
            align='center'
            justify='between'
            style={{
                backgroundColor: theme.palette.semantic.colorInfoBackground,
                border: `1px solid ${theme.palette.semantic.colorInfoIcon}`,
            }}>
            <UiStack
                direction='row'
                gap={SpacingToken.S}
                align='center'
            >
                <PortalIcon
                    name='redeem'
                    color={theme.palette.semantic.colorInfoIcon} />
                <UiText
                    color={theme.palette.semantic.colorForeground}
                    data-cy="solution-update-title">
                    {translate({ id: 'A4E_SOLUTION_UPDATE_TITLE' }, { version: solutionStatus.latestAutopilotFeedVersion?.version })}
                </UiText>
                <ApLink
                    href='https://docs.uipath.com/autopilot/other/latest/user-guide/about-autopilot'
                    target="_blank"
                >
                    {translate({ id: 'A4E_SOLUTION_UPDATE_LEARN_MORE' })}
                </ApLink>
            </UiStack>
            <ApButton
                label={translate({ id: 'A4E_SOLUTION_UPDATE' })}
                variant='primary'
                loading={solutionStatus.deploymentState === SolutionDeploymentState.UPDATING}
                disabled={isButtonDisabled}
                onClick={installSolution}
                data-cy="solution-update-button"
            />
        </UiStack>;
    }, [ installSolution, solutionStatus.deploymentState, solutionStatus.latestAutopilotFeedVersion?.version, theme.palette.blue_secondary, translate ]);

    const showUpdateBanner = solutionStatus.deploymentState && solutionStatus.deploymentState >= SolutionDeploymentState.UPDATE_AVAILABLE;
    const showDescription = !solutionStatus.deploymentState || solutionStatus.deploymentState <= SolutionDeploymentState.INSTALL_ERROR;
    return <UiStack
        direction='column'
        gap={SpacingToken.S}
        pl={SpacingToken.Micro}
        pr={SpacingToken.Micro}>
        <A4ETenantDropdown
            selectedValue={tenant?.id}
            tenants={tenants}
            callback={setTenantCallback} />
        <PortalDivider type='horizontal' />
        {(showUpdateBanner && createUpdateBanner()) || null}
        <UiText
            variant={FontVariantToken.fontSizeMBold}
            data-cy="prereq-title"
        >
            {determineSolutionHeaderText()}
        </UiText>
        {showDescription && <UiText
            data-cy="prereq-description">
            <ul>
                <li>
                    {translate({ id: 'A4E_PREREQ_DESCRIPTION_AUTOMATION_CLOUD' })}
                </li>
                <li>
                    {translate({ id: 'A4E_PREREQ_DESCRIPTION_VERSION' })}
                </li>
                <li>
                    {translate({ id: 'A4E_PREREQ_DESCRIPTION_PERSONAL_WORKSPACE' })}
                </li>
                <li>
                    {translate({ id: 'A4E_PREREQ_DESCRIPTION_DU_ENABLED' })}
                </li>
                <li>
                    <FormattedMessage
                        key={2}
                        id="A4E_PREREQ_DESCRIPTION_AUTOPILOT_LICENSE"
                        values={{
                            a: (msg: React.ReactNode[]) =>
                                (
                                    <ApLink
                                        href='https://docs.uipath.com/autopilot/other/latest/user-guide/about-autopilot'
                                        target="_blank"
                                    >
                                        {msg}
                                    </ApLink>
                                ),
                        }}
                    />
                </li>
            </ul>
        </UiText>}
        <UiStack>
            {determineSolutionActionButton()}
        </UiStack>
    </UiStack>;

};

export default A4ESolutionComponent;
