import { getEnvironment } from '@experiences/util';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // Extend dayjs with the isSameOrAfter plugin

import type { IGridDataEntry } from './GridDataEntryTypes';

dayjs.extend(isSameOrAfter);

type Environment = 'local' | 'alp' | 'stg' | 'prd';

export interface GridFilters {
    searchTerm?: string;
    sort?: Array<{ field: keyof IGridDataEntry | 'formattedDate'; direction: 'asc' | 'desc' }>; // Allow 'formattedDate'
    product?: string;
    modelUsed?: string;
    status?: string;
    dateRange?: 'all' | 'lastDay' | 'lastWeek' | 'last30Days'; // Add dateRange filter
}

export function processGridData(
    data: IGridDataEntry[],
    filters: GridFilters
): IGridDataEntry[] {
    const {
        searchTerm = '', sort = [],
    } = filters;

    let updatedData = [ ...data ]; // Start with a copy of the data

    // Handle search logic
    if (searchTerm) {
        updatedData = updatedData.filter(item => Object.keys(item).some(key => {
            const value = item[key as keyof IGridDataEntry];
            if (typeof value === 'string' || typeof value === 'number') {
                return value.toString().toLowerCase()
                    .includes(searchTerm.toLowerCase());
            }
        }));
    }

    // Handle sorting
    if (sort && sort.length > 0) {
        const {
            field, direction,
        } = sort[0];

        updatedData.sort((a, b) => {
            // Handle 'formattedDate' by using 'date' for sorting
            if (field === 'formattedDate') {
                const dateA = dayjs(a.date); // Sort by raw `date` field
                const dateB = dayjs(b.date);

                if (dateA.isBefore(dateB)) {
                    return direction === 'asc' ? -1 : 1;
                }
                if (dateA.isAfter(dateB)) {
                    return direction === 'asc' ? 1 : -1;
                }
                return 0;
            }

            // Default sorting for other fields
            if (a[field] < b[field]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[field] > b[field]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    return updatedData;
}

export function filterByProduct(
    model: { value?: string | null | number | boolean | Date | any } | null, // Accept broader types
    distinctProducts: Array<{ label: string; value: string }>,
    processedAuditData: IGridDataEntry[],
    // eslint-disable-next-line @typescript-eslint/no-shadow
    processGridData: (data: IGridDataEntry[], filters: any) => IGridDataEntry[]
): { selectedProduct: { label: string; value: string } | undefined; filteredData: IGridDataEntry[] } {
    // Ensure model.value is a string
    const stringValue = typeof model?.value === 'string' ? model?.value : null;

    if (stringValue === 'all') {
        return {
            selectedProduct: undefined, // Reset product
            filteredData: processGridData(processedAuditData, {}), // Show all data
        };
    } else if (stringValue) {
        const selectedValue = stringValue;
        const selectedProduct = {
            label: distinctProducts.find(p => p.value === selectedValue)?.label ?? '',
            value: selectedValue,
        };
        // Pass the product filter to processGridData
        const filteredData = processGridData(processedAuditData, { product: selectedValue });

        return {
            selectedProduct,
            filteredData,
        }; // Filter data based on selected product
    }
    // In case model or model.value is null or undefined
    return {
        selectedProduct: undefined,
        filteredData: processGridData(processedAuditData, {}),
    };
}

export function filterByModelUsed(
    model: { value?: string | null | number | boolean | Date | any } | null,
    distinctModelsUsed: Array<{ label: string; value: string }>,
    processedAuditData: IGridDataEntry[],
    processGridData: (data: IGridDataEntry[], filters: any) => IGridDataEntry[]
): { selectedModelUsed: { label: string; value: string } | undefined; filteredData: IGridDataEntry[] } {
    const stringValue = typeof model?.value === 'string' ? model?.value : null;

    if (stringValue === 'all') {
        return {
            selectedModelUsed: undefined, // Reset model used
            filteredData: processGridData(processedAuditData, {}), // Show all data
        };
    } else if (stringValue) {
        const selectedValue = stringValue;
        const selectedModelUsed = {
            label: distinctModelsUsed.find(m => m.value === selectedValue)?.label ?? '',
            value: selectedValue,
        };
        // Pass the modelUsed filter to processGridData
        const filteredData = processGridData(processedAuditData, { modelUsed: selectedValue });

        return {
            selectedModelUsed,
            filteredData,
        }; // Filter data based on selected modelUsed
    }
    return {
        selectedModelUsed: undefined,
        filteredData: processGridData(processedAuditData, {}),
    };
}

export function filterByStatus(
    model: { value?: string | null | number | boolean | Date | any } | null,
    distinctStatuses: Array<{ label: string; value: string }>,
    processedAuditData: IGridDataEntry[],
    // processGridData: (data: IGridDataEntry[], filters: any) => IGridDataEntry[]
): { selectedStatus: { label: string; value: string } | undefined; filteredData: IGridDataEntry[] } {
    const stringValue = typeof model?.value === 'string' ? model?.value : null;

    if (stringValue === 'all') {
        return {
            selectedStatus: undefined, // Reset status
            filteredData: processGridData(processedAuditData, {}), // Show all data
        };
    } else if (stringValue) {
        const selectedValue = stringValue;
        const selectedStatus = {
            label: distinctStatuses.find(s => s.value === selectedValue)?.label ?? '',
            value: selectedValue,
        };
        // Pass the status filter to processGridData
        const filteredData = processGridData(processedAuditData, { status: selectedValue });

        return {
            selectedStatus,
            filteredData,
        }; // Filter data based on selected status
    }
    return {
        selectedStatus: undefined,
        filteredData: processGridData(processedAuditData, {}),
    };
}

export function filterByDateRange(
    model: { value?: string | null | number | boolean | Date | any } | null,
    distinctDateRanges: Array<{ label: string; value: string }>,
    processedAuditData: IGridDataEntry[],
    processGridData: (data: IGridDataEntry[], filters: any) => IGridDataEntry[]
): { selectedDateRange: { label: string; value: string } | undefined; filteredData: IGridDataEntry[] } {
    const stringValue = typeof model?.value === 'string' ? model?.value : null;

    if (stringValue === 'all') {
        return {
            selectedDateRange: undefined, // Reset date range
            filteredData: processGridData(processedAuditData, {}), // Show all data
        };
    } else if (stringValue) {
        const selectedValue = stringValue;
        const selectedDateRange = {
            label: distinctDateRanges.find(d => d.value === selectedValue)?.label ?? '',
            value: selectedValue,
        };

        let dateFilter;
        const now = dayjs();

        switch (selectedValue) {
            case 'lastDay':
                dateFilter = now.subtract(1, 'day').startOf('day');
                break;
            case 'lastWeek':
                dateFilter = now.subtract(1, 'week').startOf('week');
                break;
            case 'last30Days':
                dateFilter = now.subtract(30, 'day').startOf('day');
                break;
            case 'last60Days':
                dateFilter = now.subtract(60, 'day').startOf('day');
                break;
            default:
                dateFilter = '';
                break;
        }

        const filteredData = processGridData(
            processedAuditData,
            { dateRange: dateFilter ? { $gte: dateFilter.toString() } : undefined }
        );

        return {
            selectedDateRange,
            filteredData,
        };
    }

    return {
        selectedDateRange: undefined,
        filteredData: processGridData(processedAuditData, {}),
    };
}

// Function to handle next record selection
export function handleNextRecord(
    filteredData: IGridDataEntry[],
    currentIndex: number,
    setSelectedRecord: (record: IGridDataEntry | null) => void,
    setCurrentIndex: (index: number) => void
): void {
    const newIndex = currentIndex + 1;

    if (newIndex < filteredData.length) {
        setSelectedRecord(filteredData[newIndex]);
        setCurrentIndex(newIndex);
    } else {
        setSelectedRecord(null); // No next record, reset selection
        setCurrentIndex(currentIndex); // Keep the index unchanged
    }
}

// Function to handle previous record selection and update the state
export function handlePreviousRecord(
    filteredData: IGridDataEntry[],
    currentIndex: number,
    setSelectedRecord: (record: IGridDataEntry | null) => void,
    setCurrentIndex: (index: number) => void
): void {
    const newIndex = currentIndex - 1;

    if (newIndex >= 0) {
        setSelectedRecord(filteredData[newIndex]);
        setCurrentIndex(newIndex);
    } else {
        setSelectedRecord(null); // If there are no previous records, reset the selection
        setCurrentIndex(currentIndex); // Keep the index unchanged
    }
}

function constructApiUrl(accountId: string, path: string): string {
    const env = getEnvironment() as Environment; // Explicitly cast the environment as one of the expected types

    const baseUrls: Record<Environment, string> = {
        local: `http://localhost:5148/llmops_/api/LLMOps/${path}`,
        alp: `https://alpha.uipath.com/${accountId}/llmops_/api/LLMOps/${path}`,
        stg: `https://staging.uipath.com/${accountId}/llmops_/api/LLMOps/${path}`,
        prd: `https://cloud.uipath.com/${accountId}/llmops_/api/LLMOps/${path}`,
    };

    // Provide a fallback in case the environment is not one of the expected values
    return baseUrls[env] ?? baseUrls.local;
}

export const getBatchAuditLogApiUrl = (accountId: string) => constructApiUrl(accountId, 'auditlogs/');
export const getSingleAuditLogApiUrl = (accountId: string, firstRequestId: string, lastRequestId: string) =>
    constructApiUrl(accountId, `auditlogs/${firstRequestId}/${lastRequestId}`);
export const getAuditLogSummaryApiUrl = (accountId: string) => constructApiUrl(accountId, 'auditlogs/summary');
export const getFilteredAuditLogSummaryApiUrl = (accountId: string) => constructApiUrl(accountId, 'auditlogs/stats');

export function handleSearchChange(
    term: string,
    setSearchTerm: (value: string) => void,
    filteredData: IGridDataEntry[], // Filter data on the current page
    setFilteredData: (data: IGridDataEntry[]) => void,
    setFilteredDataLength: (length: number) => void
) {
    setSearchTerm(term);

    const lowerCaseTerm = term.toLowerCase();
    const searchedData = filteredData.filter(entry =>
        Object.values(entry).some(value =>
            String(value).toLowerCase()
                .includes(lowerCaseTerm)
        )
    );

    setFilteredData(searchedData);
    setFilteredDataLength(searchedData.length);
}
