import {
    SpacingToken,
    UiProgressButton,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { FontVariantToken } from '@uipath/apollo-core';
import React, { useRef } from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { notificationType } from '../../../../common/constants/Constant';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import type { IA4EStartingPromptData } from '../../../../common/interfaces/a4e';
import { UiDrawer } from '../../../common/UiDrawer';
import UiForm from '../../../common/UiForm';
import { parseFileForStartingPrompts } from '../utils/A4EStartingPromptsUtils';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: theme.spacing(1),
        },
        uploadFileButtonWrapper: { width: '100%' },
        uploadFileButtonDiv: { width: '100%' },
        uploadFileButton: {
            border: `2px dashed ${theme.palette.grey[500]}`,
            borderRadius: '5px',
            height: '110px',
        },
        addFilesText: { color: theme.palette.blue[500] },
        dropFilesText: { color: theme.palette.grey[500] },
    }),
);

interface A4EStartingPromptsUploadComponentProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setStartingPromptsTableData: React.Dispatch<React.SetStateAction<IA4EStartingPromptData[]>>;
}

const A4EStartingPromptsUploadComponent: React.FC<A4EStartingPromptsUploadComponentProps> = ({
    isOpen, setIsOpen, setStartingPromptsTableData,
}) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const createNotification = useUiSnackBar();
    const inputRef = useRef<HTMLInputElement>(null);
    const {
        handleSubmit, control, reset, watch,
    } = useForm();

    const startingPromptsFile = watch('startingPromptsFile');
    const hasFile = !!startingPromptsFile;

    const handleClose = () => {
        reset();
        setIsOpen(false);
    };

    const onSubmit = async (fileData: any) => {
        const requiredHeaders = [ 'department', 'category', 'title', 'prompt' ];
        const optionalHeaders = [ 'isFeatured', 'requiresFileUpload' ];
        try {
            const uploadedTableData = await parseFileForStartingPrompts(fileData.startingPromptsFile[0], requiredHeaders, optionalHeaders);
            if (!uploadedTableData) {
                createNotification(
                    translate({ id: 'A4E_STARTING_PROMPTS_UPLOAD_DRAWER_UPLOAD_FAIL' }),
                    notificationType.ERROR
                );
            } else {
                //  add an uuid to each row
                uploadedTableData.forEach(row => {
                    row.id = uuid();
                });
                setStartingPromptsTableData(uploadedTableData);
                createNotification(
                    translate({ id: 'A4E_STARTING_PROMPTS_UPLOAD_DRAWER_UPLOAD_SUCCESS' }),
                    notificationType.SUCCESS
                );
            }
        } catch (e) {
            createNotification(
                translate({ id: 'A4E_STARTING_PROMPTS_UPLOAD_DRAWER_UPLOAD_FAIL' }),
                notificationType.ERROR
            );
        } finally {
            handleClose();
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
            const files = e.dataTransfer.files;
            const csvFiles = Array.from(files).filter(file => file.type === 'text/csv');
            if (csvFiles.length > 0 && inputRef.current) {
                const dataTransfer = new DataTransfer();
                csvFiles.forEach(file => dataTransfer.items.add(file));
                inputRef.current.files = dataTransfer.files;
                inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
            }
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <UiDrawer
            title={translate({ id: 'A4E_STARTING_PROMPTS_UPLOAD_DRAWER_TITLE' })}
            drawerProps={{
                anchor: 'right',
                open: isOpen,
                onClose: () => {
                    handleClose();
                },
            }}
            width="medium"
            themeProps={{ disableGutters: [ 'bottom', 'right' ] }}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            onClick={() => handleClose()}
                            color="primary"
                            data-cy="upload-cancel-button"
                        >
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            disabled={!hasFile}
                            type="submit"
                            variant="contained"
                            loading={false}
                            data-cy="upload-save-button"
                        >
                            {translate({ id: 'A4E_STARTING_PROMPTS_UPLOAD_DRAWER_CLIENT_UPLOAD' })}
                        </UiProgressButton>
                    </div>
                }
                isDrawer
                addScrollPadding
            >
                <UiStack
                    direction="column"
                    align="start"
                >
                    <Controller
                        control={control}
                        name="startingPromptsFile"
                        rules={{ required: true }}
                        render={({ field: { onChange } }) => (
                            <>
                                <input
                                    onChange={e => {
                                        if (e.target.files && e.target.files.length > 0) {
                                            onChange(e.target.files);
                                        } else {
                                            onChange(null);
                                        }
                                    }}
                                    type="file"
                                    accept=".csv"
                                    ref={inputRef}
                                    style={{ display: 'none' }}
                                    multiple={false}
                                    data-cy="starting-prompts-upload-file-input" />
                                <UiStack
                                    direction="column"
                                    align="start"
                                    className={classes.uploadFileButtonDiv}
                                    gap={SpacingToken.XS}
                                >
                                    <UiText
                                        variant={FontVariantToken.fontSizeMBold}
                                    >
                                        {translate({ id: 'CLIENT_UPLOAD_FILE' })}
                                    </UiText>
                                    <div
                                        className={classes.uploadFileButtonWrapper}
                                        onDrop={handleDrop}
                                        onDragOver={handleDragOver}
                                        role="presentation"
                                    >
                                        <Button
                                            className={classes.uploadFileButton}
                                            color="primary"
                                            onClick={() => inputRef.current?.click()}
                                            fullWidth
                                        >

                                            {!hasFile ?
                                                <UiStack
                                                    direction="row"
                                                    align="center"
                                                    justify="between"
                                                >
                                                    <p className={classes.addFilesText}>
                                                        {translate({ id: 'A4E_STARTING_PROMPTS_UPLOAD_DRAWER_ADD_FILES' })}
                                                    </p>
                                            &nbsp;&nbsp;
                                                    <p className={classes.dropFilesText}>
                                                        {translate({ id: 'A4E_STARTING_PROMPTS_UPLOAD_DRAWER_DROP_FILES' })}
                                                    </p>
                                                </UiStack>
                                                : <p>
                                                    {startingPromptsFile[0]?.name}
                                                </p>}
                                        </Button>
                                    </div>
                                    <UiText
                                        variant={FontVariantToken.fontSizeS}
                                    >
                                        {translate({ id: 'A4E_STARTING_PROMPTS_UPLOAD_DRAWER_FILE_TYPE_RESTRICTION' })}
                                    </UiText>
                                </UiStack>
                            </>
                        )}
                    />
                </UiStack>
            </UiForm>
        </UiDrawer>
    );
};

export default A4EStartingPromptsUploadComponent;
