import { useUiDataContext } from '@experiences/ui-common';
import { useQueryState } from '@experiences/util';
import type {
    Column,
    IFilterModel,
} from '@uipath/apollo-angular-elements';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';

import type { DateTimeRange } from '../../common/UiDatePicker/UiDatePicker';
import type {
    AuditEventSourceDto,
    IAuditDetailsContext,
} from '../interfaces/auditLog';
import {
    getDateFilterString,
    getSuggestValue,
} from './AuditGridUtils';

const FILTER_DEBOUNCE_TIME_MS = 500;

const DAYS = 24 * 60 * 60 * 1000;

export function useAuditGridFilterModel() {
    const { formatMessage: translate } = useIntl();

    const { data: { filterManager } } = useUiDataContext<IAuditDetailsContext>();

    const [ filters, setFilters ] = useQueryState<Array<IFilterModel<AuditEventSourceDto>>>('filter', []);

    const [ timeFilter, setTimeFilter ] = useQueryState<DateTimeRange>('time', {
        from: new Date(Date.now() - 90 * DAYS),
        to: new Date(),
    }, (k, value) => k ? new Date(value) : value);

    const [ timeFilterAnchorEl, setTimeFilterAnchorEl ] = useState<HTMLElement | null>(null);
    const [ isClearing, setIsClearing ] = useState<boolean>(false);

    const [ debouncedFilters ] = useDebounce(filters, FILTER_DEBOUNCE_TIME_MS);

    const [ sourceFilter, categoryFilter, actionFilter ] = useMemo(() => {
        const eventSource = filters.find(f => f.property === 'eventSource')?.value as string[];
        const debouncedEventSource = debouncedFilters.find(f => f.property === 'eventSource')?.value as string[];

        const eventTarget = filters.find(f => f.property === 'eventTarget')?.value as string[];
        const debouncedEventTarget = debouncedFilters.find(f => f.property === 'eventTarget')?.value as string[];

        const eventType = filters.find(f => f.property === 'eventType')?.value as string[];
        const debouncedEventType = debouncedFilters.find(f => f.property === 'eventType')?.value as string[];

        return [
            {
                value: eventSource,
                debouncedValue: debouncedEventSource,
                suggestValue: getSuggestValue(eventSource),
            },
            {
                value: eventTarget,
                debouncedValue: debouncedEventTarget,
                suggestValue: getSuggestValue(eventTarget),
            },
            {
                value: eventType,
                debouncedValue: debouncedEventType,
                suggestValue: getSuggestValue(eventType),
            },
        ];
    }, [ debouncedFilters, filters ]);

    const timeFilterLabel = useMemo(() => {
        const {
            id, args,
        } = getDateFilterString(timeFilter.from, timeFilter.to);

        return translate({ id }, args);
    }, [ timeFilter.from, timeFilter.to, translate ]);

    const handleTimeFilterChange = useCallback((event?: React.MouseEvent<HTMLButtonElement>) => {
        setTimeFilterAnchorEl(event?.currentTarget ?? null);
    }, []);

    const clearTimeFilter = useCallback((e?: React.MouseEvent<SVGElement>) => {
        e?.stopPropagation();
        setTimeFilter({
            from: undefined,
            to: undefined,
        });
    }, [ setTimeFilter ]);

    const clearFilters = useCallback(() => {
        setIsClearing(true);

        // @ts-ignore
        filterManager?.clear();
        clearTimeFilter();
    }, [ clearTimeFilter, filterManager ]);

    const onFilterChange = useCallback((f: Array<IFilterModel<AuditEventSourceDto>>) => {
        const sourceFilter_ = f.find(filter => filter.property === 'eventSource');
        const categoryFilter_ = f.find(filter => filter.property === 'eventTarget');
        const actionFilter_ = f.find(filter => filter.property === 'eventType');

        // if the source filter is missing, then there should be no filters selected
        if (!sourceFilter_ && (categoryFilter_ || actionFilter_)) {
            clearFilters();
            return;
        }

        // if the category filter is selected but the action filter isn't, then deselect action filter
        if (!categoryFilter_ && actionFilter_) {
            const actionsColumn = filterManager?.columns.find(c => c.property === 'eventType') as Column<any>;

            filterManager?.dropdownUpdate(actionsColumn, undefined);
            return;
        }

        const filtersWithoutMeta = f.map(({
            meta, ...rest
        }) => rest);

        setFilters(filtersWithoutMeta);
    }, [ clearFilters, filterManager, setFilters ]);

    useEffect(() => {
        if (!filterManager) {
            return;
        }

        const subscription = filterManager.filter$.asObservable().subscribe(onFilterChange);

        return () => subscription.unsubscribe();
    }, [ filterManager, onFilterChange ]);

    useEffect(() => {
        if (debouncedFilters.length === 0 && !timeFilter.from && !timeFilter.to) {
            setIsClearing(false);
        }
    }, [ debouncedFilters.length, timeFilter.from, timeFilter.to ]);

    return {
        filters: debouncedFilters,
        sourceFilter,
        categoryFilter,
        actionFilter,
        timeFilter: {
            value: timeFilter,
            hasValue: timeFilter.from && timeFilter.to,
            anchorEl: timeFilterAnchorEl,
            open: Boolean(timeFilterAnchorEl),
            label: timeFilterLabel,
            set: setTimeFilter,
            clear: clearTimeFilter,
            toggle: handleTimeFilterChange,
        },
        isClearing,
        setFilters,
        clearFilters,
    };
}
