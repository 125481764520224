import type { Tenant } from '@uipath/portal-shell/dist/types/models/tenant';
import {
    ApDropdown,
    ApDropdownItem,
} from '@uipath/portal-shell-react';
import type { ApDropdownCustomEvent } from '@uipath/portal-shell-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

interface IA4ETenantDropdownProps {
    selectedValue?: string;
    tenants: Tenant[];
    callback: (tenantId: string) => void;
}

export const A4ETenantDropdown = (props: IA4ETenantDropdownProps) => {
    const { formatMessage: translate } = useIntl();

    const onChange = useCallback((event: ApDropdownCustomEvent<string | string[]>) => {
        props.callback(event.detail as string);
    }, [ props ]);

    return (
        <ApDropdown
            style={{ width: '322px' }}
            label={translate({ id: 'A4E_TENANT' })}
            selectedValue={props.selectedValue}
            onSelectedValueChanged={onChange}
            required
            data-cy="tenant-dropdown-select"
        >
            {props.tenants.map(tenant => (
                <ApDropdownItem
                    value={tenant.id}
                    label={tenant.name}
                    key={`ap-dropdown-item-${tenant.id}`}
                    data-cy={`ap-dropdown-item-${tenant.id}`}
                />
            ))}
        </ApDropdown>
    );
};
