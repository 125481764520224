import { Entitlements } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiProgressButton } from '@experiences/ui-common';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import { EnablingCustomerManagedKeyLink } from '../../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useDocumentationLinks } from '../../../../common/hooks/useDocumentationLink';
import type { EncryptionFormData } from '../../../../common/interfaces/encryption';
import {
    getKmsConfig,
    kmsUrl,
    saveKmsConfig,
} from '../../../../services/identity/KeyManagementService';
import { consumeEntitlement } from '../../../../services/licensing/EntitlementsService';
import { accountGlobalId } from '../../../../store/selectors';
import { UiDrawer } from '../../../common/UiDrawer';
import UiForm from '../../../common/UiForm';
import EncryptionConfirmationDialogBody from './EncryptionConfirmationDialogBody';

const useStyles = makeStyles(() =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        fieldLabel: {
            display: 'inline-flex',
            alignItems: 'center',
            gap: '5px',
        },
        form: {
            overflow: 'auto',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '36px',
            marginTop: '48px',
        },
        link: {
            fontSize: '14px',
            fontWeight: 600,
            marginTop: 'auto',
        },
        saveButton: { marginLeft: '8px' },
    }),
);

const defaultEncryptionData: EncryptionFormData = {
    AADTenantId: '',
    clientId: '',
    clientSecret: '',
    keyIdentifier: '',
};

const EncryptionConfigureComponent: React.FC<{ type: 'add' | 'edit' }> = ({ type = 'add' }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const partitionGlobalId = useSelector(accountGlobalId);
    const createDialog = useShowDialog();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'zh-TW', 'pt', 'tr', 'ru' ] });

    const EnableCustomerManagedKeyEntitlement = useFeatureFlagValue(Features.EnableCustomerManagedKeyEntitlement.name);
    const EnableKMSClientCertificate = useFeatureFlagValue(Features.EnableKMSClientCertificate.name);
    const EnableCustomerManagedKeyErrorBanner = useFeatureFlagValue(Features.EnableCustomerManagedKeyErrorBanner.name);

    const {
        open, close,
    } = useModalState(RouteNames.Encryption);
    const [ loading, setLoading ] = useState(false);

    const { mutate } = useSWRConfig();
    const { data: kmsConfig } = useSWR(
        type === 'edit' ? {
            url: kmsUrl,
            partitionGlobalId,
        } : null,
        getKmsConfig,
    );

    const methods = useForm<EncryptionFormData>({
        mode: 'onSubmit',
        defaultValues: defaultEncryptionData,
    });
    const {
        control, handleSubmit, reset, formState: {
            errors, isDirty,
        }, watch,
    } = methods;

    const initialFormData: EncryptionFormData | undefined = useMemo(() => {
        if (type === 'edit' && kmsConfig?.keyType === 1) {
            const config = JSON.parse(kmsConfig?.configuration);
            return {
                AADTenantId: config.TenantId,
                clientId: config.ClientId,
                clientSecret: config.ClientSecret,
                keyIdentifier: config.KeyId,
            };
        }
    }, [ kmsConfig, type ]);

    useEffect(() => {
        if (initialFormData) {
            reset(initialFormData);
        }
    }, [ initialFormData, kmsConfig, reset ]);

    const refreshRadioGroup = useCallback(() => {
        mutate({
            url: kmsUrl,
            partitionGlobalId,
        });
    }, [ mutate, partitionGlobalId ]);

    const refreshCmkStatus = useCallback(() => {
        mutate({
            url: `${kmsUrl}/cmkstatus`,
            partitionGlobalId,
        });
    }, [ mutate, partitionGlobalId ]);

    const onSubmit = useCallback(
        async (data: any) => {
            try {
                setLoading(true);
                const config = {
                    TenantId: data.AADTenantId.trim(),
                    ClientId: data.clientId.trim(),
                    ClientSecret: data.clientSecret.trim(),
                    KeyId: data.keyIdentifier.trim(),
                };
                if (EnableCustomerManagedKeyEntitlement && type === 'add') {
                    await consumeEntitlement(Entitlements.CustomerManagedKey);
                }
                await saveKmsConfig(kmsUrl, {
                    partitionGlobalId,
                    keyType: 1,
                    configuration: JSON.stringify(config),
                });
                close();
                refreshRadioGroup();
                if (EnableCustomerManagedKeyErrorBanner) {
                    refreshCmkStatus();
                }

                await createDialog({
                    title: translate({ id: 'CLIENT_ENCRYPTION_TYPE_CHANGED' }),
                    customDialogContent: EncryptionConfirmationDialogBody,
                    customDialogContentProps: { success: true },
                    icon: 'success',
                });
            } catch (error) {
                setLoading(false);
                await createDialog({
                    title: translate({ id: 'CLIENT_TEST_CONNECTION_FAILED' }),
                    customDialogContent: EncryptionConfirmationDialogBody,
                    customDialogContentProps: { success: false },
                    icon: 'error',
                });
            } finally {
                setLoading(false);
            }
        },
        [
            EnableCustomerManagedKeyEntitlement,
            EnableCustomerManagedKeyErrorBanner,
            close,
            createDialog,
            partitionGlobalId,
            refreshCmkStatus,
            refreshRadioGroup,
            translate,
            type,
        ],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_ENCRYPTION_CUSTOMER_MANAGED_KEY_CONFIGURATION' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            width="large"
            loading={false}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            onClick={() => close()}
                            color="primary"
                            data-cy="encryption-cancel-button"
                        >
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            className={classes.saveButton}
                            disabled={
                                !isDirty
                                || watch('clientSecret')?.includes('***')
                            }
                            type="submit"
                            variant="contained"
                            loading={loading}
                            data-cy="encryption-test-and-save-button"
                        >
                            {translate({ id: 'CLIENT_TEST_AND_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
            >
                <div className={classes.form}>
                    <Controller
                        name="AADTenantId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                required
                                data-cy="encryption-aad-tenant-id"
                                error={!!errors.AADTenantId}
                                fullWidth
                                helperText={
                                    !!errors.AADTenantId &&
                                    translate(
                                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                        { 0: translate({ id: 'CLIENT_ENCRYPTION_AZURE_AD_TENANT_ID' }) },
                                    )
                                }
                                label={
                                    <div className={classes.fieldLabel}>
                                        {translate({ id: 'CLIENT_ENCRYPTION_AZURE_AD_TENANT_ID' })}
                                        <Tooltip
                                            title={translate({ id: 'CLIENT_ENCRYPTION_AZURE_AD_TENANT_ID_TOOLTIP' })}>
                                            <PortalCustomIcon
                                                name='info'
                                                size='15px' />
                                        </Tooltip>
                                    </div>
                                }
                                variant="outlined"
                            />}
                    />
                    <Controller
                        name="clientId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                required
                                data-cy="encryption-client-id"
                                error={!!errors.clientId}
                                fullWidth
                                helperText={
                                    !!errors.clientId &&
                                    translate(
                                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                        { 0: translate({ id: 'CLIENT_ENCRYPTION_CLIENT_ID' }) },
                                    )
                                }
                                label={
                                    <div className={classes.fieldLabel}>
                                        {translate({ id: 'CLIENT_ENCRYPTION_CLIENT_ID' })}
                                        <Tooltip
                                            title={translate({ id: 'CLIENT_ENCRYPTION_CLIENT_ID_TOOLTIP' })}>
                                            <PortalCustomIcon
                                                name='info'
                                                size='15px' />
                                        </Tooltip>
                                    </div>
                                }
                                variant="outlined"
                            />}
                    />
                    <Controller
                        name="clientSecret"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                multiline={EnableKMSClientCertificate}
                                maxRows={6}
                                required
                                autoComplete="off"
                                type={!EnableKMSClientCertificate ? 'password' : undefined}
                                data-cy="encryption-client-secret"
                                error={!!errors.clientSecret}
                                fullWidth
                                helperText={
                                    !!errors.clientSecret &&
                                    translate(
                                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                        {
                                            0: translate({
                                                id: EnableKMSClientCertificate
                                                    ? 'CLIENT_ENCRYPTION_CLIENT_SECRET_OR_CERTIFICATE'
                                                    : 'CLIENT_ENCRYPTION_CLIENT_SECRET',
                                            }),
                                        },
                                    )
                                }
                                label={
                                    <div className={classes.fieldLabel}>
                                        {translate({
                                            id: EnableKMSClientCertificate
                                                ? 'CLIENT_ENCRYPTION_CLIENT_SECRET_OR_CERTIFICATE'
                                                : 'CLIENT_ENCRYPTION_CLIENT_SECRET',
                                        })}
                                        <Tooltip
                                            title={translate({
                                                id: EnableKMSClientCertificate
                                                    ? 'CLIENT_ENCRYPTION_CLIENT_SECRET_OR_CERTIFICATE_TOOLTIP'
                                                    : 'CLIENT_ENCRYPTION_CLIENT_SECRET_TOOLTIP',
                                            })}>
                                            <PortalCustomIcon
                                                name='info'
                                                size='15px' />
                                        </Tooltip>
                                    </div>
                                }
                                variant="outlined"
                            />}
                    />
                    <Controller
                        name="keyIdentifier"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                required
                                data-cy="encryption-key-identifier"
                                error={!!errors.keyIdentifier}
                                fullWidth
                                helperText={
                                    !!errors.keyIdentifier &&
                                    translate(
                                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                        { 0: translate({ id: 'CLIENT_ENCRYPTION_KEY_IDENTIFIER' }) },
                                    )
                                }
                                label={
                                    <div className={classes.fieldLabel}>
                                        {translate({ id: 'CLIENT_ENCRYPTION_KEY_IDENTIFIER' })}
                                        <Tooltip
                                            title={translate({ id: 'CLIENT_ENCRYPTION_KEY_IDENTIFIER_TOOLTIP' })}>
                                            <PortalCustomIcon
                                                name='info'
                                                size='15px' />
                                        </Tooltip>
                                    </div>
                                }
                                variant="outlined"
                            />}
                    />

                </div>
                <Link
                    className={classes.link}
                    href={getLocalizedLink({ articleSlug: EnablingCustomerManagedKeyLink })}
                    target='_blank'
                    underline='none'
                    data-cy='encryption-info-link'
                >
                    {translate({ id: 'CLIENT_ENCRYPTION_INFO_LINK' })}
                </Link>
            </UiForm>
        </UiDrawer>

    );
};

export const EncryptionConfigurationComponentWithParams: React.FC = () => {
    const { type } = useParams<{ type: 'add' | 'edit' }>();

    return <EncryptionConfigureComponent type={type ?? 'add'} />;
};

export default EncryptionConfigureComponent;
