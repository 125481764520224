import {
    SpacingToken,
    UiProgressButton,
    UiStack,
} from '@experiences/ui-common';
import TextField from '@mui/material/TextField';
import { ApButton } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
} from 'react';
import type { UseFormSetValue } from 'react-hook-form';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { notificationType } from '../../../../common/constants/Constant';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import type { LaunchAcknowledgementConfigInterface } from '../../../../services/a4e/A4EServiceTypes';
import { UiDrawer } from '../../../common/UiDrawer';
import UiForm from '../../../common/UiForm';

interface A4EAdvancedSettingsUserAcknowledgementDrawerComponentProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setValue: UseFormSetValue<any>;
    defaultValues?: LaunchAcknowledgementConfigInterface;
}

const defaultData: LaunchAcknowledgementConfigInterface = {
    headerText: '',
    messageImageURL: '', // optional image URL
    messageText: '',
    acknowledgementButtonText: '', // optional button text
};

const A4EAdvancedSettingsUserAcknowledgementDrawerComponent: React.FC<A4EAdvancedSettingsUserAcknowledgementDrawerComponentProps> = ({
    isOpen, setIsOpen, setValue, defaultValues,
}) => {
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const methods = useForm<LaunchAcknowledgementConfigInterface>({
        mode: 'all',
        defaultValues: defaultValues ?? defaultData,
    });

    const {
        register, handleSubmit, reset, formState: {
            errors, isValid, isDirty,
        },
    } = methods;

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        }
    }, [ defaultValues, reset ]);

    const handleClose = useCallback(() => {
        if (
            defaultValues?.headerText?.trim() === '' ||
            defaultValues?.messageText?.trim() === ''
        ) {
            setValue('launchAcknowledgementConfigEnabled', 'false');
        }
        reset();
        setIsOpen(false);
    }, [ reset, setIsOpen, setValue, defaultValues ]);

    const onSubmit = useCallback((data: LaunchAcknowledgementConfigInterface) => {
        setValue('launchAcknowledgementConfig', data);
        createNotification(
            translate({ id: 'A4E_ADVANCED_SETTINGS_DRAWER_USER_ACK_EDIT_SUCCESS' }),
            notificationType.SUCCESS
        );
        setIsOpen(false);
    }, [ setValue, setIsOpen, createNotification, translate ]);

    return (
        <UiDrawer
            title={translate({ id: 'A4E_ADVANCED_SETTINGS_USER_ACK_DRAWER_TITLE' })}
            drawerProps={{
                anchor: 'right',
                open: isOpen,
                onClose: () => {
                    handleClose();
                },
            }}
            width="medium"
            themeProps={{ disableGutters: [ 'bottom', 'right' ] }}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <UiStack
                        direction="row"
                        justify='end'
                        align='center'
                        gap={SpacingToken.S}
                    >
                        <ApButton
                            onClick={() => handleClose()}
                            label={translate({ id: 'CLIENT_CANCEL' })}
                            variant='tertiary'
                            data-cy="launch-ack-drawer-cancel-button"
                        />
                        <UiProgressButton
                            disabled={!isValid || !!Object.keys(errors).length || !isDirty}
                            type="submit"
                            variant="contained"
                            loading={false}
                            fullWidth
                            data-cy="launch-ack-drawer-save-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </UiStack>
                }
                isDrawer
                addScrollPadding
            >
                <FormProvider {...methods}>
                    <UiStack
                        direction="column"
                        align="start"
                        gap={SpacingToken.M}
                    >
                        <TextField
                            inputProps={register('headerText'
                                , {
                                    minLength: 1,
                                    validate: { required: p => !!p.trim() },
                                }
                            )}
                            required
                            error={!!errors.headerText}
                            helperText={errors.headerText?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            label={translate({ id: 'A4E_ADVANCED_SETTINGS_CUSTOMIZE_APPEARANCE_HEADER' })}
                            variant="outlined"
                            fullWidth
                            data-cy="advanced-settings-drawer-header-text-textfield"
                        />
                        <TextField
                            inputProps={register('messageImageURL')}
                            label={translate({ id: 'A4E_ADVANCED_SETTINGS_CUSTOMIZE_APPEARANCE_DISPLAY_IMAGE' })}
                            variant="outlined"
                            fullWidth
                            data-cy="advanced-settings-drawer-display-image-field"
                        />
                        <TextField
                            inputProps={register('messageText'
                                , {
                                    minLength: 1,
                                    validate: { required: p => !!p.trim() },
                                }
                            )}
                            required
                            multiline
                            minRows={5}
                            error={!!errors.messageText}
                            helperText={errors.messageText?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            placeholder={translate({ id: 'A4E_ADVANCED_SETTINGS_CUSTOMIZE_APPEARANCE_MESSAGE_PLACEHOLDER' })}
                            label={translate({ id: 'A4E_ADVANCED_SETTINGS_CUSTOMIZE_APPEARANCE_MESSAGE' })}
                            variant="outlined"
                            fullWidth
                            data-cy="advanced-settings-drawer-message-text-textfield"
                        />
                        <TextField
                            inputProps={register('acknowledgementButtonText')}
                            label={translate({ id: 'A4E_ADVANCED_SETTINGS_CUSTOMIZE_APPEARANCE_BUTTON_TEXT' })}
                            defaultValue={defaultData?.acknowledgementButtonText}
                            variant="outlined"
                            fullWidth
                            data-cy="advanced-settings-drawer-button-text-field"
                        />
                    </UiStack>
                </FormProvider>
            </UiForm>
        </UiDrawer>
    );
};

export default A4EAdvancedSettingsUserAcknowledgementDrawerComponent;
