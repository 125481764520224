import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import { useAuthContext } from '@experiences/util';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { FontVariantToken } from '@uipath/apollo-core';
import {
    ApDataGridColumn,
    ApDataGridColumnDropdownFilter,
    ApDataGridFooter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridWrapper,
} from '@uipath/portal-shell-react';
import type { IDropdownOption } from '@uipath/portal-shell-types/components/angular-elements';
import dayjs from 'dayjs';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { accountGlobalId } from '../../../store/selectors';
import { AuditLogService } from './AuditTabServices/AuditLogService';
import {
    filterByDateRange,
    filterByModelUsed,
    filterByProduct,
    filterByStatus,
    handleNextRecord,
    handlePreviousRecord,
    handleSearchChange,
    processGridData,
} from './AuditTabServices/AuditTabHelpers';
import type { IGridDataEntry } from './AuditTabServices/GridDataEntryTypes';
import {
    TenantService,
    useProcessedAuditData,
} from './AuditTabServices/TenantService';

export const AuditTab: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const [ loading, setLoading ] = useState(true);
    const [ auditData ] = useState<IGridDataEntry[]>([]);
    const [ filteredData, setFilteredData ] = useState<IGridDataEntry[]>([]);
    const { token } = useAuthContext();
    const accountId = useSelector(accountGlobalId);
    const [ distinctProducts, setDistinctProducts ] = useState<Array<{ label: string; value: string }>>([]);
    const [ selectedProduct, setSelectedProduct ] = useState<{ label: string; value: string }>();
    const [ selectedRecord, setSelectedRecord ] = useState<IGridDataEntry | null>(null);
    const [ currentIndex, setCurrentIndex ] = useState<number>(0);
    const [ filteredDataLength, setFilteredDataLength ] = useState(0);
    const [ inputOutputData, setInputOutputData ] = useState<{ input: string | null; output: string | null; isInputOutputParsingOnboarded: boolean | null } | null>();

    const [ pagination, setPagination ] = useState<{
        top: number;
        skip: number;
        searchTerm: string;
        sortBy: string;
        sortOrder: string;
    }>({
        top: 50,
        skip: 0,
        searchTerm: '',
        sortBy: 'Date',
        sortOrder: 'desc',
    });

    const [ distinctModelsUsed, setDistinctModelsUsed ] = useState<Array<{ label: string; value: string }>>([]);
    const [ selectedModelUsed, setSelectedModelUsed ] = useState<{ label: string; value: string }>();
    const [ selectedStatus, setSelectedStatus ] = useState<{ label: string; value: string }>();
    const [ selectedDateRange, setSelectedDateRange ] = useState<IDropdownOption | undefined>(undefined);
    const [ searchTerm, setSearchTerm ] = useState<string>('');

    const getDistinctProductsFromAuditLogs = (distProducts: string[]) =>
        distProducts.map(prod => ({
            label: prod,
            value: prod,
        }));

    const getDistinctModelsUsedFromAuditLogs = (distinctUsedModels: string[]) =>
        distinctUsedModels.map(model => ({
            label: model,
            value: model,
        }));

    const [ processedAuditData ] = useProcessedAuditData(auditData);

    useEffect(() => {
        setFilteredData(processGridData(processedAuditData, {}));
    }, [ processedAuditData ]);

    const onGridChange = useCallback(
        async ({
            filter, sort, pageIndex = 0, pageSize = 50, searchTerm,
        }: any) => {
            setLoading(true);
            try {

                if (searchTerm !== '') {
                    return;
                }

                const updatedFilters = {
                    ...filter,
                    product: selectedProduct?.value ?? undefined,
                    modelUsed: selectedModelUsed?.value ?? undefined,
                    status: selectedStatus?.value ?? undefined,
                    dateRange: selectedDateRange?.value ?? undefined,
                    searchTerm: searchTerm ?? '',
                    sort: (sort && sort.length > 0) ? [ sort[0] ] : [],
                };

                const updatedData = await AuditLogService.fetchAuditLogsBatch({
                    token,
                    accountId,
                    itemPerPage: pageSize,
                    pageNumber: pageIndex + 1,
                    productFilter: updatedFilters.product,
                    timeFilter: updatedFilters.dateRange,
                    modelFilter: updatedFilters.modelUsed,
                    statusFilter: updatedFilters.status,
                });

                const tenantDict = TenantService.getTenantCache();
                const formattedLogs = await Promise.all(updatedData.map(async (log) => {
                    const tenantName = await TenantService.fetchAndUpdateTenant(log.tenant, tenantDict);

                    return {
                        ...log,
                        tenant: tenantName,
                        formattedDate: dayjs(log.date).format('ddd, MMM D, YYYY HH:mm'),
                    };
                }));

                const updatedData3 = processGridData(formattedLogs, updatedFilters);
                setFilteredData(updatedData3);

                const s = await AuditLogService.fetchFilteredAuditLogSummary(
                    token,
                    accountId,
                    updatedFilters.product,
                    updatedFilters.dateRange,
                    updatedFilters.modelUsed,
                    updatedFilters.status,
                );

                const products = getDistinctProductsFromAuditLogs(s?.distinctProducts ?? []);
                const modelsUsed = getDistinctModelsUsedFromAuditLogs(s?.distinctUsedModels ?? []);
                setDistinctProducts(products);
                setDistinctModelsUsed(modelsUsed);

                setFilteredDataLength(s?.totalRecords ?? 0);
                setFilteredData(updatedData3);

                setPagination({
                    searchTerm: searchTerm ?? '',
                    top: pageSize,
                    skip: pageIndex * pageSize,
                    sortBy: sort?.[0]?.field ?? 'Date',
                    sortOrder: sort?.[0]?.direction === 'asc' ? 'asc' : 'desc',
                });
            } catch (error) {
                console.error('Error during grid change:', error);
            } finally {
                setLoading(false);
            }
        },
        [
            selectedProduct?.value,
            selectedModelUsed?.value,
            selectedStatus?.value,
            selectedDateRange?.value,
            token,
            accountId,
        ]
    );

    const handleRefresh = useCallback(() => {
        onGridChange({
            filter: {},
            sort: pagination.sortBy ? [
                {
                    field: pagination.sortBy,
                    direction: pagination.sortOrder,
                },
            ] : [],
            pageIndex: pagination.skip / pagination.top,
            pageSize: pagination.top,
            searchTerm: pagination.searchTerm,
        });
    }, [ onGridChange, pagination ]);

    const handleRowClick = (row: IGridDataEntry) => {
        AuditLogService.fetchInputOutputForRecord(
            token,
            accountId,
            row,
            setInputOutputData,
            setSelectedRecord,
            setCurrentIndex,
            filteredData
        );
    };

    const handleCloseSidebar = () => {
        setSelectedRecord(null);
    };

    const handleNext = () => {
        handleNextRecord(filteredData, currentIndex, setSelectedRecord, setCurrentIndex);
    };

    const handlePrevious = () => {
        handlePreviousRecord(filteredData, currentIndex, setSelectedRecord, setCurrentIndex);
    };

    return (
        <div style={{
            display: 'flex',
            flexWrap: 'nowrap',
            width: '100%',
        }}>
            <UiStack
                direction="column"
                mt={SpacingToken.L}
                gap={SpacingToken.XS}
                data-testid="audit-tab-container"
                style={{
                    flex: selectedRecord ? '0 0 70%' : '1',
                    minWidth: 0,
                }}
            >
                <UiText variant={FontVariantToken.fontSizeL}>
                    {translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_DESC' })}
                </UiText>
                <ApDataGridWrapper
                    data={filteredData}
                    loading={loading}
                    selectable={false}
                    onChange={onGridChange}
                    rowClick={({ row }) => handleRowClick(row)}
                    data-testid="audit-grid"
                >
                    <ApDataGridHeader
                        search
                        searchValue={searchTerm}
                        searchTerm={(term: string) => {
                            handleSearchChange(term, setSearchTerm, filteredData, setFilteredData, setFilteredDataLength);
                        }}
                    >

                        <ApDataGridHeaderButton
                            id="refresh-audit"
                            key="refresh-audit"
                            type="main"
                            icon="refresh"
                            buttonType="mat-flat-button"
                            visible
                            text={translate({ id: 'CLIENT_REFRESH' })}
                            label={translate({ id: 'CLIENT_REFRESH' })}
                            data-testid="refresh-audit-button"
                            render={() => (
                                <Button
                                    startIcon={<RefreshIcon />}
                                    onClick={handleRefresh}
                                    style={{
                                        backgroundColor: 'white',
                                        color: '#1976D2',
                                        border: 'none',
                                        textTransform: 'none',
                                    }}
                                >
                                    {translate({ id: 'CLIENT_REFRESH' })}
                                </Button>
                            )}
                        />
                    </ApDataGridHeader>

                    <ApDataGridColumn
                        property="formattedDate"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_DATE' })}
                        sortable
                    >
                        <ApDataGridColumnDropdownFilter
                            items={[
                                {
                                    label: 'Last day',
                                    value: 'lastDay',
                                },
                                {
                                    label: 'Last week',
                                    value: 'lastWeek',
                                },
                                {
                                    label: 'Last 30 days',
                                    value: 'last30Days',
                                },
                                {
                                    label: 'Last 60 days',
                                    value: 'last60Days',
                                },
                            ]}
                            value={selectedDateRange}
                            filterChange={(model) => {
                                const {
                                    selectedDateRange, filteredData,
                                } = filterByDateRange(
                                    model,
                                    [
                                        {
                                            label: 'Last day',
                                            value: 'lastDay',
                                        },
                                        {
                                            label: 'Last week',
                                            value: 'lastWeek',
                                        },
                                        {
                                            label: 'Last 30 days',
                                            value: 'last30Days',
                                        },
                                        {
                                            label: 'Last 60 days',
                                            value: 'last60Days',
                                        },
                                    ],
                                    processedAuditData,
                                    processGridData
                                );
                                setSelectedDateRange(selectedDateRange);
                                setFilteredData(filteredData);
                            }}
                            showAllOption
                            data-testid="date-range-dropdown"
                        />
                    </ApDataGridColumn>

                    <ApDataGridColumn
                        property="product"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_PRODUCT' })}
                        sortable
                    >
                        <ApDataGridColumnDropdownFilter
                            items={[ ...distinctProducts ]}
                            value={selectedProduct}
                            filterChange={(model) => {
                                const {
                                    selectedProduct, filteredData,
                                } = filterByProduct(model, distinctProducts, processedAuditData, processGridData);
                                setSelectedProduct(selectedProduct);
                                setFilteredData(filteredData);
                            }}
                            showAllOption
                            data-testid="product-dropdown"
                        />
                    </ApDataGridColumn>

                    <ApDataGridColumn
                        property="feature"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_FEATURE' })}
                        sortable
                    />
                    <ApDataGridColumn
                        property="tenant"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_TENANT' })}
                        sortable
                    />
                    <ApDataGridColumn
                        property="user"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_USER' })}
                        sortable
                    />
                    <ApDataGridColumn
                        property="modelUsed"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_MODEL_USED' })}
                        sortable
                    >
                        <ApDataGridColumnDropdownFilter
                            items={[ ...distinctModelsUsed ]}
                            value={selectedModelUsed}
                            filterChange={(model) => {
                                const {
                                    selectedModelUsed, filteredData,
                                } = filterByModelUsed(model, distinctModelsUsed, processedAuditData, processGridData);
                                setSelectedModelUsed(selectedModelUsed);
                                setFilteredData(filteredData);
                            }}
                            showAllOption
                            data-testid="model-used-dropdown"
                        />
                    </ApDataGridColumn>

                    <ApDataGridColumn
                        property="modelLocation"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_MODEL_LOCATION' })}
                        sortable
                    />
                    <ApDataGridColumn
                        property="status"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_STATUS' })}
                        sortable
                    >
                        <ApDataGridColumnDropdownFilter
                            items={[
                                {
                                    label: 'Succeeded',
                                    value: 'Succeeded',
                                },
                                {
                                    label: 'Failed',
                                    value: 'Failed',
                                },
                            ]}
                            value={selectedStatus}
                            filterChange={(model) => {
                                const {
                                    selectedStatus, filteredData,
                                } = filterByStatus(model, [
                                    {
                                        label: 'Succeeded',
                                        value: 'Succeeded',
                                    },
                                    {
                                        label: 'Failed',
                                        value: 'Failed',
                                    },
                                ], processedAuditData);
                                setSelectedStatus(selectedStatus);
                                setFilteredData(filteredData);
                            }}
                            showAllOption
                            data-testid="status-dropdown"
                        />
                    </ApDataGridColumn>

                    <ApDataGridFooter
                        length={filteredDataLength} // Use the length of the entire filtered dataset before pagination
                        pageSize={pagination.top}
                        pageSizes={[ 50, 100 ]}
                        pageChange={(event) => {
                            onGridChange({
                                pageIndex: event.pageIndex,
                                pageSize: event.pageSize,
                                filter: {},
                                sort: pagination.sortBy ? [
                                    {
                                        field: pagination.sortBy,
                                        direction: pagination.sortOrder,
                                    },
                                ] : [],
                            });
                        }}
                    />

                </ApDataGridWrapper>
            </UiStack>
            {selectedRecord && (
                <div style={{
                    position: 'fixed',
                    top: '48px',
                    right: 0,
                    height: '94vh',
                    width: '20%',
                    borderLeft: '1px solid #ccc',
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#FFFFFF',
                    zIndex: 1000,
                    boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)', // Apply shadows
                }}>
                    <DialogTitle sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '20px',
                        fontWeight: 'bold',
                    }}>
                        Details
                        <IconButton
                            sx={{ ml: 'auto' }}
                            onClick={handleCloseSidebar}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <hr style={{
                        margin: '16px 0',
                        borderColor: '#CFD8DD',
                    }} />
                    {' '}
                    <div style={{
                        marginTop: '16px',
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 150px)',
                    }}>
                        <p>
                            <strong>
                                {translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_DATE' })}
                                :
                            </strong>
                            {' '}
                            {dayjs(selectedRecord.date).format('ddd, MMM D, YYYY HH:mm')}
                        </p>
                        <p>
                            <strong>
                                Product:
                            </strong>
                            {' '}
                            {selectedRecord.product}
                        </p>
                        <p>
                            <strong>
                                Feature:
                            </strong>
                            {' '}
                            {selectedRecord.feature}
                        </p>
                        <p>
                            <strong>
                                Tenant:
                            </strong>
                            {' '}
                            {selectedRecord.tenant}
                        </p>
                        <p>
                            <strong>
                                User:
                            </strong>
                            {' '}
                            {selectedRecord.user}
                        </p>
                        <p>
                            <strong>
                                Model used:
                            </strong>
                            {' '}
                            {selectedRecord.modelUsed}
                        </p>
                        <p>
                            <strong>
                                Model location:
                            </strong>
                            {' '}
                            {selectedRecord.modelLocation}
                        </p>
                        <p>
                            <strong>
                                Status:
                            </strong>
                            {' '}
                            {selectedRecord.status}
                        </p>
                        <hr style={{
                            margin: '16px 0',
                            borderColor: '#CFD8DD',
                        }} />
                        {' '}
                        {inputOutputData?.isInputOutputParsingOnboarded && (
                            <>
                                <p>
                                    <strong>
                                        Input:
                                    </strong>
                                    {' '}
                                    {inputOutputData ? inputOutputData.input : 'Loading...'}
                                </p>
                                <p>
                                    <strong>
                                        Output:
                                    </strong>
                                    {' '}
                                    {inputOutputData ? inputOutputData.output : 'Loading...'}
                                </p>
                                <hr style={{
                                    margin: '16px 0',
                                    borderColor: '#CFD8DD',
                                }} />
                            </>
                        )}
                        {' '}
                        {/* Divider line with same color */}
                        <p>
                            <strong>
                                Action ID:
                            </strong>
                            {' '}
                            {selectedRecord.actionId}
                        </p>
                    </div>
                    <div style={{
                        marginTop: 'auto',
                        display: 'flex',
                        justifyContent: 'right',
                        gap: '20px',
                    }}>
                        <Button
                            variant="outlined"
                            startIcon={<ArrowBackIcon />}
                            onClick={handlePrevious}
                            disabled={currentIndex <= 0}
                            sx={{
                                textTransform: 'none',
                                color: '#1976D2',
                                borderColor: '#1976D2',
                            }}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="contained"
                            endIcon={<ArrowForwardIcon />}
                            onClick={handleNext}
                            disabled={currentIndex >= filteredData.length - 1}
                            sx={{
                                textTransform: 'none',
                                backgroundColor: '#1976D2',
                                color: '#fff',
                            }}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
