import {
    SpacingToken,
    UiProgressButton,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { ApTooltip } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import type {
    IA4EStartingPromptData,
    IA4EStartingPromptsDrawerParams,
} from '../../../../common/interfaces/a4e';
import { UiDrawer } from '../../../common/UiDrawer';
import UiForm from '../../../common/UiForm';

interface A4EStartingPromptsAddOrEditComponentProps {
    startingPromptsDrawerParams: IA4EStartingPromptsDrawerParams;
    setStartingPromptsDrawerParams: React.Dispatch<React.SetStateAction<IA4EStartingPromptsDrawerParams>>;
    setStartingPromptsTableData: React.Dispatch<React.SetStateAction<IA4EStartingPromptData[]>>;
}

const defaultA4EStartingPromptData: IA4EStartingPromptData = {
    id: '',
    department: '',
    category: '',
    title: '',
    prompt: '',
    isFeatured: false,
};

const A4EStartingPromptsAddOrEditComponent: React.FC<A4EStartingPromptsAddOrEditComponentProps> = ({
    startingPromptsDrawerParams, setStartingPromptsDrawerParams, setStartingPromptsTableData,
}) => {
    const {
        open, isEditMode, editingStartingPrompt,
    } = startingPromptsDrawerParams;
    const { formatMessage: translate } = useIntl();
    const drawerTitle = isEditMode ? 'A4E_STARTING_PROMPTS_EDIT_NEW_PROMPT_DRAWER' : 'A4E_STARTING_PROMPTS_ADD_NEW_PROMPT_DRAWER';

    const methods = useForm<IA4EStartingPromptData>({
        mode: 'all',
        defaultValues: editingStartingPrompt ?? defaultA4EStartingPromptData,
    });
    const {
        control, register, handleSubmit, reset, formState: {
            errors, isValid,
        },
    } = methods;

    useEffect(() => {
        methods.reset(editingStartingPrompt ?? defaultA4EStartingPromptData);
    }, [ editingStartingPrompt, methods ]);

    const handleClose = useCallback(() => {
        reset();
        setStartingPromptsDrawerParams({
            ...startingPromptsDrawerParams,
            open: false,
        });
    }, [ reset, setStartingPromptsDrawerParams, startingPromptsDrawerParams ]);

    const onSubmit = useCallback(
        async (data: IA4EStartingPromptData) => {
            // check if isEditMode
            if (isEditMode) {
                // update the starting prompts table data, replace the one that was edited (check id)
                setStartingPromptsTableData(prevData => prevData.map(rowData => rowData.id === data.id ? data : rowData));
            } else {
                // add to starting prompts table data - this will be temporary until the user clicks save changes on the main page
                setStartingPromptsTableData(prevData => [
                    ...prevData, {
                        ...data,
                        id: uuid(),
                    },
                ]);
            }
            handleClose();
        },
        [ handleClose, isEditMode, setStartingPromptsTableData ],
    );

    return (
        <UiDrawer
            title={translate({ id: drawerTitle })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => {
                    handleClose();
                },
            }}
            width="medium"
            themeProps={{ disableGutters: [ 'bottom', 'right' ] }}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <UiStack
                        justify='end'
                        align='center'
                        gap={SpacingToken.S}
                    >
                        <Button
                            onClick={() => handleClose()}
                            color="primary"
                            data-cy="starting-prompt-drawer-cancel-button"
                        >
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            disabled={!isValid || !!Object.keys(errors).length}
                            type="submit"
                            variant="contained"
                            loading={false}
                            data-cy="starting-prompt-drawer-save-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </UiStack>
                }
                isDrawer
                addScrollPadding
            >
                <FormProvider {...methods}>
                    <UiStack
                        direction="column"
                        align="start"
                        gap={SpacingToken.M}
                    >
                        <TextField
                            inputProps={register('department'
                                , {
                                    minLength: 1,
                                    maxLength: 256,
                                    validate: { required: p => !!p.trim() },
                                }
                            )}
                            required
                            error={!!errors.department}
                            helperText={errors.department?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            label={translate({ id: 'A4E_STARTING_PROMPTS_TABLE_HEADER_DEPARTMENT' })}
                            variant="outlined"
                            fullWidth
                            data-cy="starting-prompt-drawer-desc-text-field"
                        />

                        <TextField
                            inputProps={register('category'
                                , {
                                    minLength: 1,
                                    maxLength: 256,
                                    validate: { required: p => !!p.trim() },
                                }
                            )}
                            required
                            error={!!errors.category}
                            helperText={errors.category?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            label={translate({ id: 'A4E_STARTING_PROMPTS_TABLE_HEADER_CATEGORY' })}
                            variant="outlined"
                            fullWidth
                            data-cy="starting-prompt-drawer-category-text-field"
                        />
                        <TextField
                            inputProps={register('title'
                                , {
                                    minLength: 1,
                                    maxLength: 256,
                                    validate: { required: p => !!p.trim() },
                                }
                            )}
                            required
                            multiline
                            minRows={3}
                            error={!!errors.title}
                            helperText={errors.title?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            label={translate({ id: 'A4E_STARTING_PROMPTS_TABLE_HEADER_TITLE' })}
                            variant="outlined"
                            fullWidth
                            data-cy="starting-prompt-drawer-title-text-field"
                        />
                        <TextField
                            inputProps={register('prompt'
                                , {
                                    minLength: 1,
                                    maxLength: 256,
                                    validate: { required: p => !!p.trim() },
                                }
                            )}
                            multiline
                            minRows={4}
                            error={!!errors.prompt}
                            helperText={errors.prompt?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            label={(
                                <UiStack>
                                    <UiText style={{ fontWeight: 600 }}>
                                        {translate({ id: 'A4E_STARTING_PROMPTS_TABLE_HEADER_PROMPT' })}
                                        {' *'}
                                    </UiText>
                                    <ApTooltip content={translate({ id: 'A4E_STARTING_PROMPT_TOOLTIP_PROMPT' })}>
                                        <InfoOutlinedIcon
                                            style={{
                                                width: '20px',
                                                transform: 'translateY(-1px) translateX(2px)',
                                            }}
                                            aria-hidden="false"
                                            aria-label={translate({ id: 'A4E_STARTING_PROMPT_TOOLTIP_PROMPT' })}
                                        />
                                    </ApTooltip>
                                </UiStack>
                            )}
                            variant="outlined"
                            fullWidth
                            data-cy="starting-prompt-drawer-prompt-text-field"
                        />
                        <Controller
                            control={control}
                            name="isFeatured"
                            render={({ field }) => (
                                <FormControlLabel
                                    {...field}
                                    control={
                                        <Checkbox
                                            checked={field.value}
                                            data-cy="starting-prompt-drawer-is-featured-checkbox"
                                        />
                                    }
                                    label={translate({ id: 'A4E_STARTING_PROMPTS_FEATURED_DESC' })}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="requiresFileUpload"
                            render={({ field }) => (
                                <FormControlLabel
                                    {...field}
                                    control={
                                        <Checkbox
                                            checked={field.value}
                                            data-cy="starting-prompt-drawer-requires-file-upload-checkbox"
                                        />
                                    }
                                    label={translate({ id: 'A4E_STARTING_PROMPTS_FILE_UPLOAD_DESC' })}
                                />
                            )}
                        />
                    </UiStack>
                </FormProvider>
            </UiForm>
        </UiDrawer>
    );
};

export default A4EStartingPromptsAddOrEditComponent;
