import {
    getRegionTranslationId,
    Region,
} from '@experiences/constants';
import { TenantSelfServeMigrationEvent } from '@experiences/telemetry';
import {
    SpacingToken,
    UiSelect,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { DataResidencyCloudLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import type { IRegionsPerService } from '../../../common/interfaces/gws';
import {
    getServiceRegion,
    tenantMigrationUrl,
} from '../../../services/global-workflow-service/TenantMigration';
import {
    getAvailableServices,
    getTenantById,
    tenantAvailableServicesUri,
    tenantByIdUri,
} from '../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../../store/selectors';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import { UiMigrationServiceCard } from '../../common/UiCard';
import UiCardGrid from '../../common/UiCardGrid/UiCardGrid';
import { listOfServices } from '../services/HelperUtil';
import { getSupportedRegionForTenant } from '../subcomponents/helpers/TenantRegionHelper';
import { useServiceDependency } from '../subcomponents/helpers/useServiceDependency';
import type { ITenantMigrationFormData } from './types';

const useStyles = makeStyles(theme =>
    ({
        regionSource: { width: '320px' },
        regionTarget: { width: '320px' },
        infoLink: {
            fontSize: '14px',
            fontWeight: 600,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        arrowIcon: { marginTop: '42px' },
        icon: {
            height: '16px',
            width: '16px',
            marginLeft: '4px',
            justifyItems: 'center',
        },
    }),
);

function buildRegionToMoveableServicesMap(data?: IRegionsPerService): { [key: string]: string[] } {
    const result: { [key: string]: string[] } = {};
    if (data?.availableRegionsPerService) {
        for (const region in data.availableRegionsPerService) {
            const services = data.availableRegionsPerService[region];
            if (services) {
                result[region] = services.map(item => item.service);
            }
        }
    }
    return result;
}

export const TenantMigrationStepSelectRegion: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLinks = useDocumentationLinks();
    const { servicesToHide } = useServiceDependency();
    const { tenantId } = useParams() as { tenantId: string };
    const [ showAlert, setShowAlert ] = useState(false);

    const {
        control,
        watch,
        formState: { errors },
    } = useFormContext<ITenantMigrationFormData>();
    const { logEvent } = useTelemetryHelper();
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);

    const { data: tenantAvailableServices } = useSWR({
        url: tenantAvailableServicesUri,
        organizationGuid: partitionGlobalId,
        accountName,
    }, getAvailableServices);

    const { data: regionsPerService } = useSWR({
        url: `${tenantMigrationUrl}/RegionsPerService`,
        tenantId,
    }, getServiceRegion);

    const { data: tenant } = useSWR({
        url: tenantByIdUri,
        id: tenantId,
    }, getTenantById);

    const oldRegion = watch('sourceRegion');
    const newRegion = watch('destinationRegion');

    const regionToMoveableServicesMap = useMemo(() =>
        buildRegionToMoveableServicesMap(regionsPerService), [ regionsPerService ]);

    const currentTenantServices = useMemo(() => {
        const display = listOfServices(tenant?.tenantServiceInstances, servicesToHide) ?? [];
        return display.map(s => (
            {
                id: s.serviceType,
                name: tenantAvailableServices?.find(service => service.id === s.serviceType)?.name ?? s.serviceType,
                region: s.region,
            }
        ));
    }, [ tenant, servicesToHide, tenantAvailableServices ]);

    const destinationRegionOptions = useMemo(() => {
        const tenantLevelRegions = getSupportedRegionForTenant(tenantAvailableServices);
        const tenantLevelRegionsExcludingCurrentRegion = tenantLevelRegions?.filter(region => region !== oldRegion);
        return Object.assign({}, ...(tenantLevelRegionsExcludingCurrentRegion ?? []).map((x) =>
            ({ [Region[x]]: translate({ id: getRegionTranslationId(x) }) })));
    }, [ tenantAvailableServices, oldRegion, translate ]);

    useEffect(() => {
        if (!newRegion) {
            return;
        }
        const moveableServices = regionToMoveableServicesMap[newRegion];
        const nonMoveableServices = currentTenantServices.filter(service =>
            service.region !== newRegion && moveableServices?.indexOf(service.id) < 0);
        setShowAlert(nonMoveableServices.length > 0);
    }, [ regionToMoveableServicesMap, currentTenantServices, newRegion ]);

    return (
        <UiStack
            direction='column'
            gap={SpacingToken.XL}>
            <UiStack gap={SpacingToken.S}>
                <TextField
                    label={translate({ id: 'CLIENT_TENANT_SOURCE_REGION' })}
                    value={translate({ id: getRegionTranslationId(oldRegion) })}
                    variant="outlined"
                    data-cy="source-region"
                    className={classes.regionSource}
                />
                <ArrowRightAltIcon className={classes.arrowIcon} />
                <UiSelect
                    id="destinationRegion"
                    name="destinationRegion"
                    control={control}
                    isTranslated
                    inputLabel={translate({ id: 'CLIENT_TENANT_TARGET_REGION' })}
                    options={destinationRegionOptions}
                    error={!!errors.destinationRegion}
                    required
                    className={classes.regionTarget}
                    dataCy="region-select-field"
                />
            </UiStack>

            <UiStack
                direction='column'
                mt={SpacingToken.L}
                gap={SpacingToken.Micro}>
                <UiText variant={FontVariantToken.fontSizeLBold}>
                    {translate({ id: 'CLIENT_SERVICE_AVAILABLE' })}
                </UiText>
                <UiText variant={FontVariantToken.fontSizeM}>
                    {translate({ id: 'CLIENT_TENANT_MIGRATE_REGION_AVAILABILITY' })}
                </UiText>
                <UiStack>
                    <Link
                        className={classes.infoLink}
                        onClick={() => logEvent(TenantSelfServeMigrationEvent.ReadMore, { Location: 'tenant-migration-page' })}
                        target="_blank"
                        href={getLocalizedLinks({ articleSlug: DataResidencyCloudLink })}>
                        {translate({ id: 'CLIENT_READ_REGION_RESIDENCY' })}
                        <OpenInNewIcon className={classes.icon} />
                    </Link>
                </UiStack>
            </UiStack>

            {showAlert && (
                <PortalAlertBar
                    status='info'
                    cancelable={false}>
                    <UiText>
                        {translate({ id: 'CLIENT_TENANT_MIGRATION_MISSING_SERVICES_IN_REGION' })}
                    </UiText>
                </PortalAlertBar>
            )}

            <UiCardGrid
                maxCardWidth='330px'
                style={{ maxWidth: 'unset' }}
                position='left'>
                {currentTenantServices?.map((service, index) => (
                    <UiMigrationServiceCard
                        key={`tenant-service-card-${index}`}
                        region={service.region}
                        serviceId={service.id}
                        newRegion={newRegion}
                        supportedServicesInNewRegion={regionToMoveableServicesMap[newRegion]}
                    />
                ))}
            </UiCardGrid>
        </UiStack>
    );
};
