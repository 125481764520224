import type { LoadRemoteModuleOptions } from '@uipath/apollo-microfrontends';

interface Options {
    moduleFederationVersionPath: string;
    options: LoadRemoteModuleOptions;
}

// First Party Service mapping of remote module federation
export const RemoteModuleMapping: Record<string, Options> = {
    roboticsops: {
        moduleFederationVersionPath: '/roboticsops_/api/moduleFederation/portal/version',
        options: {
            remoteEntry: '',
            remoteName: 'federatedGovernance',
            exposedModule: 'federatedGovernance/attachMicrofrontend',
        },
    },
};
