import type { AxiosError } from 'axios';

import type {
    ContextGroundingIndexes,
    TenantConfiguration,
} from '../../../../services/a4e/A4EServiceTypes';
import type { BlobFileAccessDto } from '../../../../services/orchestrator/OrchBucketService';
import {
    getBucketReadUri,
    getBucketWriteUri,
    getFileFromSystemBucket,
    uploadFileToSystemBucket,
} from '../../../../services/orchestrator/OrchBucketService';
import { A4EErrorMessage } from './A4EErrorType';

const AUTOPILOT_BUCKET_NAME = 'autopilot';
const AUTOPILOT_TENANT_SETTINGS = 'autopilot.json';

export const uploadTenantSettingsToAutopilot = async ({
    folderId,
    organizationName,
    tenantName,
    autopilotTenantConfig,
    contextGroundingData,
}: {
    folderId: string;
    organizationName: string;
    tenantName: string;
    autopilotTenantConfig?: TenantConfiguration;
    contextGroundingData: ContextGroundingIndexes[];
}) => {
    try {
        const writeUri: BlobFileAccessDto = await getBucketWriteUri({
            accountLogicalName: organizationName,
            tenantName,
            folderId,
            bucketName: AUTOPILOT_BUCKET_NAME,
            fileName: AUTOPILOT_TENANT_SETTINGS,
        });
        await uploadFileToSystemBucket({
            writeUri,
            file: {
                ...autopilotTenantConfig,
                contextGroundingIndexes: contextGroundingData,
            },
            folderId,
        });
    } catch (e) {
        throw new A4EErrorMessage('A4E_CONTEXT_GROUNDING_SAVE_FAIL', (e as AxiosError).code);
    }
};

export const readTenantSettingsFromAutopilot = async ({
    folderId,
    organizationName,
    tenantName,
}: {
    folderId: string;
    organizationName: string;
    tenantName: string;
}) => {
    const readUri = await getBucketReadUri({
        accountLogicalName: organizationName,
        tenantName,
        folderId,
        bucketName: AUTOPILOT_BUCKET_NAME,
        fileName: AUTOPILOT_TENANT_SETTINGS,
    });
    const tenantConfig = await getFileFromSystemBucket({
        readUri,
        folderId,
    }) as TenantConfiguration;
    return tenantConfig;
};
