import { accountLogicalName } from '@experiences/ecommerce';
import type { Tenant } from '@uipath/portal-shell/dist/types/models/tenant';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { A4EError } from '../../../services/a4e/A4EServiceTypes';
import A4EAdvancedSettingsComponent from './subcomponents/A4EAdvancedSettingsComponent';
import A4EAutomationPropertiesComponent from './subcomponents/A4EAutomationPropertiesComponent';
import A4EContextGroundingComponent from './subcomponents/A4EContextGroundingComponent';
import A4ESolutionComponent from './subcomponents/A4ESolutionComponent';
import A4EStartingPromptsComponent from './subcomponents/A4EStartingPromptsComponent';
import {
    getAutopilotFolderIdForUser,
    SolutionDeploymentState,
} from './utils/A4ESolutionUtils';

type TenantConfig = {
    tenant?: Tenant;
    autopilotFolderId?: number;
};

const A4EComponent: React.FC = () => {
    const organizationName = useSelector(accountLogicalName);
    const [ deploymentState, setDeploymentState ] = useState<SolutionDeploymentState>(SolutionDeploymentState.NONE);
    const [ tenantConfig, setTenantConfig ] = useState<TenantConfig>();

    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();

    const onTenantChanged = useCallback ((newTenant: Tenant | undefined) => {
        setDeploymentState(SolutionDeploymentState.NONE);
        setTenantConfig({
            tenant: newTenant,
            autopilotFolderId: undefined,
        });
    }, []);

    useEffect(() => {
        const fetchAutopilotFolderId = async () => {
            if (tenantConfig?.tenant && deploymentState >= SolutionDeploymentState.INSTALLED) {
                try {
                    const folderId = await getAutopilotFolderIdForUser(organizationName, tenantConfig.tenant.name);
                    if (!folderId) {
                        createNotification(translate({ id: 'CLIENT_MISSING_AUTOPILOT_FOLDER' }), notificationType.ERROR);
                        return;
                    }
                    setTenantConfig({
                        tenant: tenantConfig.tenant,
                        autopilotFolderId: folderId,
                    });
                } catch (error) {
                    const a4eError = error as A4EError;
                    createNotification(
                        translate({ id: 'A4E_ORCH_FOLDER_ERROR' }, { error: a4eError.message }),
                        notificationType.ERROR,
                    );
                }
            }
        };
        fetchAutopilotFolderId();
    }, [ createNotification, deploymentState, organizationName, tenantConfig?.tenant, translate ]);

    const tenant = tenantConfig?.tenant;
    const isDeployed =
        tenant && deploymentState >= SolutionDeploymentState.INSTALLED && deploymentState !== SolutionDeploymentState.UNINSTALLING;
    return <>
        <A4ESolutionComponent
            onDeploymentStateChange={setDeploymentState}
            tenant={tenantConfig?.tenant}
            setTenant={onTenantChanged} />
        {isDeployed &&
        (
            <>
                <A4EStartingPromptsComponent selectedTenant={tenant} />
                <A4EContextGroundingComponent
                    selectedTenant={tenant}
                    autopilotFolderId={tenantConfig.autopilotFolderId} />
                <A4EAutomationPropertiesComponent selectedTenant={tenant} />
                <A4EAdvancedSettingsComponent
                    selectedTenant={tenant}
                    autopilotFolderId={tenantConfig.autopilotFolderId} />
            </>
        )}

    </>;
};

export default A4EComponent;
